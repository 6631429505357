<template>
  <div class="container-fluid flex-fill" v-if="$parent.loaded">

    <b-breadcrumb>
      <b-breadcrumb-item to="/"><b-img class="mb-1" width="32" height="32" src="/static/images/logo.png" alt="logo"></b-img></b-breadcrumb-item>
      <b-breadcrumb-item active class="font-weight-bold">S'enregistrer</b-breadcrumb-item>
    </b-breadcrumb>

    <div class="alert alert-secondary" role="alert">
      <b>Veuillez entrer des informations complètes et exactes</b>. Certaines assos demandent que votre profil soit vérifié, par une pièce d'identité, par une carte d'adhérent ou par une licence sportive. N'utilisez aucun pseudo ou surnom, par exemple: <i>JF</i> au lieu de <i>Jean-François</i>. Les profils erronés seront automatiquement supprimés.
    </div>

    <b-input-group>
      <template #prepend>
          <b-input-group-text class="font-weight-bold">Email</b-input-group-text>
      </template>
      <b-form-input id="email" v-model="email" type="email" :state="isEmailValid()"></b-form-input>
      <template #append>
        <b-button id="activationCodeButton" v-on:click="sendActivationCode()" :variant="isEmailValid() ? 'success': 'secondary'" :disabled="!activationCodeEnabled || !isEmailValid()">Envoyer code activation</b-button>
      </template>
    </b-input-group>
    <b-form-text>Veuillez indiquer une addresse <b>email valide et non jetable</b>. Puis cliquez sur le bouton <em>Envoyer code d'activation</em>. Un email vous sera envoyé à cette addresse avec un code que vous devez recopier dans le champ suivant.</b-form-text>

    <b-input-group class="mt-2">
      <template #prepend>
          <b-input-group-text class="font-weight-bold">Code d'activation</b-input-group-text>
      </template>
      <b-form-input id="activation-code" v-model="activationCode" type="text" :state="isActivationCodeValid()"></b-form-input>
    </b-input-group>
    <b-form-text>Renseignez le code d'activation qui vous a été envoyé.</b-form-text>

    <b-input-group class="mt-2">
      <template #prepend>
          <b-input-group-text class="font-weight-bold">Mot de passe</b-input-group-text>
      </template>
      <b-form-input id="password" v-model="password" type="password" :state="isPasswordValid()"></b-form-input>
    </b-input-group>
    <b-form-text>Veuillez indiquer un mot de passe avec <b>8 caractères minimum</b>.</b-form-text>

    <b-input-group class="mt-2">
      <template #prepend>
          <b-input-group-text class="font-weight-bold">Confirmation</b-input-group-text>
      </template>
      <b-form-input id="password-confirmation" v-model="passwordConfirmation" type="password" :state="isPasswordConfirmationValid()"></b-form-input>
    </b-input-group>
    <b-form-text>Veuillez confirmer votre mot de passe.</b-form-text>

    <b-input-group class="mt-2">
      <template #prepend>
          <b-input-group-text class="font-weight-bold">Prénom</b-input-group-text>
      </template>
      <b-form-input id="firstname" v-model="firstname" type="text" :state="isFirstnameValid()"></b-form-input>
    </b-input-group>

    <b-input-group class="mt-2">
      <template #prepend>
          <b-input-group-text class="font-weight-bold">Nom</b-input-group-text>
      </template>
      <b-form-input id="lastname" v-model="lastname" type="text" :state="isLastnameValid()"></b-form-input>
    </b-input-group>

    <b-input-group class="mt-2">
      <template #prepend>
          <b-input-group-text class="font-weight-bold">Discipline</b-input-group-text>
      </template>
      <b-form-select id="domain" v-model="domainId" :options="$parent.domains" value-field="id" text-field="name" @change="updateClubs()"></b-form-select>
    </b-input-group>

    <b-input-group class="mt-2">
      <template #prepend>
          <b-input-group-text class="font-weight-bold">Club</b-input-group-text>
      </template>
      <b-form-select id="club" v-model="clubId" :options="clubs" value-field="id" text-field="selectOptionValue"></b-form-select>
    </b-input-group>
    <b-form-text>Vous ne trouvez pas votre club dans la liste ? Merci de contacter le <router-link to="/contact.html">support</router-link>.</b-form-text>

    <b-input-group class="mt-2">
      <template #prepend>
          <b-input-group-text class="font-weight-bold">Numéro adhérent</b-input-group-text>
      </template>
      <b-form-input id="licence-number" v-model="licenceNumber" type="text" :state="isLicenceNumberValid()"></b-form-input>
    </b-input-group>
    <b-form-text>Votre numéro d'adhérent est obligatoire et doit être une <b>copie exacte de celle marqué sur votre carte d'adhérent</b>. Si l'asso est une association sportive, il vous suffit de recopier votre numéro de licence.</b-form-text>

    <b-form inline class="mt-2">
      <b-form-group label="Sexe" label-class="font-weight-bold">
        <b-form-radio-group id="sex" v-model="sex" :options="sexOptions"></b-form-radio-group>
      </b-form-group>
    </b-form>

    <b-input-group class="mt-2">
      <template #prepend>
        <b-input-group-text class="font-weight-bold">Date de naissance</b-input-group-text>
      </template>
      <b-form-select id="birthdate-day" v-model="birthdateDay" :options="birthdateDayList" :state="isBirthdateDayValid()"></b-form-select>
      <b-form-select id="birthdate-month" v-model="birthdateMonth" :options="birthdateMonthList" :state="isBirthdateMonthValid()"></b-form-select>
      <b-form-select id="birthdate-year" v-model="birthdateYear" :options="birthdateYearList" :state="isBirthdateYearValid()"></b-form-select>
    </b-input-group>

    <b-input-group class="mt-2">
      <template #prepend>
        <b-input-group-text class="font-weight-bold">Téléphone</b-input-group-text>
      </template>
      <b-form-input id="phone-number" v-model="phoneNumber" type="text" :state="isPhoneNumberValid()"></b-form-input>
    </b-input-group>

    <captcha ref="captcha" class="mt-2"></captcha>

    <b-form-checkbox id="accept-legal" v-model="acceptLegal">
      J'accepte la <router-link to="/legal.html" target="_blank">politique de confidentialité</router-link>
    </b-form-checkbox>

    <b-row class="text-center">
      <b-col sm="12">
        <b-button id="register-button" v-on:click="register" :variant="isFormValid() ? 'success': 'secondary'" size="lg" :disabled="!isFormValid()"><account-plus-icon/> S'enregistrer</b-button>
      </b-col>
      <b-col sm="12">
        <p>Vous avez déjà un compte ? <router-link class="btn btn-link" to="/login.html"><login-icon/> Se connecter</router-link></p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from 'axios'
import captcha from '@/components/Captcha.vue'

export default {
  components: {
    captcha
  },
  data: function () {
    return {
      acceptLegal: false,
      activationCode: '',
      activationCodeEnabled: true,
      birthdateDay: '',
      birthdateMonth: '',
      birthdateDayList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
      birthdateMonthList: [ { value: 1, text: "Janvier" }, { value: 2, text: "Février" }, { value: 2, text: "Mars" }, { value: 4, text: "Avril" }, { value: 5, text: "Mai" }, { value: 6, text: "Juin" }, { value: 7, text: "Juillet" }, { value: 8, text: "Août" }, { value: 9, text: "Septembre" }, { value: 10, text: "Octobre" }, { value: 11, text: "Novembre" }, { value: 12, text: "Décembre" }  ],
      birthdateYear: '',
      birthdateYearList: [],
      clubId: 0,
      clubs: [],
      currentYear: new Date().getFullYear(),
      email: '',
      domainId: 0,
      firstname: '',
      lastname: '',
      licenceNumber: '',
      password: '',
      passwordConfirmation: '',
      phoneNumber: '',
      sex: 'male',
      sexOptions: [
          { text: 'Masculin', value: 'male' },
          { text: 'Féminin', value: 'female' },
      ],
    }
  },
  methods: {
    getClubs: function() {
      return axios.get("/api/club/get-clubs/" + this.domainId + "/");
    },
    processGetClubsResponse: function(response) {
      if(response.status != 200) {
        return;
      }
      this.clubs = response.data;
      for (let clubKey in this.clubs) {
        let club = this.clubs[clubKey];
        club.selectOptionValue = club.city + " - " + club.name;
      }
    },
    isBirthdateDayValid: function() {
      if (this.birthdateDay == null || this.birthdateDay.length == 0) return null;
      return this.birthdateDay != null && this.birthdateDay >= 1 && this.birthdateDay <= 31;
    },
    isBirthdateMonthValid: function() {
      if (this.birthdateMonth.length == 0) return null;
      return this.birthdateMonth != null && this.birthdateMonth >= 1 && this.birthdateMonth <= 12;
    },
    isBirthdateYearValid: function() {
      if (this.birthdateYear.length == 0) return null;
      return this.birthdateYear != null && this.birthdateYear >= (this.currentYear - 100) && this.birthdateYear <= this.currentYear;
    },
    isEmailValid: function() {
        if (this.email.length == 0) return null;
        return this.$parent.emailRegexp.test(this.email);
    },
    isActivationCodeValid: function() {
      if (this.activationCode.length == 0) return null;
      return this.activationCode.length >= 4;
    },
    isFormValid: function() {
      return this.isEmailValid() && this.isFirstnameValid() && this.isLastnameValid() && this.isPhoneNumberValid() && this.isPasswordValid() && this.isPasswordConfirmationValid() && this.isBirthdateDayValid() && this.isBirthdateMonthValid()&& this.isBirthdateYearValid() && this.isLicenceNumberValid() && this.isActivationCodeValid() && this.acceptLegal;
    },
    isPasswordValid: function() {
      if (this.password.length == 0) return null;
      return this.password.length >= 8;
    },
    isPasswordConfirmationValid: function() {
      if (this.passwordConfirmation.length == 0) return null;
      return this.passwordConfirmation.length >= 8 && this.password == this.passwordConfirmation;
    },
    isFirstnameValid: function() {
      if (this.firstname.length == 0) return null;
      return this.firstname.length >= 2 && this.firstname.length <= 255;
    },
    isLastnameValid: function() {
      if (this.lastname.length == 0) return null;
      return this.lastname.length >= 2 && this.lastname.length <= 255;
    },
    isLicenceNumberValid: function() {
      if (this.licenceNumber.length == 0) return null;
      return this.licenceNumber.length >= 6 && this.licenceNumber.length <= 20;
    },
    isPhoneNumberValid: function() {
      if (this.phoneNumber.length == 0) return null;
      return this.$parent.phoneNumberRegex.test(this.phoneNumber) && this.phoneNumber.length <= 20;
    },
    register: function() {
      this.$parent.refresh = true;
      let birthdateString = '';

      birthdateString += this.birthdateYear;
      birthdateString += '-';

      if (this.birthdateMonth < 10) {
        birthdateString += '0';
      }
      birthdateString += this.birthdateMonth;
      birthdateString += '-';

      if (this.birthdateDay < 10) {
        birthdateString += '0';
      }
      birthdateString += this.birthdateDay;

      var json = {
        activationCode: this.activationCode,
        birthdate: birthdateString,
        captchaCloud: this.$refs.captcha.captchaCloud,
        captchaTree: this.$refs.captcha.captchaTree,
        captchaFlower: this.$refs.captcha.captchaFlower,
        clubId: this.clubId,
        email: this.email,
        firstname: this.firstname,
        lastname: this.lastname,
        licenceNumber: this.licenceNumber,
        password: this.password,
        phoneNumber: this.phoneNumber,
        sex: this.sex,
       };

      this.serverError = false;

      axios.post("/api/user/add/", json)
      .then(response => {
        if(response.status == 200) {
          if (this.redirect && this.redirect.length > 0) {
            this.$router.push(this.redirect);
          } else {
            this.$parent.createSession(response);
            let p1 = this.$parent.getMyUserClubs(this.$parent.userSession.user.hash);
            let p2 = this.$parent.getBookRights(this.$parent.userSession.user.hash);
            Promise.all([p1, p2]).then((responses) => {
              this.$parent.processGetMyUserClubsResponse(responses[0]);
              this.$parent.processGetBookRightsResponse(responses[1]);
              this.$parent.refresh = false;
              this.$router.push('/user-dashboard.html');
            });
          }
          return;
        }
      }).catch(error => {

        if(error.response.status == 400) {
          var errorData = error.response.data;
          if (errorData.code == 1002) {
            this.$bvModal.msgBoxOk("Un compte avec cet email existe déjà. Vous avez peut-être déjà un compte chez nous. Dans ce cas, utilisez la fonction oubli de mot de passe disponible sur la page se connecter.", { centered: true, noFade: true });
            this.emailValid = false;
          }
          else if (errorData.code == 1001) {
            this.$bvModal.msgBoxOk("Le captcha est invalide. Veuillez cliquer sur le captcha pour le rafraîchir et entrer à nouveau les éléments.", { centered: true, noFade: true });
            this.$refs.captcha.refreshCaptcha();
          }
          else if (errorData.code == 1003) {
            this.$bvModal.msgBoxOk("Le code d'activation est incorrect.", { centered: true, noFade: true });
          }
          else {
            this.$bvModal.msgBoxOk("Une erreur est survenue. Code erreur " + errorData.code, { centered: true, noFade: true });
          }
        } else {
          this.$bvModal.msgBoxOk("Une erreur est survenue. Code HTTP " + error.response.status, { centered: true, noFade: true });
        }

        this.$parent.refresh = false;
      });
    },
    sendActivationCode: function() {
      this.$parent.refresh = true;
      var json = {
          email: this.email
       };

      this.activationCodeEnabled = false;
      axios.post("/api/user/send-activation-code/", json)
      .then(response => {
        if(response.status == 200) {
          this.activationCodeEnabled = true;
          this.$bvModal.msgBoxOk("Un code d'activation a été envoyé sur votre adresse email. Veillez à vérifier votre dossier Spams/Courriers indésirables.", { id: 'activationCodeModal', centered: true, noFade: true });
        }

        this.$parent.refresh = false;
      }).catch(error => {
        this.activationCodeEnabled = true;

        if(error.response.status == 400) {
          var errorData = error.response.data;

          if (errorData.code == 10004) {
            this.$bvModal.msgBoxOk("Un compte avec cet email existe déjà. Vous avez peut-être déjà un compte chez nous. Dans ce cas, utilisez la fonction oubli de mot de passe disponible sur la page se connecter.", { centered: true, noFade: true });
            this.emailValid = false;
          }
        }

        this.$parent.refresh = false;
      });
    },
    updateClubs: function() {
      this.$parent.refresh = true;
      this.clubId = 0;
      this.getClubs().then((response) => {
        this.processGetClubsResponse(response);
        this.$parent.refresh = false;
      });
    }
  },
  beforeMount() {
    this.$parent.init();

    document.title = this.$parent.websiteName + " - S'enregistrer";

    for (var i = this.currentYear; i >= this.currentYear - 100; i--) {
      this.birthdateYearList.push(i);
    }

    this.$parent.loaded = true;
  }
}
</script>
