<template>
<div class="container-fluid flex-fill" v-if="$parent.loaded">

  <b-breadcrumb>
    <b-breadcrumb-item to="/"><b-img class="mb-1" width="32" height="32" src="/static/images/logo.png" alt="logo"></b-img></b-breadcrumb-item>
    <b-breadcrumb-item active class="font-weight-bold">Mentions Légales</b-breadcrumb-item>
  </b-breadcrumb>

  <p>En construction</p>
</div>
</template>

<script>
export default {
  data: function () {
    return {

    }
  },
  methods: {
  },
  beforeMount() {
    this.$parent.init();
    document.title = this.$parent.websiteName + " - Mentions Légales";

    this.$parent.loaded = true;
  }
}
</script>
