<template>
<div class="container-fluid flex-fill" v-if="$parent.loaded">

  <b-breadcrumb>
    <b-breadcrumb-item to="/"><b-img class="mb-1" width="32" height="32" src="/static/images/logo.png" alt="logo"></b-img></b-breadcrumb-item>
    <b-breadcrumb-item :to="'/club-dashboard.html?clubId=' + club.id" ><office-building-icon/></b-breadcrumb-item>
    <b-breadcrumb-item active class="font-weight-bold">édition de la page</b-breadcrumb-item>
  </b-breadcrumb>

  <b-input-group class="mt-2">
    <template #prepend>
        <b-input-group-text class="font-weight-bold">Nom</b-input-group-text>
    </template>
    <b-form-input v-model="page.title" type="text"></b-form-input>
  </b-input-group>

  <b-form-group class="mt-2 mb-0" label-class="font-weight-bold">
    <quilleditor ref="quilleditor" id="pageText" v-model="page.text"></quilleditor>
  </b-form-group>

  <b-input-group class="mt-4">
    <b-form-checkbox inline switch v-model="page.isPublic">page publique</b-form-checkbox>
  </b-input-group>

  <b-row class="text-center mt-4">
    <b-col sm="12">
      <b-button variant="success" v-on:click="updatePage" :disabled="!(page.text && page.text.length > 0 && page.title && page.title.length > 0)">Mettre à jour la page</b-button>
    </b-col>
  </b-row>

</div>
</template>

<script>
import axios from 'axios'
import quilleditor from '@/components/QuillEditor.vue'

export default {
  components: {
    quilleditor
  },
  data: function () {
    return {
      club: undefined,
      clubId: undefined,
      page: undefined,
      pageId: undefined,
    }
  },
  methods: {
    getPage: function() {
      return axios.get("/api/club-page/get-club-page/" + this.pageId + "/");
    },
    processGetPageResponse: function(response) {
      this.page = response.data;
    },
    updatePage: function() {
      var json = {
        clubId: this.page.clubId,
        isPublic: this.page.isPublic || false,
        order: this.page.order,
        pageId: this.page.id,
        text: this.page.text,
        title: this.page.title,
       };

      axios.post("/api/club-page/update-club-page/", json)
      .then(() => {
        this.getPage().then(response => {
          this.processGetPageResponse(response);
          this.$bvModal.msgBoxOk("La page a été mise à jour.", { centered: true, noFade: true });
          this.$parent.refresh = false;
        });
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
  },
  beforeMount() {
    this.$parent.init();
    this.pageId = this.$route.query.pageId;

    let p1 = this.getPage();
    Promise.all([p1]).then((responses) => {
      this.processGetPageResponse(responses[0]);
      this.club = this.$parent.myUserClubMap[this.page.clubId].club;
      document.title = this.$parent.websiteName + " - Gestion des pages de l'asso " + this.club.name;
      this.$parent.loaded = true;
    }).catch((error)=>{
      console.log(error);
      //this.$router.push('/error.html');
    });
  }
}
</script>
