<template>
<div class="container-fluid flex-fill" v-if="$parent.loaded">

  <b-breadcrumb>
    <b-breadcrumb-item to="/admin/"><cog-icon/></b-breadcrumb-item>
    <b-breadcrumb-item active class="font-weight-bold">Liste des clubs</b-breadcrumb-item>
  </b-breadcrumb>

  <b-row class="text-center bg-dark text-white font-weight-bold mt-3 mb-2 ml-0 mr-0 align-items-center">
    <b-col sm="1" class="align-center">ID</b-col>
    <b-col sm="6">Nom</b-col>
    <b-col sm="2">Domain</b-col>
    <b-col sm="2">Ville</b-col>
    <b-col sm="1">Actions</b-col>
  </b-row>

  <b-row class="mb-2 mr-0 ml-0" :class="{'even-line-background' : clubIndex % 2 == 0}" align-v="center" v-for="(club, clubIndex) in clubs" :key="clubIndex">
    <b-col sm="1" class="text-center"><b>{{ club.id }}</b></b-col>
    <b-col sm="6">{{ club.name }}</b-col>
    <b-col sm="2" class="text-center">{{ $parent.domainsMap[club.domainId].name }}</b-col>
    <b-col sm="2">{{ club.city }}</b-col>
    <b-col sm="1"><router-link :to="'/admin/club.html?clubId=' + club.id"><pencil-icon/></router-link></b-col>
  </b-row>

</div>
</template>

<script>
import axios from 'axios'

export default {
  data: function () {
    return {
      clubs: [],
    }
  },
  methods: {
    getClubs: function() {
      return axios.get("/api/club/get-clubs/");
    },
    processGetClubsResponse: function(response) {
      if(response.status != 200) {
        return;
      }
      this.clubs = response.data;
    },
  },
  beforeMount() {
    this.$parent.init();
    this.$parent.inAdmin = true;
    document.title = this.$parent.websiteName + " - Administration Clubs";

    let p1 = this.getClubs();
    Promise.all([p1]).then((responses) => {
      this.processGetClubsResponse(responses[0]);
      this.$parent.loaded = true;
    });
  }
}
</script>
