<template>
<div class="container-fluid flex-fill" v-if="$parent.loaded">

  <b-breadcrumb>
    <b-breadcrumb-item to="/"><b-img class="mb-1" width="24" height="24" src="/static/images/logo.png" alt="logo"></b-img></b-breadcrumb-item>
    <b-breadcrumb-item :to="'/club-dashboard.html?clubId=' + clubId" ><office-building-icon/></b-breadcrumb-item>
    <b-breadcrumb-item active class="font-weight-bold"><poll-icon/> Création d'un sondage</b-breadcrumb-item>
  </b-breadcrumb>

  <div class="alert alert-secondary mb-0" role="alert">
    Un sondage doit comporter au moins 2 réponses. Le sondage créé sera soumis à validation de l'administrateur de l'asso.
  </div>

  <b-input-group class="mt-2">
    <template #prepend>
        <b-input-group-text class="font-weight-bold">Question</b-input-group-text>
    </template>
    <b-form-input id="name" v-model="poll.name" type="text"></b-form-input>
  </b-input-group>


  <b-form-group label="Description" class="mt-2 mb-0">
    <quilleditor ref="quilleditor" v-model="poll.description"></quilleditor>
  </b-form-group>


  <h2 class="mt-4">Les résponses</h2>
  <b-form inline class="mt-2" v-for="(option, optionIndex) in poll.options" :key="optionIndex">

    <b-input-group class="mt-2">
      <template #prepend>
          <b-input-group-text class="font-weight-bold">Réponse {{optionIndex + 1}}</b-input-group-text>
      </template>
      <b-form-input id="name" v-model="option.name" type="text"></b-form-input>
    </b-input-group>

    <b-button variant="secondary" v-on:click="deleteOption(optionIndex);" class="ml-2" v-if="poll.options.length > 2"><delete-icon/></b-button>
    <b-button variant="secondary" v-on:click="addOption();" class="ml-2"><plus-circle-outline-icon/></b-button>
  </b-form>


  <div class="text-center mt-4">
    <b-button variant="success" v-on:click="addPoll()">Soumettre le sondage</b-button>
  </div>

</div>
</template>

<script>
import axios from 'axios'
import quilleditor from '@/components/QuillEditor.vue'

export default {
  components: {
    quilleditor
  },
  data: function () {
    return {
      clubId: undefined,
      poll: {name: '', description: '', options: [{name: ''}, {name: ''}]},
    }
  },
  methods: {
    addPoll: function() {

      var json = {
        name: this.poll.name,
        description: this.poll.description,
        clubId: this.clubId,
        userId: this.$parent.userSession.user.id,
        options: this.poll.options,
       };

      axios.post("/api/club-poll/add-club-poll/", json)
      .then(() => {
        this.$bvModal.msgBoxOk("Votre sondage a bien été créé. L'administrateur de l'asso doit maintenant le valider.", { centered: true, noFade: true }).then(() => {
          this.$router.push('/club-dashboard.html?clubId=' + this.clubId);
          this.$parent.refresh = false;
        });

      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
    addOption: function() {
      this.poll.options.push({name: ''});
    },
    deleteOption: function(index) {
      this.poll.options.splice(index, 1);
    },
  },
  beforeMount() {
    this.$parent.init();
    this.clubId = this.$route.query.clubId;

    document.title = this.$parent.websiteName + " - Création d'un sondage";

    this.$parent.loaded = true;
  }
}
</script>
