<template>
<div class="container-fluid flex-fill help" v-if="$parent.loaded">

  <b-breadcrumb>
    <b-breadcrumb-item to="/"><b-img class="mb-1" width="32" height="32" src="/static/images/logo.png" alt="logo"></b-img></b-breadcrumb-item>
    <b-breadcrumb-item active class="font-weight-bold">Aide</b-breadcrumb-item>
  </b-breadcrumb>

  <h2>Sommaire</h2>
  <ul>
    <li><a href="#your-association">La création de votre association sur le site</a></li>

    <li>
      <a href="#user-register">Inscription sur le site</a>
    </li>
    <li>
      <a href="#club">Mon asso</a>
      <ul>
        <li><a href="#accept-user">Accepter une demande d'adhésion</a></li>
        <li><a href="#club-rights">Les droits utlisateurs</a></li>
      </ul>
    </li>

    <li>
      <a href="#club-news">Les actualités de mon asso</a>
    </li>

    <li>
      <a href="#book">Les réservations</a>
      <ul>
        <li><a href="#book">Les états des réservations</a></li>
        <li><a href="#book-validation-methods">Les méthodes de validation des registres</a></li>
        <li><a href="#book-user-rights">Les droits utlisateurs</a></li>
        <li><a href="#book-users-admin">Gérer les utilisateurs sur un registre</a></li>
      </ul>
    </li>
    <li>
      <a href="#group-talk">Les salons de discussion</a>
    </li>

  </ul>

  <h2 id="your-association">La création de votre association sur le site</h2>
  <p>Pour vous inscrire sur le site, il faut que votre association soit connue du site. Pour cela, il suffit de nous contacter via le formulaire de <router-link to="/contact.html">contact</router-link>, en indiquant le nom de votre association, sa discipline, son adresse et son numéro d'association tel qu'il paraît sur les licences de vos adhérents. Suite à cette demande, un contrôle de vérification vous sera demandée par mail afin d'éviter les ursupations. Suite à cette demande, la référence de votre association sera créée sur le site, et vous pourrez alors créer votre compte. Vous deviendrez alors gestionnaire de l'association sur <em>{{ $parent.websiteName }}</em>. Vous pourrez alors avertir vos adhérent de s'inscrire sur le site.</p>


  <h2 id="user-register">Inscription sur le site</h2>
  <p>Vous souhaitez créer un compte, il vous suffit de cliquer sur le formulaire d'<router-link to="/register.html">inscription</router-link> et de suivre la procédure. Si vous ne trouvez pas votre discpline, ou votre asso, c'est tout simplement que votre association n'utilise pas (encore) <em>{{ $parent.websiteName}}</em>. Parlez en à vos responsables associatifs afin qu'ils se fassent connaître sur le site.</p>
  <p>Une fois que votre compte est créé, les gestionnaire de votre association recevront une alerte afin de vous autoriser à accéder aux ressources de l'association. Tant que le compte n'est pas validé, vous n'aurez pas accès aux fonctionnalités. Cette procédure est nécessaire, afin d'éviter tout forme d'ursupation.</p>


  <h2 id="club">Mon Asso</h2>

  <h3 id="accept-user">Accepter une demande d'adhésion</h3>
  <p>Lorsqu'un nouvel adhérent s'inscrira sur le site, les administrateurs de l'asso concernée, recoivent une alerte email. <alert-outline-icon/> <u>Les administrateurs sont responsables des utilisateurs qui laissent entrer en tant qu'adhérent de leur asso</u>. Une personne affiliée à une asso aura accès aux idendités des adhérents de l'asso. Pour rappel, il est très facile de créer un compte en mettant une fausse identité. Nous conseillons aux adminisatrateurs d'asso de demander à la personne qui souhaite rejoindre votre asso, une copie de sa carte d'identité, ou une copie de sa carte d'adhérent avant toute autorisation de son inscription.</p>
  <p>Pour autoriser un utilisateur a rejoindre une asso, il faut se rendre sur le tableau de bord de l'asso, de cliquer sur <em>Utilisateurs ▹ Liste des utilisateurs</em>, puis de cliquer sur l'onglet <em>demandes d'adhésion</em>. Dans cette liste, vous verrez les utilisateurs qui souhaitent adhérer à votre asso. Pour autoriser ou refuser la demande, cliquer sur l'icone <eye-outline-icon/> à droite. Vous êtes alors inviter à contrôler les informations de la personne, et à accepter ou non sa demande via les boutons accepter ou refuser. La personne qui a demandé son adhésion, recevra alors un mail d'alerte lui précisant votre décision</p>

  <h3 id="club-rights">Les droits utilisateur</h3>
  <p>Au sein d'une asso, on distingue les droits suivants:</p>
  <ul>
    <li><account-outline-icon/> Utilisateur de base</li>
    <li><shield-account-outline-icon/> Administrateur de l'asso</li>
  </ul>
  <p>Un administrateur d'asso à tous les droits sur les fonctionnalités de son asso. Il peut même désigner une autre personne en tant qu'administrateur de l'asso. Ce qui est plutôt conseillé. Les administrateur sont en charge de créer les registres, et de désigner les administrateur des registres.</p>

  <p>Lorsque vous êtes marqué administrateur d'une asso, dans le dashboard de l'asso, vous verrez apparaître un menu propre aux administrateurs de l'asso.</p>


  <h2 id="club-news">Les Actualités de mon asso</h2>
  <p>Les administrateurs d'une asso peuvent diffuser de l'information à leurs adhérents en créant une actualité. Les actualités apparaissent sur le dashboard de l'asso et est consultable par tous les adhérents de l'asso. Aussi, les adhérents, qui ont activé l'option de notification, recevront l'actualité par mail.</p>
  <p>Il est possible d'affecter une date d'expiration à une actualité. Cela permet qu'elle soit automatiquement supprimée lorsque la date expire.</p>
  <p>Une actualité peut être aussi publique, c'est à dire qu'elle sera consultable par tout les internautes sur la page publique de l'asso.</p>

  <h2 id="book">Les Réservations</h2>
  <p>Un peu de vocabulaire avant de commencer: un registre est un ensemble nommé de créneaux. Un créneau est une plage horaire sur une date définie avec un nombre de place disponibles, par exemple: Samedi 3 Décembre de 14h à 15h, 2 places disponibles. Une réservation est faite par un utilisateur sur un créneau. Chaque réservation occupe une place du créneau.</p>
  <p>La fonctionnalité <i>Réservations</i> permet aux utilisateurs de s'inscrire des créneaux horaires. Ils peuvent symboliser la réservation d'un lieu que vous mettez à disposition de vos adhérents, par exemple un terrain de tennis. Ce terrain de tennis, n'est pas disponible tous le temps. Ainsi en tant qu'administrateur d'asso, vous ne définissez que des créneaux périodiques, par exemple, tous les samedis après-midi de 14h à 18h, avec une durée d'1h pour chaque créneau. Concrétement, il vous suffit de créer un registre "terrain de tenis", un créneau "samedi 14h -18H durée 60min".<em>{{ $parent.websiteName}}</em> générera alors tous les créneaux correspondants, et les adhérents de votre asso, verront alors apparaître ce nouveau registre et pourront réserver leur séance.</p>

  <h3 id="book-state">Les états des réservations</h3>
  <p>Les réservations peuvent avoir 3 états.</p>
  <ul>
    <li><clock-outline-icon class="text-secondary"/> en liste d'attente</li>
    <li><circle-icon class="text-secondary"/> en attente de réservation</li>
    <li><check-circle-icon class="text-success"/> validé</li>
  </ul>
  <p>Il n'existe pas d'état <i>refusé</i>. A la place, vous pouvez supprimer la réservation d'un adhérent.</p>

  <h3 id="book-validation-methods">Les méthodes de validation des registres</h3>
  <p>Il existe 3 types de validation des réservations:
    <ul>
      <li>validation manuelle: Toutes les actualités réservations sur un registre seront en attente de validation de la part d'un modérateur ou d'un administrateur.</li>
      <li>validé par défaut: Toutes les actualités réservations sur un registre sont validées par défaut.</li>
      <li>validation automatique: Une réservation sera validée sur un responsable est inscrit sur le créneau.</li>
    </ul>
  </p>
  <p>Quelque-soit la methode choisie, les administrateurs et modérateurs d'un registre auront toujours la possibilité de modifier un état de réservation</p>

  <h3 id="book-user-rights">Les droits utilisateur</h3>
  <p>Certaines icones peuvent apparaître à côté des noms des personnes. Elles correspondent aux droits sur les registres.</p>
  <ul>
    <li><clock-outline-icon/> en attente</li>
    <li><eye-outline-icon/> Utilisateur en lecture seule</li>
    <li><account-outline-icon/> Utilisateur de base</li>
    <li><shield-star-outline-icon/> Modérateur du registre</li>
    <li><shield-account-outline-icon/> Administrateur du registre</li>
  </ul>

  <p>Ces droits sont spécifique à un registre. Il est tout à fait possible qu'une personne soit administrateur d'un registre et simple utilisateur d'un autre.</p>
  <p>Le tableau suivant résume les différents droits et actions possibles sur un registre.</p>
  <b-table striped :items="bookRights" :fields="bookRightsFields"></b-table>

  <p>Seuls les administrateurs du club peuvent décider qui est administrateur d'un registre. Un administrateur d'un registre peut changer les droits des utilisateurs au sein de leur registre, par exemple, passer un utilisateur en modérateur du registre. Ils ne peuvent pas mettre un utilisateur administrateur du registre.</p>

  <h3 id="book-users-admin">Gérer les utilisateurs sur un registre</h3>
  <p>Pour gérér les utilisateur sur un registre, il faut aller sur la page des utilisateurs du registre concerné. Pour cela, depuis le dashboard de votre asso, sélectionner l'onglet registre. Vous verrez alors tous les registres de votre asso. Sur la droite, l'icone <account-multiple-icon/> apparaît si vous avez des droits d'administration sur le registre. Si vous cliquez sur cette icône, vous êtes rédirigés sur la gestion des utilisateurs du registre.</p>

  <h4>Ajouter un utilisateur à un registre</h4>
  <p>La page contient 2 onglets: <i>Les utilisateurs du registre</i>, et <i>Tous les adhérents de l'asso</i>. Pour ajouter une personne qui ne fait encore parti du registre, il faut sélectionner l'onglet Tous les adhérents de l'asso. Chercher dans la liste la personne que vous souhaitez ajouter. Pour cela, vous pouvez vous aider de la recherche, en haut à gauche, en entrant son prénom ou son nom de famille. Une fois la personne identifié, vous devez sélectionner le droit que vous souhaitez affecté à cette personne sur ce registre.</p>
  <p>Pour l'ajout d'un droit, il faut cliquer sur l'une des icones, pour affecter un droit</p>
  <p>
    <b-form-radio-group button-variant="outline-dark" name="radio-btn-outline" buttons>
      <template>
        <b-form-radio value="-1" button-variant="outline-secondary"><clock-outline-icon/></b-form-radio>
        <b-form-radio value="0"><eye-outline-icon/></b-form-radio>
        <b-form-radio value="1" button-variant="outline-primary"><account-outline-icon/></b-form-radio>
        <b-form-radio value="3" button-variant="outline-success"><shield-star-outline-icon/></b-form-radio>
        <b-form-radio value="4" button-variant="outline-danger"><shield-account-outline-icon/></b-form-radio>
      </template>
    </b-form-radio-group>
  </p>
  <p>Une confirmation sera alors demandée pour valider votre demande. La personne apparaitra aussi dans l'onglet <i>Les utilisateurs du registre</i>. Pour savoir quel droit affecter, aidez vous du tableau qui résume les différents droits.</p>

  <h4>Enlever un utilisateur à un registre</h4>
  <p>Pour enlever un utilisateur d'un registre, il suffit d'aller sur l'onglet <i>Les utlisateurs du registre</i>, chercher la personne et cliquer sur l'icone <delete-icon/>. Une confirmation sera alors demandée.</p>

  <h3>Flags</h3>
  <p>Certaines personnes peuvent avoir des flags. Ces flags n'apportent aucun droits supplémentaires sur le registre. Par contre, cela peut modifier le comportement de validation des réservations.</p>
  <ul>
    <li><bookmark-outline-icon/> flag responsable: par exemple, une personne qui à les clés du local pour pouvoir ouvrir le lieu.</li>
  </ul>
  <p>Si votre registre est en mode auto-validation, par défaut, les réservations de vos adhérents seront en attente de validation, <i>icone <circle-icon class="text-secondary"/></i>. Si un responsable <bookmark-outline-icon/> s'inscrit sur ce créneau, alors toutes les réservations seront automatiquement validées, <i>icone <check-circle-icon/></i>.</p>


  <h2 id="group-talk">Les salons de discussion</h2>
  <p>Les salons de discussions sont des espaces ou les adhérents d'une association peuvent discuter entre eux. Un salon de discussion n'est visible que par les adhérents de l'association.</p>
  <p>Seuls les administrateurs de l'asso peuvent créer des salons. La création se fait depuis le menu <em>discussion ▹ gestions des salons</em> situé dans la barre d'administration de l'asso.</p>
  <p>Pour supprimer un salon, il faut se rendre dans l'onglet salon, et de cliquer sur l'icone <delete-icon/> situé à droite du nom du salon. Suite à sa suppression, tous les messages du salon sont supprimés.</p>

</div>
</template>

<script>
export default {
  data: function () {
    return {
      bookRightsFields: [
        { key: 'action', label: 'actions' },
        { key: 'waiting', label: 'en attente' },
        { key: 'readOnly', label: 'en lecture seule' },
        { key: 'basic', label: 'utilsateur de base' },
        { key: 'moderator', label: 'modérateur' },
        { key: 'admin', label: 'administrateur' },
      ],
      bookRights: [
        {action: 'Voir les réservations', waiting: '', readOnly: '✓', basic: '✓', moderator: '✓', admin: '✓'},
        {action: 'Réserver ou annuler sa réservation', waiting: '', readOnly: '', basic: '✓', moderator: '✓', admin: '✓'},
        {action: 'Accepter ou annuler une réservation de n\'importe qui', waiting: '', readOnly: '', basic: '', moderator: '✓', admin: '✓'},
        {action: 'Voir le numéro de téléphone et email', waiting: '', readOnly: '', basic: '', moderator: '✓', admin: '✓'},
        {action: 'Modifier les flags d\'un utilisateur', waiting: '', readOnly: '', basic: '', moderator: '', admin: '✓'},
        {action: 'Changer les droits d\'un utilisateur', waiting: '', readOnly: '', basic: '', moderator: '', admin: '✓'},
      ]
    }
  },
  methods: {
  },
  mounted() {
    let section = this.$route.hash.replace("#", "");
    if (section && section.length > 0) {
        this.$nextTick(()=> window.document.getElementById(section).scrollIntoView());
    }
  },
  beforeMount() {
    this.$parent.init();
    document.title = this.$parent.websiteName + " - Aide";

    this.$parent.loaded = true;
  }
}
</script>
