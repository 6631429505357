<template>
<div class="container-fluid flex-fill" v-if="$parent.loaded">

  <b-breadcrumb>
    <b-breadcrumb-item to="/"><b-img class="mb-1" width="32" height="32" src="/static/images/logo.png" alt="logo"></b-img></b-breadcrumb-item>
    <b-breadcrumb-item :to="'/club-dashboard.html?clubId=' + club.id"><office-building-icon/></b-breadcrumb-item>
    <b-breadcrumb-item :to="'/book.html?hash=' + book.hash"><book-icon/></b-breadcrumb-item>
    <b-breadcrumb-item active class="font-weight-bold">Utilisateurs {{ book.name }}</b-breadcrumb-item>
  </b-breadcrumb>

  <b-card no-body>
    <b-tabs card>
      <b-tab title="Utilisateurs du registre" active>
        <b-row class="mt-3">
          <b-col cols="5">
            <b-input-group>
              <b-form-input type="search" v-model="searchText" placeholder="Chercher des utilisateurs"></b-form-input>
              <template v-slot:append>
                <b-button type="submit" v-on:click="search()"><magnify-icon/></b-button>
              </template>
            </b-input-group>
          </b-col>
          <b-col cols="7">
            <b-pagination v-model="currentPage" :total-rows="searchedUsers.length" :per-page="numberOfRowsPerPage" first-number align="center"></b-pagination>
          </b-col>
        </b-row>

        <b-row class="text-center bg-dark text-white font-weight-bold mt-0 mb-2 ml-0 mr-0 align-items-center">
          <b-col lg="1">ID</b-col>
          <b-col lg="2">Prénom NOM</b-col>
          <b-col lg="2">email</b-col>
          <b-col lg="2">Téléphone</b-col>
          <b-col lg="4">Droits &amp; Flags</b-col>
          <b-col lg="1"></b-col>
        </b-row>

        <b-row class="mb-2 ml-0 mr-0 text-center" :class="{'bg-light' : userIndex % 2 == 1 && user.id != $parent.switchBookUserId, 'bg-yellow-200': $parent.switchBookUserId && user.id == $parent.switchBookUserId}" align-v="center" v-for="(user, userIndex) in searchedUsers.slice((currentPage - 1) * numberOfRowsPerPage, (currentPage - 1) * numberOfRowsPerPage + numberOfRowsPerPage)" :key="userIndex">
          <b-col lg="1" class="small">{{ user.id }}</b-col>
          <b-col lg="2">{{ user.firstname }} {{ user.lastname }}</b-col>
          <b-col lg="2">{{ user.email }}</b-col>
          <b-col lg="2">{{ user.phoneNumber }}</b-col>
          <b-col lg="4">
            <b-form-radio-group class="mr-2" v-model="user.newRight" button-variant="outline-dark" name="radio-btn-outline" buttons v-on:change="updateBookRight(user, false)">
              <template>
                <b-form-radio value="-100"><delete-icon/></b-form-radio>
                <b-form-radio value="-1" button-variant="outline-secondary"><clock-outline-icon/></b-form-radio>
                <b-form-radio value="0"><eye-outline-icon/></b-form-radio>
                <b-form-radio value="1" button-variant="outline-primary"><account-outline-icon/></b-form-radio>
                <b-form-radio value="3" button-variant="outline-success"><shield-star-outline-icon/></b-form-radio>
                <b-form-radio value="4" button-variant="outline-danger"><shield-account-outline-icon/></b-form-radio>
              </template>
            </b-form-radio-group>

            <b-form-checkbox v-model="user.isManager" button-variant="outline-dark" button v-on:change="updateUserFlag(user, 1, false)"><bookmark-outline-icon/></b-form-checkbox>
          </b-col>
          <b-col lg="1">
            <span class="c-hand h3" v-on:click="$parent.switchUser(user.id, user.firstname + ' ' + user.lastname)"><account-switch-icon/></span>
          </b-col>
        </b-row>
      </b-tab>

      <b-tab title="Tous les adhérents de l'asso">
        <b-row class="mt-3">
          <b-col cols="5">
            <b-input-group>
              <b-form-input type="search" v-model="searchText" placeholder="Chercher des utilisateurs"></b-form-input>
              <template v-slot:append>
                <b-button type="submit" v-on:click="search()"><magnify-icon/></b-button>
              </template>
            </b-input-group>
          </b-col>
          <b-col cols="7">
            <b-pagination v-model="currentPageAllUsers" :total-rows="searchedClubUsers.length" :per-page="numberOfRowsPerPage" first-number align="center"></b-pagination>
          </b-col>
        </b-row>

        <b-row class="text-center bg-dark text-white font-weight-bold mt-0 mb-2 ml-0 mr-0 align-items-center">
          <b-col lg="1">ID</b-col>
          <b-col lg="3">Prénom NOM</b-col>
          <b-col lg="2">email</b-col>
          <b-col lg="2">Téléphone</b-col>
          <b-col lg="4">Droits</b-col>
        </b-row>

        <b-row class="mb-2 ml-0 mr-0 text-center" :class="{'bg-light' : userIndex % 2 == 1}" align-v="center" v-for="(user, userIndex) in searchedClubUsers.slice((currentPageAllUsers - 1) * numberOfRowsPerPage, (currentPageAllUsers - 1) * numberOfRowsPerPage + numberOfRowsPerPage)" :key="userIndex">
          <b-col lg="1" class="small">{{ user.id }}</b-col>
          <b-col lg="3">{{ user.firstname }} {{ user.lastname }}</b-col>
          <b-col lg="2">{{ user.email }}</b-col>
          <b-col lg="2">{{ user.phoneNumber }}</b-col>
          <b-col lg="4">
            <b-form-radio-group v-model="user.newRight" button-variant="outline-dark" name="radio-btn-outline" buttons v-on:change="updateBookRight(user, false)">
              <template>
                <b-form-radio value="-100" :disabled="user.right == -100"><delete-icon/></b-form-radio>
                <b-form-radio value="-1" button-variant="outline-secondary"><clock-outline-icon/></b-form-radio>
                <b-form-radio value="0"><eye-outline-icon/></b-form-radio>
                <b-form-radio value="1" button-variant="outline-primary"><account-outline-icon/></b-form-radio>
                <b-form-radio value="3" button-variant="outline-success"><shield-star-outline-icon/></b-form-radio>
                <b-form-radio value="4" button-variant="outline-danger"><shield-account-outline-icon/></b-form-radio>
              </template>
            </b-form-radio-group>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </b-card>

</div>
</template>

<script>
import axios from 'axios'

export default {
  data: function () {
    return {
      book: undefined,
      bookHash: undefined,
      clearSwitchUserTimer: undefined,
      club: undefined,
      clubId: undefined,
      clubUsers: [],
      currentPage: 1,
      numberOfRowsPerPage: 50,
      currentPageAllUsers: 1,
      searchText: '',
      users: [],
      userRightsMap: new Map(),
      userFlagsMap: new Map(),
    }
  },
  computed: {
    searchedUsers: function () {

      let searchTextLC = this.searchText.toLowerCase();
      var searchUsers = this.users.filter(row => {
        let filterOnName = (this.searchText != undefined && this.searchText.length >= 2);

        let filterOnNameIsOK = true;

        if (filterOnName) {
          filterOnNameIsOK = (row.lastname.toLowerCase().includes(searchTextLC) || row.firstname.toLowerCase().includes(searchTextLC));
        }

        return filterOnNameIsOK;

      });
      return searchUsers;
    },
    searchedClubUsers: function () {

      let searchTextLC = this.searchText.toLowerCase();
      var searchUsers = this.clubUsers.filter(row => {
        let filterOnName = (this.searchText != undefined && this.searchText.length >= 2);
        let filterOnIsVerified = true;

        let filterOnNameIsOK = true;
        let filterOnIsVerifiedIsOk = true;

        if (filterOnName) {
          filterOnNameIsOK = (row.lastname.toLowerCase().includes(searchTextLC) || row.firstname.toLowerCase().includes(searchTextLC));
        }

        if (filterOnIsVerified) {
          filterOnIsVerifiedIsOk = row.isVerified;
        }

        return filterOnNameIsOK && filterOnIsVerifiedIsOk;

      });
      return searchUsers;
    }
  },
  methods: {
    deleteBookRight: function(user, confirm) {
      if (!confirm) {

        this.$bvModal.msgBoxConfirm('Voulez-vous enlever l\'utilisateur ' + user.firstname + ' ' + user.lastname + ' du registre ?' , { okVariant: 'success', okTitle: 'Oui', cancelTitle: 'Non', hideHeaderClose: true, centered: true, noFade: true }).then((value) => {
            if (value) {
              this.deleteBookRight(user, true);
            } else {
              user.newRight = user.right;
            }
          });
        return;
      }
      this.$parent.refresh = true;

      var json = {
        userId: user.id,
        bookId: this.book.id,
       };

      axios.post("/api/book-user-right/delete-user-right/", json)
      .then(() => {
        let p1 = this.getUsers();
        let p2 = this.getClubUsers();
        Promise.all([p1, p2]).then((responses) => {
          this.processGetUsersResponse(responses[0]);
          this.processGetClubUsersResponse(responses[1]);
          this.$parent.refresh = false;
        });
      }).catch(error => {
        user.newRight = user.right;
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
    getBook: function() {
      return axios.get("/api/book/get-book/" + this.bookHash + "/");
    },
    processGetBookResponse: function(response) {
      this.book = response.data;
    },
    getClubUsers: function() {
      return axios.get("/api/user-club/get-club-users/" + this.clubId + "/");
    },
    processGetClubUsersResponse: function(response) {
      let rawUsers = response.data;
      this.clubUsers = [];
      for (let rawUserKey in rawUsers) {
        let rawUser = rawUsers[rawUserKey];
        let user = {id: rawUser.user.id, firstname: rawUser.user.firstname, lastname: rawUser.user.lastname, phoneNumber: rawUser.user.phoneNumber, email: rawUser.user.email, isVerified: rawUser.isVerified};
        this.clubUsers.push(user)
        if (this.userRightsMap[user.id]) {
          this.$set(user, 'right', this.userRightsMap[user.id].right);
          this.$set(user, 'newRight', this.userRightsMap[user.id].right);
        } else {
          this.$set(user, 'right', -100);
          this.$set(user, 'newRight', -100);
        }
      }
    },
    getUsers: function() {
      return axios.get("/api/book/get-users/" + this.bookHash + "/");
    },
    processGetUsersResponse: function(response) {
      let rawUsers = response.data;
      this.users = [];
      this.userRightsMap = new Map();
      for (let rawUserKey in rawUsers) {
        let rawUser = rawUsers[rawUserKey];
        let isManager = false;

        if (this.userFlagsMap[rawUser.user.id] != undefined) {
          for (let flagKey in this.userFlagsMap[rawUser.user.id]) {
            let flag = this.userFlagsMap[rawUser.user.id][flagKey];
            if (flag.id == 1) {
              isManager = true;
            }
          }
        }

        this.users.push({id: rawUser.user.id, firstname: rawUser.user.firstname, lastname: rawUser.user.lastname, phoneNumber: rawUser.user.phoneNumber, email: rawUser.user.email, right: rawUser.right, newRight: rawUser.right, isManager: isManager });
        this.userRightsMap[rawUser.user.id] = {right: rawUser.right, newRight: rawUser.right};

      }
    },
    getUserFlags: function() {
      return axios.get("/api/book-user-flag/get-all-flags-by-book/" + this.bookHash + "/");
    },
    processGetUserFlagsResponse: function(response) {
      this.userFlagsMap = response.data;
    },
    updateBookRight: function(user, confirm) {
      if (user.newRight == -100) {
        this.deleteBookRight(user, confirm);
        return;
      }

      if (!confirm) {
        this.$bvModal.msgBoxConfirm('Voulez-vous mettre à jour les droits de l\'utilisateur ' + user.firstname + ' ' + user.lastname + ' sur le registre ?' , { okVariant: 'success', okTitle: 'Oui', cancelTitle: 'Non', hideHeaderClose: true, centered: true, noFade: true }).then((value) => {
            if (value) {
              this.updateBookRight(user, true);
            } else {
              user.newRight = user.right;
            }
          });
        return;
      }
      this.$parent.refresh = true;

      var json = {
        userId: user.id,
        right: user.newRight,
        bookId: this.book.id,
       };

      axios.post("/api/book-user-right/update-user-right/", json)
      .then(() => {
        let p1 = this.getUsers();
        let p2 = this.getClubUsers();
        Promise.all([p1, p2]).then((responses) => {
          this.processGetUsersResponse(responses[0]);
          this.processGetClubUsersResponse(responses[1]);
          this.$parent.refresh = false;
        });
      }).catch(error => {
        user.newRight = user.right;
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
    updateUserFlag: function(user, flag, confirm) {
      if (!confirm) {
        this.$bvModal.msgBoxConfirm('Voulez-vous mettre à jour les flags de l\'utilisateur ' + user.firstname + ' ' + user.lastname + ' ?' , { okVariant: 'success', okTitle: 'Oui', cancelTitle: 'Non', hideHeaderClose: true, centered: true, noFade: true }).then((value) => {
            if (value) {
              this.updateUserFlag(user, flag, true);
            } else {
              if (flag == 1) user.isManager = !user.isManager;
            }
          });
        return;
      }
      this.$parent.refresh = true;

      var json = {
        userId: user.id,
        id: flag,
        bookId: this.book.id,
       };

      let url = "/api/book-user-flag/add-user-flag/";
      if (flag == 1 && !user.isManager) {
        url = "/api/book-user-flag/delete-user-flag/";
      }

      axios.post(url, json)
      .then(() => {
          this.$parent.refresh = false;
      }).catch(error => {
        if (flag == 1) user.isManager = !user.isManager;
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
  },
  beforeMount() {
    this.$parent.init();
    this.bookHash = this.$route.query.hash;
    this.clubId = this.$route.query.clubId;

    let p1 = this.getBook();
    let p2 = this.getUserFlags();
    let p3 = this.getUsers();
    let p4 = this.getClubUsers();
    Promise.all([p1, p2, p3, p4]).then((responses) => {
      this.processGetBookResponse(responses[0]);
      this.processGetUserFlagsResponse(responses[1]);
      this.processGetUsersResponse(responses[2]);
      this.processGetClubUsersResponse(responses[3]);

      this.club = this.$parent.myUserClubMap[this.book.clubId].club;
      document.title = this.$parent.websiteName + " - Liste utilisateurs du registre " + this.book.name;
      this.$parent.loaded = true;
    }).catch(()=>{
      this.$router.push('/error.html');
    });
  }
}
</script>
