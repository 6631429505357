<template>
<div class="container-fluid flex-fill" v-if="$parent.loaded">

  <b-breadcrumb>
    <b-breadcrumb-item to="/admin/"><cog-icon/></b-breadcrumb-item>
    <b-breadcrumb-item active class="font-weight-bold"><shape-icon/> Catégories Forum</b-breadcrumb-item>
  </b-breadcrumb>

  <b-card no-body>
    <b-tabs card>
      <b-tab>
        <template v-slot:title>
          <package-variant-closed-icon/> Les catégories
        </template>

        <div class="alert alert-secondary mb-0 text-center h5" role="alert" v-if="categories.length == 0">
          Aucune catégorie trouvé.
        </div>

        <div v-if="categories.length > numberOfRowsPerPage">
          <b-pagination v-model="currentPage" :total-rows="categories.length" :per-page="numberOfRowsPerPage" first-number align="center"></b-pagination>
        </div>

        <b-row align-v="center" :class="{'even-line-background' : categoryIndex % 2 == 1}" class="p-2" v-for="(category, categoryIndex) in categories.slice((currentPage - 1) * numberOfRowsPerPage, (currentPage - 1) * numberOfRowsPerPage + numberOfRowsPerPage)" :key="categoryIndex">
          <b-col cols="12" class="p-2">
            <b-form-group class="mt-2 mb-0" label-class="font-weight-bold">
              <b-form-input :id="'name-' + categoryIndex" v-model="category.name" type="text"></b-form-input>
            </b-form-group>
            <div class="text-center mt-2">
              <b-button variant="success" class="mr-2" v-on:click="updateCategory(category);"><content-save-icon/>Mettre à jour</b-button>
              <b-button variant="danger" v-on:click="deleteCategory(category.id, false);"><delete-icon/> Supprimer</b-button>
            </div>
          </b-col>
        </b-row>
      </b-tab>

      <b-tab>
        <template v-slot:title>
          <plus-circle-outline-icon/> Ajout d'une catégorie
        </template>

        <b-input-group class="mt-2">
          <template #prepend>
              <b-input-group-text class="font-weight-bold">Nom</b-input-group-text>
          </template>
          <b-form-input id="new-category-name" v-model="newCategory.name" type="text"></b-form-input>
        </b-input-group>

        <b-row class="text-center mt-4">
          <b-col sm="12">
            <b-button id="add-category-button" v-on:click="addCategory" :variant="isFormValid() ? 'success': 'secondary'"  :disabled="!isFormValid()">Ajouter la catégorie</b-button>
          </b-col>
        </b-row>

      </b-tab>
    </b-tabs>
  </b-card>
</div>
</template>

<script>
import axios from 'axios'

export default {
  components: {
  },
  data: function () {
    return {
      categories: [],
      newCategory: {name: ''},
      numberOfRowsPerPage: 20,
      currentPage: 1,
    }
  },
  methods: {
    addCategory: function() {
      this.$parent.refresh = true;
      var json = {
        name: this.newCategory.name,
       };

      axios.post("/api/site-forum-category/add-site-forum-category/", json)
      .then(() => {
        this.getSiteForumCategories().then(response => {
          this.processGetSiteForumCategoriesResponse(response);
          this.$bvModal.msgBoxOk("La catégorie a été ajoutée.", { centered: true, noFade: true });
          this.newCategory = {name: ''};
          this.$parent.refresh = false;
        });
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
    deleteCategory: function(categoryId, confirm) {
      if (!confirm) {

        this.$bvModal.msgBoxConfirm('Voulez-vous supprimer la catégorie ? ATTENTION: Tous les sujets de cette catégorie seront supprimés.' , { okVariant: 'success', okTitle: 'Oui', cancelTitle: 'Non', hideHeaderClose: true, centered: true, noFade: true }).then((value) => {
            if (value) {
              this.deleteCategory(categoryId, true);
            }
          });
        return;
      }
      this.$parent.refresh = true;

      var json = {
        categoryId: categoryId,
       };

      axios.post("/api/site-forum-category/delete-site-forum-category/", json)
      .then(() => {
        this.getSiteForumCategories().then(response => {
          this.processGetSiteForumCategoriesResponse(response);
          this.$bvModal.msgBoxOk("La catégorie a été supprimée.", { centered: true, noFade: true });
          this.$parent.refresh = false;
        });
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
    isFormValid: function() {
      return this.isNameValid();
    },
    isNameValid: function() {
      if (this.newCategory.name.length == 0) return null;
      return this.newCategory.name.length > 0 && this.newCategory.name.length <= 100;
    },
    getSiteForumCategories: function() {
      return axios.get("/api/site-forum-category/get-site-forum-categories/");
    },
    processGetSiteForumCategoriesResponse: function(response) {
      this.categories = response.data;
    },
    updateCategory: function(category) {
      this.$parent.refresh = true;
      var json = {
        categoryId: category.id,
        name: category.name,
       };

      axios.post("/api/site-forum-category/update-site-forum-category/", json)
      .then(() => {
        this.getSiteForumCategories().then(response => {
          this.processGetSiteForumCategoriesResponse(response);
          this.$bvModal.msgBoxOk("La catégorie a été mise à jour.", { centered: true, noFade: true });
          this.$parent.refresh = false;
        });
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
  },
  beforeMount() {
    this.$parent.init();
    this.$parent.inAdmin = true;

    let p1 = this.getSiteForumCategories();
    Promise.all([p1]).then((responses) => {
      this.processGetSiteForumCategoriesResponse(responses[0]);
      document.title = this.$parent.websiteName + " - Gestion des catégories de forum du site";
      this.$parent.loaded = true;
    }).catch((error)=>{
      console.log(error);
      //this.$router.push('/error.html');
    });
  }
}
</script>
