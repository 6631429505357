<template>
<div class="container-fluid flex-fill" v-if="$parent.loaded">

  <b-breadcrumb>
    <b-breadcrumb-item to="/"><b-img class="mb-1" width="32" height="32" src="/static/images/logo.png" alt="logo"></b-img></b-breadcrumb-item>
    <b-breadcrumb-item :to="'/club-dashboard.html?clubId=' + clubId" ><office-building-icon/></b-breadcrumb-item>
    <b-breadcrumb-item active class="font-weight-bold"><poll-icon/> Les sondages</b-breadcrumb-item>
  </b-breadcrumb>

  <b-card no-body>
    <b-tabs card>
      <b-tab>
        <template v-slot:title>
          <poll-icon/> Les sondages publiés
        </template>

        <div class="alert alert-secondary mb-0 text-center h5" role="alert" v-if="polls.length == 0">
          Aucun sondage publié.
        </div>

        <b-row align-v="center" :class="{'even-line-background' : pollIndex % 2 == 1}" class="p-2" v-for="(poll, pollIndex) in polls.slice((currentPage - 1) * numberOfRowsPerPage, (currentPage - 1) * numberOfRowsPerPage + numberOfRowsPerPage)" :key="pollIndex">
          <b-col cols="10" class="p-2">
            <span class="font-weight-bold">{{ poll.name }}</span>
          </b-col>
          <b-col cols="2" class="text-right">
            <span class="ml-2 c-hand h3 text-primary" v-on:click="archivePoll(poll.id, false);"><archive-icon/></span>
            <span class="ml-2 c-hand h3 text-danger" v-on:click="deletePoll(poll.id, false);"><delete-icon/></span>
          </b-col>
        </b-row>

      </b-tab>

      <b-tab>
        <template v-slot:title>
          <clock-outline-icon/> Les sondages en attente de validation
        </template>

        <div class="alert alert-secondary mb-0 text-center h5" role="alert" v-if="pollsWaitingValidation.length == 0">
          Aucun sondage publié.
        </div>

        <b-row align-v="center" :class="{'even-line-background' : pollIndex % 2 == 1}" class="p-2" v-for="(poll, pollIndex) in pollsWaitingValidation" :key="pollIndex">
          <b-col cols="10" class="p-2">
            <span class="font-weight-bold">{{ poll.name }}</span>
          </b-col>
          <b-col cols="2" class="text-right">
            <span class="ml-2 c-hand h3 text-success" v-on:click="validatePoll(poll.id, false);"><check-circle-icon/></span>
            <span class="ml-2 c-hand h3 text-danger" v-on:click="deletePoll(poll.id, false);"><delete-icon/></span>
          </b-col>
        </b-row>
      </b-tab>

      <b-tab>
        <template v-slot:title>
          <archive-icon/> Les sondages archivés
        </template>

        <div class="alert alert-secondary mb-0 text-center h5" role="alert" v-if="pollsArchived.length == 0">
          Aucun sondage archivé.
        </div>

        <b-row align-v="center" :class="{'even-line-background' : pollIndex % 2 == 1}" class="p-2" v-for="(poll, pollIndex) in pollsArchived" :key="pollIndex">
          <b-col cols="10" class="p-2">
            <span class="font-weight-bold">{{ poll.name }}</span>
          </b-col>
        </b-row>
      </b-tab>

    </b-tabs>
  </b-card>
</div>
</template>

<script>
import axios from 'axios'

export default {
  data: function () {
    return {
      polls: [],
      pollsArchived: [],
      pollsWaitingValidation: [],
      club: undefined,
      clubId: undefined,
      numberOfRowsPerPage: 20,
      currentPage: 1,
    }
  },
  methods: {
    archivePoll: function(pollId, confirm) {
      if (!confirm) {

        this.$bvModal.msgBoxConfirm('Voulez-vous archiver le sondage ?' , { okVariant: 'success', okTitle: 'Oui', cancelTitle: 'Non', hideHeaderClose: true, centered: true, noFade: true }).then((value) => {
            if (value) {
              this.archivePoll(pollId, true);
            }
          });
        return;
      }
      this.$parent.refresh = true;

      this.changePollStatus(pollId, 2);
    },
    changePollStatus: function(pollId, status) {
      var json = {
        pollId: pollId,
        status: status,
       };

      axios.post("/api/club-poll/change-club-poll-status/", json)
      .then(() => {
        let p1 = this.getPolls();
        let p2 = this.getPollsWaitingValidation();
        let p3 = this.getPollsArchived();
        Promise.all([p1, p2, p3]).then((responses) => {
          this.processGetPollsResponse(responses[0]);
          this.processGetPollsWaitingValidationResponse(responses[1]);
          this.processGetPollsArchivedResponse(responses[2]);
          this.$parent.refresh = false;
        });
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
    deletePoll: function(pollId, confirm) {
      if (!confirm) {

        this.$bvModal.msgBoxConfirm('Voulez-vous supprimer le sondage ?' , { okVariant: 'success', okTitle: 'Oui', cancelTitle: 'Non', hideHeaderClose: true, centered: true, noFade: true }).then((value) => {
            if (value) {
              this.deletePoll(pollId, true);
            }
          });
        return;
      }
      this.$parent.refresh = true;

      var json = {
        pollId: pollId,
       };

      axios.post("/api/club-poll/delete-club-poll/", json)
      .then(() => {
        let p1 = this.getPolls();
        let p2 = this.getPollsWaitingValidation();
        let p3 = this.getPollsArchived();
        Promise.all([p1, p2, p3]).then((responses) => {
          this.processGetPollsResponse(responses[0]);
          this.processGetPollsWaitingValidationResponse(responses[1]);
          this.processGetPollsArchivedResponse(responses[2]);
          this.$bvModal.msgBoxOk("Le sondage a été supprimé.", { centered: true, noFade: true });
          this.$parent.refresh = false;
        });
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
    validatePoll: function(pollId, confirm) {
      if (!confirm) {

        this.$bvModal.msgBoxConfirm('Voulez-vous accepter le sondage ?' , { okVariant: 'success', okTitle: 'Oui', cancelTitle: 'Non', hideHeaderClose: true, centered: true, noFade: true }).then((value) => {
            if (value) {
              this.validatePoll(pollId, true);
            }
          });
        return;
      }
      this.$parent.refresh = true;

      this.changePollStatus(pollId, 1);
    },
    getPolls: function() {
      return axios.get("/api/club-poll/get-club-polls/" + this.clubId + "/");
    },
    processGetPollsResponse: function(response) {
      this.polls = response.data;
    },
    getPollsArchived: function() {
      return axios.get("/api/club-poll/get-club-polls-archived/" + this.clubId + "/");
    },
    processGetPollsArchivedResponse: function(response) {
      this.pollsArchived = response.data;
    },
    getPollsWaitingValidation: function() {
      return axios.get("/api/club-poll/get-club-polls-waiting-validation/" + this.clubId + "/");
    },
    processGetPollsWaitingValidationResponse: function(response) {
      this.pollsWaitingValidation = response.data;
    },
  },
  beforeMount() {
    this.$parent.init();
    this.clubId = this.$route.query.clubId;
    this.club = this.$parent.myUserClubMap[this.clubId].club;

    let p1 = this.getPolls();
    let p2 = this.getPollsWaitingValidation();
    let p3 = this.getPollsArchived();
    Promise.all([p1, p2, p3]).then((responses) => {
      this.processGetPollsResponse(responses[0]);
      this.processGetPollsWaitingValidationResponse(responses[1]);
      this.processGetPollsArchivedResponse(responses[2]);
      document.title = this.$parent.websiteName + " - Gestion des sondages de l'asso " + this.club.name;
      this.$parent.loaded = true;
    }).catch((error)=>{
      console.log(error);
      //this.$router.push('/error.html');
    });
  }
}
</script>
