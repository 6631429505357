<template>
<div class="container-fluid flex-fill" v-if="$parent.loaded">

  <b-breadcrumb>
    <b-breadcrumb-item to="/"><b-img class="mb-1" width="32" height="32" src="/static/images/logo.png" alt="logo"></b-img></b-breadcrumb-item>
    <b-breadcrumb-item :to="'/club-dashboard.html?clubId=' + clubId"><office-building-icon/></b-breadcrumb-item>
    <b-breadcrumb-item active class="font-weight-bold">Editer Informations Asso</b-breadcrumb-item>
  </b-breadcrumb>


  <div class="alert alert-secondary mb-0" role="alert">
    Seul les champs en gras sont obligatoires. La description et les horaires seront affichés sur la page publique de votre asso. Ces champs sont optionnels, toutefois, cela peut permettre aux visiteurs d'avoir des informations complémentaires sur votre asso.
  </div>

  <b-input-group class="mt-2">
    <template #prepend>
        <b-input-group-text class="font-weight-bold">Nom</b-input-group-text>
    </template>
    <b-form-input id="name" v-model="club.name" type="text"></b-form-input>
  </b-input-group>

  <b-input-group class="mt-2">
    <template #prepend>
        <b-input-group-text>Numéro Association</b-input-group-text>
    </template>
    <b-form-input id="number" v-model="club.number" type="text"></b-form-input>
  </b-input-group>

  <b-input-group class="mt-2">
    <template #prepend>
        <b-input-group-text class="font-weight-bold">Adresse</b-input-group-text>
    </template>
    <b-form-input id="address" v-model="club.address" type="text"></b-form-input>
  </b-input-group>

  <b-input-group class="mt-2">
    <template #prepend>
        <b-input-group-text class="font-weight-bold">Code postal</b-input-group-text>
    </template>
    <b-form-input id="zip-code" v-model="club.zipCode" type="text"></b-form-input>
  </b-input-group>

  <b-input-group class="mt-2">
    <template #prepend>
        <b-input-group-text class="font-weight-bold">Ville</b-input-group-text>
    </template>
    <b-form-input id="city" v-model="club.city" type="text"></b-form-input>
  </b-input-group>

  <b-input-group class="mt-2">
    <template #prepend>
        <b-input-group-text>Téléphone</b-input-group-text>
    </template>
    <b-form-input id="phone-number" v-model="club.phoneNumber" type="text"></b-form-input>
  </b-input-group>

  <b-form-group label="Description" class="mt-2 mb-0">
    <quilleditor ref="quilleditor" id="description" v-model="club.description"></quilleditor>
  </b-form-group>

  <b-form-group label="Horaires" class="mt-2 mb-0">
    <quilleditor ref="quilleditor" id="hours" v-model="club.hours"></quilleditor>
  </b-form-group>

  <b-row class="text-center mt-4">
    <b-col sm="12">
      <b-button id="update-club-button" v-on:click="updateClub">Mettre à jour les informations</b-button>
    </b-col>
  </b-row>

</div>
</template>

<script>
import axios from 'axios'
import quilleditor from '@/components/QuillEditor.vue'

export default {
  components: {
    quilleditor
  },
  data: function () {
    return {
      club: undefined,
      clubId: undefined,
    }
  },
  methods: {
    getClub: function() {
      return axios.get("/api/club/get-club/" + this.clubId + "/");
    },
    processGetClubResponse: function(response) {
      this.club = response.data;
    },
    updateClub: function() {
      this.$parent.refresh = true;
      var json = {
        address: this.club.address,
        city: this.club.city,
        description: this.club.description,
        domainId: this.club.domainId,
        hours: this.club.hours,
        id: this.club.id,
        name: this.club.name,
        number: this.club.number,
        phoneNumber: this.club.phoneNumber,
        zipCode: this.club.zipCode,
       };

      axios.post("/api/club/update-club/", json)
      .then(() => {
        this.getClub().then(response => {
          this.processGetClubResponse(response);
          this.$bvModal.msgBoxOk("Les informations ont été mises à jour.", { centered: true, noFade: true });
          this.$parent.refresh = false;
        });
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
  },
  beforeMount() {
    this.$parent.init();
    this.clubId = this.$route.query.clubId;

    let p1 = this.getClub();
    Promise.all([p1]).then((responses) => {
      this.processGetClubResponse(responses[0]);
      document.title = this.$parent.websiteName + " - Editer informations de l'asso";
      this.$parent.loaded = true;
    }).catch((error)=>{
      console.log(error);
      //this.$router.push('/error.html');
    });
  }
}
</script>
