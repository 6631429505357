<template>
<div class="container-fluid flex-fill" v-if="$parent.loaded">

  <b-breadcrumb>
    <b-breadcrumb-item to="/"><b-img class="mb-1" width="24" height="24" src="/static/images/logo.png" alt="logo"></b-img></b-breadcrumb-item>
    <b-breadcrumb-item :to="'/club-dashboard.html?clubId=' + poll.clubId" ><office-building-icon/></b-breadcrumb-item>
    <b-breadcrumb-item active class="font-weight-bold"><message-outline-icon/> Commentaires Sondage</b-breadcrumb-item>
  </b-breadcrumb>

  <div class="alert-secondary message mb-4">
    <div class="h5 text-uppercase">{{ poll.name }}</div>
    <b-row align-v="center" class="mt-2" v-for="(option, optionIndex) in poll.options" :key="optionIndex">
      <b-col cols="12">
          <div class="text-break">{{option.name}}</div>
          <b-progress max="100" variant="secondary" v-if="poll.numberOfVotes != 0">
            <b-progress-bar :value="(100 * option.numberOfVotes / poll.numberOfVotes).toFixed(0)">
                <span class="text-white"><strong>{{ (100 * option.numberOfVotes / poll.numberOfVotes).toFixed(0) }}%</strong></span>
            </b-progress-bar>
          </b-progress>
          <b-progress max="100" :value="0" variant="secondary" v-if="poll.numberOfVotes == 0">
          </b-progress>
      </b-col>
    </b-row>

    <div class="text-right small text-muted mt-2">
      <span>Nombre de votes: {{ poll.numberOfVotes }}</span>
    </div>
  </div>

  <div class="alert alert-secondary mb-0 text-center h5 mb-4" role="alert" v-if="comments.length == 0">
    Pas de commentaires. Soyez le premier à commenter.
  </div>

  <b-pagination v-if="poll.numberOfComments > numberOfRowsPerPage" v-model="currentPage" :total-rows="poll.numberOfComments" :per-page="numberOfRowsPerPage" first-number align="center" @change="loadComments"></b-pagination>

  <div class="message mb-4" :class="commentIndex % 2 == 0 ? 'message-even' : 'message-odd'" v-for="(comment,commentIndex) in comments" :key="commentIndex">
    <span class="font-weight-bold">{{ comment.user.firstname }} {{ comment.user.lastname }}</span>
    <span v-if="comment.user.hash != $parent.userSession.user.hash"><router-link class="text-dark ml-2" title="message privé" :to="'/private-message.html?userHash=' + comment.user.hash"><message-outline-icon/></router-link></span>
    <hr class="mt-1 mb-1">
    <div v-html="comment.text" class="inner-html text-break"></div>
    <div class="small text-muted text-right mr-2">{{ comment.creationDate }}</div>
  </div>

  <b-pagination v-if="poll.numberOfComments > numberOfRowsPerPage" v-model="currentPage" :total-rows="poll.numberOfComments" :per-page="numberOfRowsPerPage" first-number align="center" @change="loadComments"></b-pagination>

  <div>
    <div style="display: flex" class="mt-0">
      <div style="display: flex; width: 100%">
        <b-form-textarea style="background-color: #DDDDDD;" id="new-message" v-model="newComment" rows="3" max-rows="3"></b-form-textarea>
      </div>
      <div style="display: flex" class="ml-2">
        <b-button variant="success" size="lg" v-on:click="addComment()" :disabled="newComment.length == 0"><send-icon/></b-button>
      </div>
    </div>
  </div>

</div>
</template>

<script>
import axios from 'axios'

export default {
  data: function () {
    return {
      poll: undefined,
      pollId: undefined,
      comments: [],
      newComment: '',
      currentPage: 1,
      numberOfRowsPerPage: 20,
      lastCommentId: 0,
    }
  },
  methods: {
    addComment: function() {
      if (this.newComment.length == 0) {
        return;
      }
      this.$parent.refresh = true;
      let newCommentHtml = '<p>' + this.newComment.replaceAll('\n', '</p><p>') + '</p>';

      var json = {
        pollId: this.pollId,
        text: newCommentHtml,
        userId: this.$parent.userSession.user.id,
       };

      axios.post("/api/club-poll-comment/add-club-poll-comment/", json)
      .then(() => {
        let p1 = this.getClubPoll();
        let p2 = this.getClubPollComments();
        Promise.all([p1, p2]).then((responses) => {
          this.processGetClubPollResponse(responses[0]);
          this.processGetClubPollCommentsResponse(responses[1]);
          this.newComment = '';
          this.$parent.refresh = false;
        });
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
    loadComments: function(pageNum) {
      this.$parent.refresh = true;
      this.lastCommentId = (pageNum - 1) * this.numberOfRowsPerPage;
      this.getClubPollComments().then(response => {
        this.processGetClubPollCommentsResponse(response);
        this.$parent.refresh = false;
      });
    },
    getClubPoll: function() {
      return axios.get("/api/club-poll/get-club-poll/" + this.pollId + "/");
    },
    processGetClubPollResponse: function(response) {
      this.poll = response.data;
    },
    getClubPollComments: function() {
      return axios.get("/api/club-poll-comment/get-club-poll-comments/" + this.pollId + "/" + this.lastCommentId + "/");
    },
    processGetClubPollCommentsResponse: function(response) {
      this.comments = response.data;
    },
  },
  beforeMount() {
    this.$parent.init();
    this.pollId = this.$route.query.clubPollId;

    let p1 = this.getClubPoll();
    let p2 = this.getClubPollComments();
    Promise.all([p1, p2]).then((responses) => {
      this.processGetClubPollResponse(responses[0]);
      this.processGetClubPollCommentsResponse(responses[1]);
      document.title = this.$parent.websiteName + " - Commentaires Sondage " + this.pollId;
      this.$parent.loaded = true;
    }).catch((error)=>{
      console.log(error)
      //this.$router.push('/error.html');
    });

  }
}
</script>
