<template>
<div class="container-fluid flex-fill" v-if="$parent.loaded">

  <b-breadcrumb>
    <b-breadcrumb-item to="/admin/"><cog-icon/></b-breadcrumb-item>
    <b-breadcrumb-item active class="font-weight-bold">Les actualités de MonAsso.ovh</b-breadcrumb-item>
  </b-breadcrumb>

  <b-card no-body>
    <b-tabs card>
      <b-tab>
        <template v-slot:title>
          <newspaper-icon/> Les actualités
        </template>

        <div class="alert alert-secondary mb-0 text-center h5" role="alert" v-if="newsList.length == 0">
          Aucun actualité trouvée.
        </div>

        <b-row align-v="center" :class="{'even-line-background' : newsIndex % 2 == 1}" class="p-2" v-for="(news, newsIndex) in newsList" :key="newsIndex">
          <b-col sm="11">
            <b-form-group class="mt-2 mb-0" label-class="font-weight-bold">
              <quilleditor ref="quilleditor" :id="'newsText-' + newsIndex " v-model="news.text"></quilleditor>
            </b-form-group>
            <div class="small text-muted text-right" v-if="news.expirationDate">Date expiration: {{ news.expirationDate }}</div>
            <div class="small text-muted text-right">Date création: {{ news.creationDate }}</div>
          </b-col>
          <b-col sm="1">
            <span class="ml-2 c-hand h3 text-primary" v-on:click="updateNews(news);"><content-save-icon/></span>
            <span class="ml-2 c-hand h3 text-danger" v-on:click="deleteNews(news.id, false);"><delete-icon/></span>
          </b-col>
        </b-row>
      </b-tab>

      <b-tab>
        <template v-slot:title>
          <plus-circle-outline-icon/> Ajout d'une actualité
        </template>

        <b-form-group class="mt-2 mb-0" label-class="font-weight-bold">
          <quilleditor ref="quilleditor" id="newsText" v-model="newNews.text"></quilleditor>
        </b-form-group>

        <b-input-group class="mt-4">
          <template #prepend>
              <b-input-group-text class="font-weight-bold">Date d'expiration</b-input-group-text>
          </template>
          <b-form-datepicker reset-button id="expiration-date" v-model="newNews.expirationDate" locale="fr"></b-form-datepicker>
        </b-input-group>

        <b-row class="text-center mt-4">
          <b-col sm="12">
            <b-button id="add-news-button" v-on:click="addNews">Ajouter l'actualité</b-button>
          </b-col>
        </b-row>

      </b-tab>
    </b-tabs>
  </b-card>
</div>
</template>

<script>
import axios from 'axios'
import quilleditor from '@/components/QuillEditor.vue'

export default {
  components: {
    quilleditor
  },
  data: function () {
    return {
      newsList: [],
      newNews: {text: ''},
    }
  },
  methods: {
    addNews: function() {
      var json = {
        expirationDate: this.newNews.expirationDate,
        text: this.newNews.text,
       };

      axios.post("/api/site-news/add-site-news/", json)
      .then(() => {
        this.getNews().then(response => {
          this.processGetNewsResponse(response);
          this.$bvModal.msgBoxOk("La actualité a été ajoutée.", { centered: true, noFade: true });
          this.newNews = {text: ''}
          this.$parent.refresh = false;
        });
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
    deleteNews: function(newsId, confirm) {
      if (!confirm) {

        this.$bvModal.msgBoxConfirm("Voulez-vous supprimer l'actualité ?" , { okVariant: 'success', okTitle: 'Oui', cancelTitle: 'Non', hideHeaderClose: true, centered: true, noFade: true }).then((value) => {
            if (value) {
              this.deleteNews(newsId, true);
            }
          });
        return;
      }
      this.$parent.refresh = true;

      var json = {
        siteNewsId: newsId,
       };

      axios.post("/api/site-news/delete-site-news/", json)
      .then(() => {
        this.getNews().then(response => {
          this.processGetNewsResponse(response);
          this.$bvModal.msgBoxOk("L'actualité a été supprimée.", { centered: true, noFade: true });
          this.$parent.refresh = false;
        });
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
    getNews: function() {
      return axios.get("/api/site-news/get-site-news/");
    },
    processGetNewsResponse: function(response) {
      this.newsList = response.data;
    },
    updateNews: function(news) {
      var json = {
        siteNewsId: news.id,
        text: news.text,
       };

      axios.post("/api/site-news/update-site-news/", json)
      .then(() => {
        this.getNews().then(response => {
          this.processGetNewsResponse(response);
          this.$parent.refresh = false;
        });
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
  },
  beforeMount() {
    this.$parent.init();
    this.$parent.inAdmin = true;

    let p1 = this.getNews();
    Promise.all([p1]).then((responses) => {
      this.processGetNewsResponse(responses[0]);
      document.title = this.$parent.websiteName + " - Gestion des actualités de MonAsso.ovh";
      this.$parent.loaded = true;
    }).catch(()=>{
      this.$router.push('/error.html');
    });
  }
}
</script>
