<template>
<div class="container-fluid flex-fill" v-if="$parent.loaded">

  <b-breadcrumb>
    <b-breadcrumb-item to="/admin/"><cog-icon/></b-breadcrumb-item>
    <b-breadcrumb-item active class="font-weight-bold">Liste des utilisateurs</b-breadcrumb-item>
  </b-breadcrumb>

  <b-pagination v-model="currentPage" :total-rows="users.length" :per-page="numberOfRowsPerPage" first-number align="center"></b-pagination>

  <b-row class="text-center bg-dark text-white font-weight-bold mt-3 mb-2 ml-0 mr-0 align-items-center">
    <b-col sm="1" class="align-center">ID</b-col>
    <b-col sm="6">Prénom Nom</b-col>
    <b-col sm="2">email</b-col>
    <b-col sm="2">Téléphone</b-col>
    <b-col sm="1">Actions</b-col>
  </b-row>

  <b-row class="mb-2 mr-0 ml-0" :class="{'even-line-background' : userIndex % 2 == 0}" align-v="center" v-for="(user, userIndex) in users.slice((currentPage - 1) * numberOfRowsPerPage, (currentPage - 1) * numberOfRowsPerPage + numberOfRowsPerPage)" :key="userIndex">
    <b-col sm="1" class="text-center"><b>{{ user.id }}</b></b-col>
    <b-col sm="6">{{ user.firstname }} {{ user.lastname }}</b-col>
    <b-col sm="2">{{ user.email }}</b-col>
    <b-col sm="2">{{ user.phoneNumber }}</b-col>
    <b-col sm="1"><router-link :to="'/admin/edit-user.html?hash=' + user.hash"><pencil-icon/></router-link></b-col>
  </b-row>

</div>
</template>

<script>
import axios from 'axios'

export default {
  data: function () {
    return {
      currentPage: 1,
      numberOfRowsPerPage: 100,
      users: [],
    }
  },
  methods: {
    getUsers: function() {
      return axios.get("/api/user/get-users/");
    },
    processgetUsersResponse: function(response) {
      if(response.status != 200) {
        return;
      }
      this.users = response.data;
    },
  },
  beforeMount() {
    this.$parent.init();
    this.$parent.inAdmin = true;
    document.title = this.$parent.websiteName + " - Administration Liste des utilisateurs";

    let p1 = this.getUsers();
    Promise.all([p1]).then((responses) => {
      this.processgetUsersResponse(responses[0]);
      this.$parent.loaded = true;
    });
  }
}
</script>
