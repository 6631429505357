<template>
<div class="container-fluid flex-fill" v-if="$parent.loaded">

  <b-breadcrumb>
    <b-breadcrumb-item to="/admin/"><cog-icon/></b-breadcrumb-item>
    <b-breadcrumb-item to="/admin/clubs.html">Clubs</b-breadcrumb-item>
    <b-breadcrumb-item active class="font-weight-bold">{{ club.name }}</b-breadcrumb-item>
  </b-breadcrumb>

  <b-input-group>
    <template #prepend>
        <b-input-group-text class="font-weight-bold">Discipline</b-input-group-text>
    </template>
    <b-form-select id="domain" v-model="club.domainId" :options="$parent.domains" value-field="id" text-field="name"></b-form-select>
  </b-input-group>

  <b-input-group class="mt-2">
    <template #prepend>
        <b-input-group-text class="font-weight-bold">Nom</b-input-group-text>
    </template>
    <b-form-input id="name" v-model="club.name" type="text"></b-form-input>
  </b-input-group>

  <b-input-group class="mt-2">
    <template #prepend>
        <b-input-group-text class="font-weight-bold">Numéro</b-input-group-text>
    </template>
    <b-form-input id="address" v-model="club.number" type="text"></b-form-input>
  </b-input-group>

  <b-input-group class="mt-2">
    <template #prepend>
        <b-input-group-text class="font-weight-bold">Adresse</b-input-group-text>
    </template>
    <b-form-input id="address" v-model="club.address" type="text"></b-form-input>
  </b-input-group>

  <b-input-group class="mt-2">
    <template #prepend>
        <b-input-group-text class="font-weight-bold">Code postal</b-input-group-text>
    </template>
    <b-form-input id="zip-code" v-model="club.zipCode" type="text"></b-form-input>
  </b-input-group>

  <b-input-group class="mt-2">
    <template #prepend>
        <b-input-group-text class="font-weight-bold">Ville</b-input-group-text>
    </template>
    <b-form-input id="coty" v-model="club.city" type="text"></b-form-input>
  </b-input-group>

  <b-input-group class="mt-2">
    <template #prepend>
        <b-input-group-text class="font-weight-bold">Téléphone</b-input-group-text>
    </template>
    <b-form-input id="coty" v-model="club.phoneNumber" type="text"></b-form-input>
  </b-input-group>

  <b-row class="text-center mt-4">
    <b-col sm="12">
      <b-button v-on:click="updateClub()" variant="success">
        <font-awesome-icon :icon="['fa', 'pen']"/> Mettre à jour</b-button>
    </b-col>
  </b-row>

</div>
</template>

<script>
import axios from 'axios'

export default {
  data: function () {
    return {
      club: undefined,
      clubId: undefined,
    }
  },
  methods: {
    getClub: function() {
      return axios.get("/api/club/get-club/" + this.clubId + "/");
    },
    processGetClubResponse: function(response) {
      if(response.status != 200) {
        return;
      }
      this.club = response.data;
    },
    updateClub: function() {
      this.$parent.refresh = true;
      var json = {
          id: this.club.id,
          name: this.club.name,
          number: this.club.number,
          phoneNumber: this.club.phoneNumber,
          address: this.club.address,
          zipCode: this.club.zipCode,
          city: this.club.city,
          domainId: this.club.domainId,
       };

      axios.post("/api/club/update-club/", json)
      .then(response => {
        if(response.status == 200) {
          this.$parent.getDomains().then(response => {
            this.$parent.processGetDomainsResponse(response);
            this.$parent.refresh = false;
          });
          return;
        }
      }).catch(error => {
        if(error.response.status == 400) {
          this.$bvModal.msgBoxOk("Une erreur est survenue. Code erreur " + error.response.data.code, { centered: true, noFade: true });
        } else {
          this.$bvModal.msgBoxOk("Une erreur est survenue. Code HTTP " + error.response.status, { centered: true, noFade: true });
        }
        this.$parent.refresh = false;
      });
    }
  },
  beforeMount() {
    this.$parent.init();
    this.$parent.inAdmin = true;

    this.clubId = this.$route.query.clubId;

    let p1 = this.getClub();
    Promise.all([p1]).then((responses) => {
      this.processGetClubResponse(responses[0]);
      document.title = this.$parent.websiteName + " - Administration Asso " + this.club.name;
      this.$parent.loaded = true;
    });
  }
}
</script>
