<template>
<div class="container-fluid flex-fill" v-if="$parent.loaded">

  <b-breadcrumb>
    <b-breadcrumb-item to="/"><b-img class="mb-1" width="32" height="32" src="/static/images/logo.png" alt="logo"></b-img></b-breadcrumb-item>
    <b-breadcrumb-item :to="'/club-dashboard.html?clubId=' + clubId" ><office-building-icon/></b-breadcrumb-item>
    <b-breadcrumb-item :to="'/club-forum-category.html?clubId=' + clubId + '&forumCategoryId=' + categoryId" ><forum-icon/></b-breadcrumb-item>
    <b-breadcrumb-item active class="font-weight-bold">{{ thread.name }}</b-breadcrumb-item>
  </b-breadcrumb>

  <b-pagination v-if="thread.numberOfMessages > numberOfRowsPerPage" v-model="currentPage" :total-rows="thread.numberOfMessages" :per-page="numberOfRowsPerPage" first-number align="center" @change="loadThreads"></b-pagination>

  <div class="message mb-4" :class="messageIndex % 2 == 0 ? 'message-even' : 'message-odd'" v-for="(message, messageIndex) in messages" :key="messageIndex">
    <div>
      <span class="font-weight-bold">{{ message.user.firstname }} {{ message.user.lastname }}</span>
      <span v-if="message.user.hash != $parent.userSession.user.hash"><router-link class="text-dark ml-2" title="message privé" :to="'/private-message.html?userHash=' + message.user.hash"><message-outline-icon/></router-link></span>
      <span v-if="club.isAdmin" class="ml-2 c-hand" v-on:click="message.edit = !message.edit" ><pencil-icon/></span>
      <span v-if="club.isAdmin && message.edit" class="ml-2 c-hand" v-on:click="updateMessage(message)" ><content-save-icon/></span>
    </div>
    <hr class="mt-1 mb-1">
    <div v-if="!message.edit" v-html="message.text" class="inner-html text-break"></div>
    <div v-if="message.edit">
      <quilleditor ref="quilleditor" id="message" v-model="message.text"></quilleditor>
    </div>
    <b-row>
      <b-col cols="6">
        <span class="text-dark " v-if="message.editByUserId != undefined"><i>-- message édité par un admin</i></span>
      </b-col>
      <b-col cols="6" class="text-right">
        <span class="small text-muted mr-2">{{ message.creationDate }}</span>
      </b-col>
    </b-row>
  </div>

  <b-pagination v-if="thread.numberOfMessages > numberOfRowsPerPage" v-model="currentPage" :total-rows="thread.numberOfMessages" :per-page="numberOfRowsPerPage" first-number align="center" @change="loadThreads"></b-pagination>

  <b-form-group class="mt-2 mb-0" label="Votre message" label-class="font-weight-bold">
    <quilleditor ref="quilleditor" id="message" v-model="newMessage.text"></quilleditor>
  </b-form-group>

  <div class="text-center mt-4">
    <b-button id="add-thread-button" v-on:click="addMessage" :variant="isFormValid() ? 'success': 'secondary'" :disabled="!isFormValid()">Ajouter le message</b-button>
  </div>

</div>
</template>

<script>
import axios from 'axios'
import quilleditor from '@/components/QuillEditor.vue'

export default {
  components: {
    quilleditor
  },
  data: function () {
    return {
      category: undefined,
      categoryId: undefined,
      club: undefined,
      clubId: undefined,
      messages: [],
      thread: undefined,
      threadId: undefined,
      currentPage: 1,
      numberOfRowsPerPage: 20,
      newMessage: {text: ''},
    }
  },
  methods: {
    addMessage: function() {
      this.$parent.refresh = true;
      var json = {
        clubId: this.clubId,
        threadId: this.threadId,
        userId: this.$parent.userSession.user.id,
        categoryId: this.categoryId,
        text: this.newMessage.text,
       };

      axios.post("/api/club-forum-message/add-club-forum-message/", json)
      .then(() => {
          this.newMessage.text = '';
          let p1 = this.getForumThread();
          let p2 = this.getForumMessages();
          Promise.all([p1, p2]).then((responses) => {
            this.processGetForumThreadResponse(responses[0]);
            this.processGetForumMessagesResponse(responses[1]);
            this.$parent.refresh = false;
          });
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },

    isFormValid: function() {
      return this.newMessage.text && this.newMessage.text.length >= 1 && this.newMessage.text.length <= 2000;
    },
    getForumCategory: function() {
      return axios.get("/api/club-forum-category/get-club-forum-category/" + this.clubId + "/" + this.categoryId + "/");
    },
    processGetForumCategoryResponse: function(response) {
      this.category = response.data;
    },
    getForumMessages: function() {
      return axios.get("/api/club-forum-message/get-club-forum-messages/" + this.clubId + "/" + this.categoryId + "/" + this.threadId + "/" + this.currentPage + "/");
    },
    processGetForumMessagesResponse: function(response) {
      this.messages = response.data;

      for (let message of this.messages) {
        this.$set(message, "edit", false);
      }
    },
    getForumThread: function() {
      return axios.get("/api/club-forum-thread/get-club-forum-thread/" + this.clubId + "/" + this.categoryId + "/" + this.threadId + "/");
    },
    processGetForumThreadResponse: function(response) {
      this.thread = response.data;
    },
    loadThreads: function(pageNum) {
      this.currentPage = pageNum;
      this.$parent.refresh = true;
      let p1 = this.getForumThread();
      let p2 = this.getForumMessages();
      Promise.all([p1, p2]).then((responses) => {
        this.processGetForumThreadResponse(responses[0]);
        this.processGetForumMessagesResponse(responses[1]);
        this.$parent.refresh = false;
      });
    },
    updateMessage: function(message) {
      this.$parent.refresh = true;
      var json = {
        clubId: this.clubId,
        categoryId: this.categoryId,
        threadId: this.threadId,
        messageId: message.id,
        text: message.text,
       };

      axios.post("/api/club-forum-message/update-club-forum-message/", json)
      .then(() => {
          this.newMessage.text = '';
          let p1 = this.getForumThread();
          let p2 = this.getForumMessages();
          Promise.all([p1, p2]).then((responses) => {
            this.processGetForumThreadResponse(responses[0]);
            this.processGetForumMessagesResponse(responses[1]);
            this.$parent.refresh = false;
          });
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
  },
  beforeMount() {
    this.$parent.init();
    this.categoryId = this.$route.query.forumCategoryId;
    this.clubId = this.$route.query.clubId;
    this.threadId = this.$route.query.threadId;
    this.club = this.$parent.myUserClubMap[this.clubId].club;

    let p1 = this.getForumCategory();
    let p2 = this.getForumThread();
    let p3 = this.getForumMessages();
    Promise.all([p1, p2, p3]).then((responses) => {
      this.processGetForumCategoryResponse(responses[0]);
      this.processGetForumThreadResponse(responses[1]);
      this.processGetForumMessagesResponse(responses[2]);
      document.title = this.$parent.websiteName + " - Forum " + this.category.name + " - " + this.thread.name;
      this.$parent.loaded = true;
    }).catch((error)=>{
      console.log(error);
      //this.$router.push('/error.html');
    });
  }
}
</script>
