<template>
<div class="d-flex align-items-center text-center flex-fill bg" v-if="$parent.loaded">
  <div class="container text-white">
    <h1 class="font-weight-bold" style="text-shadow: 0px 0px 2px #000000, 0px 0px 4px #000000;">
    Page non trouvée
    </h1>
    <h4>La page que vous souhaitez voir n'existe pas.</h4>
  </div>
</div>
</template>

<script>

export default {
  data: function () {
    return {

    }
  },
  methods: {
  },
  beforeMount() {
    this.$parent.init();

    document.title = this.$parent.websiteName + ' - Page non trouvée';

    this.$parent.loaded = true;
  }
}
</script>
