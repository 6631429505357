<template>
<div class="container-fluid flex-fill" v-if="$parent.loaded">

  <b-breadcrumb>
    <b-breadcrumb-item to="/"><b-img class="mb-1" width="32" height="32" src="/static/images/logo.png" alt="logo"></b-img></b-breadcrumb-item>
    <b-breadcrumb-item :to="'/club-dashboard.html?clubId=' + club.id"><office-building-icon/></b-breadcrumb-item>
    <b-breadcrumb-item :to="'/book.html?hash=' + book.hash"><book-icon/></b-breadcrumb-item>
    <b-breadcrumb-item active class="font-weight-bold">Utilisateurs {{ book.name }}</b-breadcrumb-item>
  </b-breadcrumb>


  <b-row class="mt-3">
    <b-col sm="2">
      <b-input-group>
        <b-form-input type="search" v-model="searchText" placeholder="Chercher des utilisateurs"></b-form-input>
        <template v-slot:append>
          <b-button type="submit" v-on:click="search()"><magnify-icon/></b-button>
        </template>
      </b-input-group>
    </b-col>
    <b-col sm="3">
      <b-pagination v-model="currentPage" :total-rows="searchedUsers.length" :per-page="numberOfRowsPerPage" first-number align="center"></b-pagination>
    </b-col>
  </b-row>

  <b-row class="text-center bg-secondary text-white font-weight-bold mt-0 mb-2 ml-0 mr-0 align-items-center">
    <b-col sm="1">ID</b-col>
    <b-col sm="2">Prénom NOM</b-col>
    <b-col sm="2">email</b-col>
    <b-col sm="2">Téléphone</b-col>
    <b-col sm="4">Droits &amp; Flags</b-col>
    <b-col sm="1"></b-col>
  </b-row>

  <b-row class="mb-2 ml-0 mr-0 text-center" :class="{'bg-light' : userIndex % 2 == 1 && user.id != $parent.switchBookUserId, 'bg-yellow-200': $parent.switchBookUserId && user.id == $parent.switchBookUserId}" align-v="center" v-for="(user, userIndex) in searchedUsers.slice((currentPage - 1) * numberOfRowsPerPage, (currentPage - 1) * numberOfRowsPerPage + numberOfRowsPerPage)" :key="userIndex">
    <b-col sm="1">#{{ user.id }}</b-col>
    <b-col sm="2">{{ user.firstname }} {{ user.lastname }}</b-col>
    <b-col sm="2">{{ user.email }}</b-col>
    <b-col sm="2">{{ user.phoneNumber }}</b-col>
    <b-col sm="4">
      <b-form-radio-group class="mr-2" v-model="user.newRight" button-variant="outline-dark" name="radio-btn-outline" buttons disabled>
        <template>
          <b-form-radio value="-1" :class="{ 'bg-dark text-white': user.newRight == -1 }"><clock-outline-icon/></b-form-radio>
          <b-form-radio value="0" :class="{ 'bg-dark text-white': user.newRight == 0 }"><eye-outline-icon/></b-form-radio>
          <b-form-radio value="1" :class="{ 'bg-dark text-white': user.newRight == 1 }"><account-outline-icon/></b-form-radio>
          <b-form-radio value="3" :class="{ 'bg-dark text-white': user.newRight == 3 }"><shield-star-outline-icon/></b-form-radio>
          <b-form-radio value="4" :class="{ 'bg-dark text-white': user.newRight == 4 }"><shield-account-outline-icon/></b-form-radio>
        </template>
      </b-form-radio-group>

      <b-form-checkbox :button-variant="user.isManager ? 'dark': 'outline-dark'" button disabled><bookmark-outline-icon/></b-form-checkbox>
    </b-col>
    <b-col lg="1">
      <span class="c-hand h3" v-on:click="$parent.switchUser(user.id, user.firstname + ' ' + user.lastname)"><account-switch-icon/></span>
    </b-col>
  </b-row>

</div>
</template>

<script>
import axios from 'axios'

export default {
  data: function () {
    return {
      book: undefined,
      bookHash: undefined,
      club: undefined,
      clubId: undefined,
      currentPage: 1,
      numberOfRowsPerPage: 50,
      searchText: '',
      users: [],
      userRightsMap: new Map(),
    }
  },
  computed: {
    searchedUsers: function () {

      let searchTextLC = this.searchText.toLowerCase();
      var searchUsers = this.users.filter(row => {
        let filterOnName = (this.searchText != undefined && this.searchText.length >= 2);

        let filterOnNameIsOK = true;

        if (filterOnName) {
          filterOnNameIsOK = (row.lastname.toLowerCase().includes(searchTextLC) || row.firstname.toLowerCase().includes(searchTextLC));
        }

        return filterOnNameIsOK;

      });
      return searchUsers;
    },
  },
  methods: {
    getBook: function() {
      return axios.get("/api/book/get-book/" + this.bookHash + "/");
    },
    processGetBookResponse: function(response) {
      this.book = response.data;
    },
    getUsers: function() {
      return axios.get("/api/book/get-users/" + this.bookHash + "/");
    },
    processGetUsersResponse: function(response) {
      let rawUsers = response.data;
      this.users = [];
      this.userRightsMap = new Map();
      for (let rawUserKey in rawUsers) {
        let rawUser = rawUsers[rawUserKey];
        let isManager = false;

        if (this.userFlagsMap[rawUser.user.id] != undefined) {
          for (let flagKey in this.userFlagsMap[rawUser.user.id]) {
            let flag = this.userFlagsMap[rawUser.user.id][flagKey];
            if (flag.id == 1) {
              isManager = true;
            }
          }
        }

        this.users.push({id: rawUser.user.id, firstname: rawUser.user.firstname, lastname: rawUser.user.lastname, phoneNumber: rawUser.user.phoneNumber, email: rawUser.user.email, right: rawUser.right, newRight: rawUser.right, isManager: isManager })
        this.userRightsMap[rawUser.user.id] = {right: rawUser.right, newRight: rawUser.right};
      }
    },
    getUserFlags: function() {
      return axios.get("/api/book-user-flag/get-all-flags-by-book/" + this.bookHash + "/");
    },
    processGetUserFlagsResponse: function(response) {
      this.userFlagsMap = response.data;
    },
  },
  beforeMount() {
    this.$parent.init();
    this.bookHash = this.$route.query.hash;
    this.clubId = this.$route.query.clubId;

    let p1 = this.getBook();
    let p2 = this.getUserFlags();
    let p3 = this.getUsers();
    Promise.all([p1, p2, p3]).then((responses) => {
      this.processGetBookResponse(responses[0]);
      this.processGetUserFlagsResponse(responses[1]);
      this.processGetUsersResponse(responses[2]);

      this.club = this.$parent.myUserClubMap[this.book.clubId].club;
      document.title = this.$parent.websiteName + " - Liste utilisateurs du registre " + this.book.name;
      this.$parent.loaded = true;
    }).catch(()=>{
      this.$router.push('/error.html');
    });
  }
}
</script>
