<template>
<div class="container-fluid flex-fill" v-if="$parent.loaded">

  <b-breadcrumb>
    <b-breadcrumb-item to="/"><b-img class="mb-1" width="32" height="32" src="/static/images/logo.png" alt="logo"></b-img></b-breadcrumb-item>
    <b-breadcrumb-item :to="'/club-dashboard.html?clubId=' + clubId" ><office-building-icon/></b-breadcrumb-item>
    <b-breadcrumb-item active class="font-weight-bold"><package-variant-closed-icon/> Les matériels</b-breadcrumb-item>
  </b-breadcrumb>

  <b-card no-body>
    <b-tabs card>
      <b-tab>
        <template v-slot:title>
          <package-variant-closed-icon/> Les matériels
        </template>

        <div class="alert alert-secondary mb-0 text-center h5" role="alert" v-if="equipments.length == 0">
          Aucun matériel trouvé.
        </div>

        <div v-if="equipments.length > 0">
          <router-link :to="'club-admin-equipment-print.html?clubId=' + clubId" target="_blank">Liste imprimable avec code barre</router-link>
          <b-pagination v-model="currentPage" :total-rows="equipments.length" :per-page="numberOfRowsPerPage" first-number align="center"></b-pagination>
        </div>

        <b-row align-v="center" :class="{'even-line-background' : equipmentIndex % 2 == 1}" class="p-2" v-for="(equipment, equipmentIndex) in equipments.slice((currentPage - 1) * numberOfRowsPerPage, (currentPage - 1) * numberOfRowsPerPage + numberOfRowsPerPage)" :key="equipmentIndex">
          <b-col cols="10" class="p-2">
            <span class="font-weight-bold">{{ equipment.name }}</span>
            <span class="small ml-2">{{ equipment.serialNumber }}</span>
          </b-col>
          <b-col cols="2" class="text-right">
            <span class="ml-2 h3"><router-link :to="'club-admin-edit-equipment.html?equipmentId=' + equipment.id"><pencil-icon/></router-link></span>
            <span class="ml-2 c-hand h3 text-danger" v-on:click="deleteEquipment(equipment.id, false);"><delete-icon/></span>
          </b-col>
        </b-row>
      </b-tab>

      <b-tab>
        <template v-slot:title>
          <plus-circle-outline-icon/> Ajout d'un matériel
        </template>

        <div class="alert alert-secondary" role="alert">
          Un équipment est un objet que vous souhaitez rendre disponible à vos adhérents. Il est identifié par son nom. Les autres paramètres sont optionnels. Lorsque le matériel sera créé un code barre sera automatiquement généré.
        </div>

        <b-input-group class="mt-2">
          <template #prepend>
              <b-input-group-text class="font-weight-bold">Nom</b-input-group-text>
          </template>
          <b-form-input id="new-equipment-name" v-model="newEquipment.name" type="text"></b-form-input>
        </b-input-group>
        <b-form-text>Le nom du matériel doit être suffisamment explicite pour le différencer d'un autre.</b-form-text>

        <b-form-group label="Description" class="mt-2 mb-0">
          <quilleditor ref="quilleditor" id="new-equipment-description" v-model="newEquipment.description"></quilleditor>
        </b-form-group>

        <b-input-group class="mt-2">
          <template #prepend>
              <b-input-group-text>Code EAN</b-input-group-text>
          </template>
          <b-form-input id="new-equipment-code1" v-model="newEquipment.code1" type="text" :state="isCode1Valid()"></b-form-input>
        </b-input-group>
        <b-form-text>Le code EAN sont utilisés par les fabricants européens pour identifier leur produit. C'est le code barre qu'on retrouve derrière les produits achetés en supermarchés. Un code EAN est composé de 13 chiffres.</b-form-text>

        <b-input-group class="mt-2">
          <template #prepend>
              <b-input-group-text>Code 128 ASCII</b-input-group-text>
          </template>
          <b-form-input id="new-equipment-code2" v-model="newEquipment.code2" type="text" :state="isCode2Valid()"></b-form-input>
        </b-input-group>
        <b-form-text>Le code 128 ASCII permet de générer un code barre contenant jusqu'à 128 caractères.</b-form-text>

        <b-input-group class="mt-2">
          <template #prepend>
              <b-input-group-text>Numéro de série</b-input-group-text>
          </template>
          <b-form-input id="new-equipment-serial-number" v-model="newEquipment.serialNumber" type="text"></b-form-input>
        </b-input-group>
        <b-form-text>Le numéro de série permet de différencier 2 matériels identiques.</b-form-text>

        <b-row class="text-center mt-4">
          <b-col sm="12">
            <b-button id="add-equipment-button" v-on:click="addEquipment" :variant="isFormValid() ? 'success': 'secondary'"  :disabled="!isFormValid()">Ajouter le matériel</b-button>
          </b-col>
        </b-row>

      </b-tab>
    </b-tabs>
  </b-card>
</div>
</template>

<script>
import axios from 'axios'
import quilleditor from '@/components/QuillEditor.vue'

export default {
  components: {
    quilleditor
  },
  data: function () {
    return {
      equipments: [],
      club: undefined,
      clubId: undefined,
      newEquipment: {name: '', description: '', code1: '', code2: '', serialNumber: '' },
      numberOfRowsPerPage: 10,
      currentPage: 1,
    }
  },
  methods: {
    addEquipment: function() {
      this.$parent.refresh = true;
      var json = {
        clubId: this.club.id,
        code1: this.newEquipment.code1,
        code2: this.newEquipment.code2,
        description: this.newEquipment.description,
        name: this.newEquipment.name,
        serialNumber: this.newEquipment.serialNumber,
       };

      if (json.description != undefined && json.description.length == 0) {
        json.description = undefined;
      }

      if (json.code1 != undefined && json.code1.length == 0) {
        json.code1 = undefined;
      }

      if (json.code2 != undefined && json.code2.length == 0) {
        json.code2 = undefined;
      }

      if (json.serialNumber != undefined && json.serialNumber.length == 0) {
        json.serialNumber = undefined;
      }

      axios.post("/api/equipment/add-equipment/", json)
      .then(() => {
        this.getClubEquipments().then(response => {
          this.processGetEquipmentResponse(response);
          this.$bvModal.msgBoxOk("Le matériel a été ajoutée.", { centered: true, noFade: true });
          this.newEquipment = {name: '', description: '', code1: '', code2: '', serialNumber: '' };
          this.$parent.refresh = false;
        });
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
    deleteEquipment: function(equipmentId, confirm) {
      if (!confirm) {

        this.$bvModal.msgBoxConfirm('Voulez-vous supprimer le matériel ?' , { okVariant: 'success', okTitle: 'Oui', cancelTitle: 'Non', hideHeaderClose: true, centered: true, noFade: true }).then((value) => {
            if (value) {
              this.deleteEquipment(equipmentId, true);
            }
          });
        return;
      }
      this.$parent.refresh = true;

      var json = {
        equipmentId: equipmentId,
       };

      axios.post("/api/equipment/delete-equipment/", json)
      .then(() => {
        this.getClubEquipments().then(response => {
          this.processGetEquipmentResponse(response);
          this.$bvModal.msgBoxOk("Le matériel a été supprimé.", { centered: true, noFade: true });
          this.$parent.refresh = false;
        });
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
    isCode1Valid: function() {
      if (this.newEquipment.code1.length == 0) return true;
      return this.newEquipment.code1.length == 13 && !isNaN(this.newEquipment.code1);
    },
    isCode2Valid: function() {
      if (this.newEquipment.code2.length == 0) return true;
      return this.newEquipment.code2.length <= 128;
    },
    isFormValid: function() {
      return this.isCode1Valid() && this.isCode2Valid() && this.isNameValid();
    },
    isNameValid: function() {
      if (this.newEquipment.name.length == 0) return null;
      return this.newEquipment.name.length > 0 && this.newEquipment.name.length <= 100;
    },
    getClubEquipments: function() {
      return axios.get("/api/equipment/get-club-equipments/" + this.clubId + "/");
    },
    processGetEquipmentResponse: function(response) {
      this.equipments = response.data;

      for (let equipmentKey in this.equipments) {
        let equipment = this.equipments[equipmentKey];
        equipment.barcode = this.$parent.createEanBarCode(equipment.id);
      }
    },
  },
  beforeMount() {
    this.$parent.init();
    this.clubId = this.$route.query.clubId;
    this.club = this.$parent.myUserClubMap[this.clubId].club;

    let p1 = this.getClubEquipments();
    Promise.all([p1]).then((responses) => {
      this.processGetEquipmentResponse(responses[0]);
      document.title = this.$parent.websiteName + " - Gestion des matériels de l'asso " + this.club.name;
      this.$parent.loaded = true;
    }).catch((error)=>{
      console.log(error);
      //this.$router.push('/error.html');
    });
  }
}
</script>
