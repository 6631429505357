<template>
<div class="container-fluid flex-fill" v-if="$parent.loaded">

  <b-breadcrumb>
    <b-breadcrumb-item to="/admin/"><cog-icon/></b-breadcrumb-item>
    <b-breadcrumb-item to="/admin/users.html">Liste des utilisateurs</b-breadcrumb-item>
    <b-breadcrumb-item active class="font-weight-bold">Utilisateur {{ this.user.firstname }} {{ this.user.lastname }}</b-breadcrumb-item>
  </b-breadcrumb>

  <b-input-group>
    <template #prepend>
        <b-input-group-text class="font-weight-bold">Email</b-input-group-text>
    </template>
    <b-form-input id="email" v-model="user.email" type="email"></b-form-input>
  </b-input-group>

  <b-input-group class="mt-2">
    <template #prepend>
        <b-input-group-text class="font-weight-bold">Mot de passe</b-input-group-text>
    </template>
    <b-form-input id="password" v-model="user.password" type="password"></b-form-input>
  </b-input-group>

  <b-input-group class="mt-2">
    <template #prepend>
        <b-input-group-text class="font-weight-bold">Prénom</b-input-group-text>
    </template>
    <b-form-input id="firstname" v-model="user.firstname" type="text"></b-form-input>
  </b-input-group>

  <b-input-group class="mt-2">
    <template #prepend>
        <b-input-group-text class="font-weight-bold">Nom</b-input-group-text>
    </template>
    <b-form-input id="lastname" v-model="user.lastname" type="text"></b-form-input>
  </b-input-group>

  <b-form inline class="mt-2">
    <b-form-group label="Sexe" label-class="font-weight-bold">
      <b-form-radio-group id="sex" v-model="user.sex" :options="sexOptions"></b-form-radio-group>
    </b-form-group>
  </b-form>

  <b-input-group class="mt-2">
    <template #prepend>
        <b-input-group-text class="font-weight-bold">Date de naissance</b-input-group-text>
    </template>
    <b-form-input id="birthdate" v-model="user.birthdate" type="text"></b-form-input>
  </b-input-group>

  <b-input-group class="mt-2">
    <template #prepend>
      <b-input-group-text class="font-weight-bold">Téléphone</b-input-group-text>
    </template>
    <b-form-input id="phone-number" v-model="user.phoneNumber" type="text"></b-form-input>
  </b-input-group>

    <b-row class="text-center mt-4">
      <b-col sm="12">
        <b-button id="update-button" v-on:click="updateUser" variant="success"><pencil-icon/>Mettre à jour</b-button>
      </b-col>
    </b-row>

</div>
</template>

<script>
import axios from 'axios'

export default {
  data: function () {
    return {
      user: undefined,
      userHash: undefined,
      sexOptions: [
          { text: 'Masculin', value: 'male' },
          { text: 'Féminin', value: 'female' },
      ],
    }
  },
  methods: {
    getUser: function() {
      return axios.get("/api/user/get-user/" + this.userHash + "/");
    },
    processGetUserResponse: function(response) {
      if(response.status != 200) {
        return;
      }
      this.user = response.data;
    },
    updateUser: function() {
      this.$parent.refresh = true;

      var json = {
        birthdate: this.user.birthdate,
        email: this.user.email,
        firstname: this.user.firstname,
        lastname: this.user.lastname,
        password: this.user.password,
        phoneNumber: this.user.phoneNumber,
        sex: this.user.sex,
        userId: this.user.id,
       };

      axios.post("/api/user/update-user/", json)
      .then(response => {
        if(response.status == 200) {
          this.getUser().then(response=>{
            this.processGetUserResponse(response);
            this.$bvModal.msgBoxOk("L'utilisateur a été mis à jour avec succès.", { centered: true, noFade: true });
            this.$parent.refresh = false;
          });
        }
      }).catch(error => {
        if(error.response.status == 400) {
          var errorData = error.response.data;
          this.$bvModal.msgBoxOk("Une erreur est survenue. Code erreur " + errorData.code, { centered: true, noFade: true });
        } else {
          this.$bvModal.msgBoxOk("Une erreur est survenue. Code HTTP " + error.response.status, { centered: true, noFade: true });
        }

        this.$parent.refresh = false;
      });
    },
  },
  beforeMount() {
    this.$parent.init();
    this.$parent.inAdmin = true;

    this.userHash = this.$route.query.hash;
    let p1 = this.getUser();
    Promise.all([p1]).then((responses) => {
      this.processGetUserResponse(responses[0]);
      document.title = this.$parent.websiteName + " - Administration Utilisateur " + this.user.firstname + " " + this.user.lastname;
      this.$parent.loaded = true;
    });
  }
}
</script>
