<template>
<div class="container-fluid flex-fill" v-if="$parent.loaded">

  <b-breadcrumb>
    <b-breadcrumb-item to="/"><b-img class="mb-1" width="32" height="32" src="/static/images/logo.png" alt="logo"></b-img></b-breadcrumb-item>
    <b-breadcrumb-item :to="'/club-dashboard.html?clubId=' + clubId"><office-building-icon/></b-breadcrumb-item>
    <b-breadcrumb-item active class="font-weight-bold">Ajouter un registre</b-breadcrumb-item>
  </b-breadcrumb>

  <b-input-group class="mt-2">
    <template #prepend>
        <b-input-group-text class="font-weight-bold">Nom</b-input-group-text>
    </template>
    <b-form-input id="name" v-model="name" type="text" :state="isNameValid()"></b-form-input>
  </b-input-group>
  <b-form-text>Le nom du registre doit être le plus explicite possible. Il est intéressant d'ajouter le nom du lieu, surtout si vous avez plusieurs lieux pour vos activités, par exemple: <i>Cours de tennis Rue rousseau</i>.</b-form-text>

  <b-form-group label="Description" class="mt-2 mb-0" label-class="font-weight-bold">
    <quilleditor ref="quilleditor" id="description" v-model="description"></quilleditor>
  </b-form-group>
  <b-form-text>La description du registre est <b>obligatoire</b> et doit être la plus complète possible. Si vous avez des remarques ou des règles de réservation à faire part à vos adhérents c'est ici qu'il faut mettre cela.</b-form-text>

  <b-input-group class="mt-2">
    <template #prepend>
        <b-input-group-text class="font-weight-bold">Délai</b-input-group-text>
    </template>
        <div><b-form-spinbutton id="booking-closed-before-days" v-model="bookingClosedBeforeDays" min="0" max="10" inline></b-form-spinbutton></div>
  </b-input-group>
  <b-form-text>Définissez le délai pour que les personnes puissent s'inscrire à un créneau de votre registre. Un délai de 2 jours, permet à vos adhérents de s'inscrire ou d'annuler 48h avant le debut du créneau. En mettant 0, ils pourront s'inscrire ou annuler juste avant que le créneau commence.</b-form-text>

  <b-input-group class="mt-4">
    <template #prepend>
        <b-input-group-text class="font-weight-bold">Méthode de validation</b-input-group-text>
    </template>
    <b-form-select id="booking-validation-method" v-model="bookingValidationMethod" :options="bookingValidationMethodOptions"></b-form-select>
  </b-input-group>
  <b-form-text></b-form-text>

  <h2 class="mt-4">Les créneaux périodiques</h2>
  <b-form inline class="mt-2" v-for="(periodicSlot, periodicSlotIndex) in periodicSlots" :key="periodicSlotIndex">

    <b-input-group class="mr-2">
      <b-form-select :id="'periodic-slot-n-day-of-week-of-month-' + periodicSlotIndex" v-model="periodicSlot.nDayOfWeekOfMonth" :options="nDayOfWeekOfMonthOptions"></b-form-select>
    </b-input-group>

    <b-input-group class="mr-2">
      <b-form-select :id="'periodic-slot-day-' + periodicSlotIndex" v-model="periodicSlot.day" :options="dayOptions"></b-form-select>
    </b-input-group>

    <b-input-group class="mr-2">
      <template #prepend>
          <b-input-group-text class="font-weight-bold">Heure de début</b-input-group-text>
      </template>
      <b-form-timepicker :id="'periodic-slot-start-time-' + periodicSlotIndex" v-model="periodicSlot.startTime" locale="fr"></b-form-timepicker>
    </b-input-group>

    <b-input-group class="mr-2">
      <template #prepend>
          <b-input-group-text class="font-weight-bold">Heure de fin</b-input-group-text>
      </template>
      <b-form-timepicker :id="'periodic-slot-end-time-' + periodicSlotIndex" v-model="periodicSlot.endTime" locale="fr"></b-form-timepicker>
    </b-input-group>

    <b-input-group class="mr-2">
      <template #prepend>
          <b-input-group-text class="font-weight-bold">Durée</b-input-group-text>
      </template>
      <b-form-input :id="'periodic-slot-duration-' + periodicSlotIndex" v-model="periodicSlot.duration" type="number"></b-form-input>
      <template #append>
          <b-input-group-text>minutes</b-input-group-text>
      </template>
    </b-input-group>

    <b-input-group class="mr-2">
      <template #prepend>
          <b-input-group-text class="font-weight-bold">Nombre de place</b-input-group-text>
      </template>
      <b-form-input :id="'periodic-slot-number-of-places-' + periodicSlotIndex" v-model="periodicSlot.numberOfPlaces" name="numberOfPlaces" type="number"></b-form-input>
    </b-input-group>

    <b-button variant="secondary" v-on:click="deletePeriodicSlot(periodicSlots, periodicSlotIndex);" class="mr-2" v-if="periodicSlots.length > 1"><delete-icon/></b-button>
    <b-button id="add-periodic-slot-button" variant="secondary" v-on:click="addPeriodicSlot();" class="mr-2" v-if="periodicSlotIndex >= periodicSlots.length - 1"><plus-circle-outline-icon/></b-button>
  </b-form>

  <b-row class="text-center mt-4">
    <b-col sm="12">
      <b-button id="add-book-button" v-on:click="addBook" :variant="isFormValid() ? 'success': 'secondary'" size="lg" :disabled="!isFormValid()">Créer le registre</b-button>
    </b-col>
  </b-row>

</div>
</template>

<script>
import axios from 'axios'
import quilleditor from '@/components/QuillEditor.vue'

export default {
  components: {
    quilleditor
  },
  data: function () {
    return {
      bookingClosedBeforeDays: 1,
      bookingValidationMethod: 1,
      bookingValidationMethodOptions: [
          { text: 'Les réservations sont validées par défaut', value: 1 },
          { text: 'Chaque réservation doit être validées manuellement', value: 2 },
          { text: 'Les réservations sont validées automatiquement si il y a un responsable d\'inscrit sur le créneau', value: 3 },
      ],
      clubId: undefined,
      dayOptions: [
        { value: '1', text: 'Lundi' },
        { value: '2', text: 'Mardi' },
        { value: '3', text: 'Mercredi' },
        { value: '4', text: 'Jeudi' },
        { value: '5', text: 'Vendredi' },
        { value: '6', text: 'Samedi' },
        { value: '7', text: 'Dimanche' },
      ],
      description: undefined,
      name: undefined,
      nDayOfWeekOfMonthOptions: [
        { value: '0', text: 'Tous les' },
        { value: '1', text: 'Seulement le 1er' },
        { value: '2', text: 'Seulement le 2ème' },
        { value: '3', text: 'Seulement le 3ème' },
        { value: '4', text: 'Seulement le 4ème' },
        { value: '5', text: 'Seulement le 5ème' },
      ],
      periodicSlots: [{day: 1, duration: 60, startTime: '12:00:00', endTime: '13:00:00', nDayOfWeekOfMonth: 0, numberOfPlaces: 10 }],
    }
  },
  methods: {
    addBook: function() {
      this.$parent.refresh = true;

      var json = {
        bookingCancellationDays: this.bookingClosedBeforeDays,
        bookingValidationMethod: this.bookingValidationMethod,
        clubId: this.clubId,
        description: this.description,
        name: this.name,
        periodicSlots: this.periodicSlots
       };

      axios.post("/api/book/add-book/", json)
      .then(response => {
        if(response.status == 200) {
          let book = response.data;
          this.$parent.refresh = false;
          this.$router.push("/book.html?hash=" + book.hash);
        }
      }).catch(error => {
          if(error.response.status == 400) {
            var errorData = error.response.data;
            this.$bvModal.msgBoxOk("Une erreur est survenue. Code erreur " + errorData.code, { centered: true, noFade: true });
          } else {
            this.$bvModal.msgBoxOk("Une erreur est survenue. Code HTTP " + error.response.status, { centered: true, noFade: true });
          }
          this.$parent.refresh = false;
      });
    },
    addPeriodicSlot: function() {
      this.periodicSlots.push({day: 1, duration: 60, startTime: '12:00:00', endTime: '13:00:00', nDayOfWeekOfMonth: 0, numberOfPlaces: 10 });
    },
    deletePeriodicSlot: function(periodicSlot, periodicSlotIndex) {
      this.$bvModal.msgBoxConfirm('Voulez-vous supprimer le créneau périodique n° ' + (periodicSlotIndex + 1) + ' ?', { okVariant: 'success', okTitle: 'Oui', cancelTitle: 'Non', hideHeaderClose: true, centered: true, noFade: true }).then((value) => {
          if (value) {
            this.periodicSlots.splice(periodicSlotIndex, 1);
          }
        });
    },
    isDescriptionValid: function() {
      if (this.description == undefined) return null;
      return this.description.length >= this.$parent.constants.formInputs['bookDescription'].minSize && this.description.length <= this.$parent.constants.formInputs['bookDescription'].maxSize;
    },
    isFormValid: function() {
      return this.isNameValid() &&
             this.isDescriptionValid();
    },
    isNameValid: function() {
      if (this.name == undefined) return null;
      return this.name.length >= this.$parent.constants.formInputs['bookName'].minSize && this.name.length <= this.$parent.constants.formInputs['bookName'].maxSize;
    },
  },
  beforeMount() {
    this.$parent.init();
    document.title = this.$parent.websiteName + " - Ajouter un registre";

    this.clubId = this.$route.query.clubid;
    this.$parent.loaded = true;
  }
}
</script>
