<template>
<div class="container-fluid flex-fill" v-if="$parent.loaded">

  <b-breadcrumb>
    <b-breadcrumb-item to="/"><b-img class="mb-1" width="24" height="24" src="/static/images/logo.png" alt="logo"></b-img></b-breadcrumb-item>
    <b-breadcrumb-item active class="font-weight-bold"><message-outline-icon/> Commentaires Actualité Site</b-breadcrumb-item>
  </b-breadcrumb>

  <div class="alert-secondary message mb-4">
    <div v-html="siteNews.text" class="inner-html text-break"></div>
    <div class="small text-muted text-right mr-2">{{ siteNews.creationDate }}</div>
  </div>

  <div class="alert alert-secondary mb-0 text-center h5 mb-4" role="alert" v-if="comments.length == 0">
    Pas de commentaires. Soyez le premier à commenter.
  </div>

  <b-pagination v-if="siteNews.numberOfComments > numberOfRowsPerPage" v-model="currentPage" :total-rows="siteNews.numberOfComments" :per-page="numberOfRowsPerPage" first-number align="center" @change="loadComments"></b-pagination>

  <div class="message mb-4" :class="commentIndex % 2 == 0 ? 'message-even' : 'message-odd'" v-for="(comment,commentIndex) in comments" :key="commentIndex">
    <div class="font-weight-bold">{{ comment.user.firstname }} {{ comment.user.lastname }}</div>
    <div v-html="comment.text" class="inner-html text-break"></div>
    <div class="small text-muted text-right mr-2">{{ comment.creationDate }}</div>
  </div>

  <b-pagination v-if="siteNews.numberOfComments > numberOfRowsPerPage" v-model="currentPage" :total-rows="siteNews.numberOfComments" :per-page="numberOfRowsPerPage" first-number align="center" @change="loadComments"></b-pagination>

  <div>
    <div style="display: flex" class="mt-0">
      <div style="display: flex; width: 100%">
        <b-form-textarea style="background-color: #DDDDDD;" id="new-message" v-model="newComment" rows="3" max-rows="3"></b-form-textarea>
      </div>
      <div style="display: flex" class="ml-2">
        <b-button variant="success" size="lg" v-on:click="addComment()" :disabled="newComment.length == 0"><send-icon/></b-button>
      </div>
    </div>
  </div>

</div>
</template>

<script>
import axios from 'axios'

export default {
  data: function () {
    return {
      siteNews: undefined,
      siteNewsId: undefined,
      comments: [],
      newComment: '',
      currentPage: 1,
      numberOfRowsPerPage: 20,
      lastCommentId: 0,
    }
  },
  methods: {
    addComment: function() {
      if (this.newComment.length == 0) {
        return;
      }
      this.$parent.refresh = true;
      let newCommentHtml = '<p>' + this.newComment.replaceAll('\n', '</p><p>') + '</p>';

      var json = {
        siteNewsId: this.siteNewsId,
        text: newCommentHtml,
        userId: this.$parent.userSession.user.id,
       };

      axios.post("/api/site-news-comment/add-site-news-comment/", json)
      .then(() => {
        let p1 = this.getSiteNews();
        let p2 = this.getSiteNewsComments();
        Promise.all([p1, p2]).then((responses) => {
          this.processGetSiteNewsResponse(responses[0]);
          this.processGetSiteNewsCommentsResponse(responses[1]);
          this.newComment = '';
          this.$parent.refresh = false;
        });
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
    loadComments: function(pageNum) {
      this.$parent.refresh = true;
      this.lastCommentId = (pageNum - 1) * this.numberOfRowsPerPage;
      this.getSiteNewsComments().then(response => {
        this.processGetSiteNewsCommentsResponse(response);
        this.$parent.refresh = false;
      });
    },
    getSiteNews: function() {
      return axios.get("/api/site-news/get-single-site-news/" + this.siteNewsId + "/");
    },
    processGetSiteNewsResponse: function(response) {
      this.siteNews = response.data;
    },
    getSiteNewsComments: function() {
      return axios.get("/api/site-news-comment/get-site-news-comments/" + this.siteNewsId + "/" + this.lastCommentId + "/");
    },
    processGetSiteNewsCommentsResponse: function(response) {
      this.comments = response.data;
    },
  },
  beforeMount() {
    this.$parent.init();
    this.siteNewsId = this.$route.query.siteNewsId;

    let p1 = this.getSiteNews();
    let p2 = this.getSiteNewsComments();
    Promise.all([p1, p2]).then((responses) => {
      this.processGetSiteNewsResponse(responses[0]);
      this.processGetSiteNewsCommentsResponse(responses[1]);
      document.title = this.$parent.websiteName + " - Commentaires Actualité " + this.siteNewsId;
      this.$parent.loaded = true;
    }).catch((error)=>{
      console.log(error);
      // this.$router.push('/error.html');
    });

  }
}
</script>
