<template>
<div class="container-fluid flex-fill" v-if="$parent.loaded">

  <b-breadcrumb>
    <b-breadcrumb-item to="/"><b-img class="mb-1" width="32" height="32" src="/static/images/logo.png" alt="logo"></b-img></b-breadcrumb-item>
    <b-breadcrumb-item active class="font-weight-bold">Messages Privés</b-breadcrumb-item>
  </b-breadcrumb>

  <div class="alert alert-secondary mb-4" role="alert" v-if="talks.length == 0">
    Vous n'avez pas de messages privés.
  </div>

  <b-card nobody v-if="talks.length > 0">
    <b-row align-v="center" :class="{'even-line-background' : talkIndex % 2 == 1}" class="p-2" v-for="(talk, talkIndex) in filterTalks" :key="talkIndex">
      <b-col cols="12" class="p-2">
        <div class="text-break" v-if="$parent.userSession.user.id != talk.toUser.id">
          <router-link :to="'/private-message.html?userHash=' + talk.toUser.hash"><span class="text-break font-weight-bold text-uppercase">{{ talk.toUser.firstname }} {{ talk.toUser.lastname }}</span></router-link>

          <span class="text-warning ml-2" v-if="(talk.lastMessageId && $parent.lastUserTalkMessageIdMap.get(''+talk.toUser.id) && talk.lastMessageId > $parent.lastUserTalkMessageIdMap.get(''+talk.toUser.id)) || (talk.lastMessageId && !$parent.lastUserTalkMessageIdMap.get(''+talk.toUser.id))"><circle-icon/></span>
        </div>
        <div class="text-break" v-if="$parent.userSession.user.id != talk.fromUser.id">
          <router-link :to="'/private-message.html?userHash=' + talk.fromUser.hash"><span class="text-break font-weight-bold text-uppercase">{{ talk.fromUser.firstname }} {{ talk.fromUser.lastname }}</span></router-link>
          <span class="text-warning ml-2" v-if="(talk.lastMessageId && $parent.lastUserTalkMessageIdMap.get(''+talk.fromUser.id) && talk.lastMessageId > $parent.lastUserTalkMessageIdMap.get(''+talk.fromUser.id)) || (talk.lastMessageId && !$parent.lastUserTalkMessageIdMap.get(''+talk.fromUser.id))"><circle-icon/></span>
        </div>

      </b-col>
    </b-row>
  </b-card>
</div>
</template>

<script>
import axios from 'axios'

export default {
  data: function () {
    return {
      talks: undefined,
    }
  },
  computed: {
    filterTalks: function () {
      return this.talks.filter(talk => {
        if (talk.toUser.id == talk.fromUser.id) {
          return false;
        }
        return true;
      });
    },
  },
  methods: {
    getUserTalks: function() {
      return axios.get("/api/user-talk/get-user-talks/" + this.$parent.userSession.user.id + "/");
    },
    processGetUserTalksResponse: function(response) {
      this.talks = response.data;
    },
  },
  beforeMount() {
    this.$parent.init();
    document.title = this.$parent.websiteName + " - Message privés";

    let p1 = this.getUserTalks();
    let p2 = this.$parent.getUserSettings(this.$parent.userSession.user.hash);
    Promise.all([p1, p2]).then((responses) => {
      this.processGetUserTalksResponse(responses[0]);
      this.$parent.processGetUserSettingsResponse(responses[1]);

      let unreadUserTalk = false;
      for (let talk of this.talks) {
        let toUserId = talk.toUserId;
        if (toUserId == this.$parent.userSession.user.id) {
          toUserId = talk.fromUserId;
        }

        if ((talk.lastMessageId && this.$parent.lastUserTalkMessageIdMap.get(''+ toUserId) && talk.lastMessageId > this.$parent.lastUserTalkMessageIdMap.get('' + toUserId)) || (talk.lastMessageId && !this.$parent.lastUserTalkMessageIdMap.get(''+ toUserId))) {
          unreadUserTalk = true;
          break;
        }
      }

      if (!unreadUserTalk && this.$parent.userSettings['unread-private-message'] != "0") {
        var json = {
          name: "unread-private-message",
          value: "0",
         };
        axios.post("/api/user-setting/update-user-setting/", json).then(()=> {
          this.$parent.getUserSettings(this.$parent.userSession.user.hash).then(response => {
            this.$parent.processGetUserSettingsResponse(response);
            this.$parent.refresh = false;
          });
        });
      }

      this.$parent.loaded = true;
    }).catch((error)=>{
      console.log(error);
      //this.$router.push('/error.html');
    });
  }
}
</script>
