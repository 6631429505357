<template>
  <footer class="bg-dark text-center p-1" style="color:#cdcdcd">
          <span>© {{ $parent.websiteName }} {{ new Date().getFullYear() }}</span>
          <span> • </span>
          <span><router-link to="/changelog.html" style="color: rgb(205, 205, 205)"><span class="d-none d-md-inline">version </span>{{ $parent.appVersion }}</router-link></span>
          <span> • </span>
          <span><router-link style="color:#cdcdcd" to="/legal.html">mentions légales</router-link></span>
  </footer>
</template>

<script>
export default {
}
</script>

<style>
</style>
