<template>
<div class="container-fluid flex-fill" v-if="$parent.loaded">

  <b-breadcrumb>
    <b-breadcrumb-item to="/"><b-img class="mb-1" width="32" height="32" src="/static/images/logo.png" alt="logo"></b-img></b-breadcrumb-item>
    <b-breadcrumb-item :to="'/club-dashboard.html?clubId=' + clubId" ><office-building-icon/></b-breadcrumb-item>
    <b-breadcrumb-item active class="font-weight-bold"><forum-icon/> {{ category.name }}</b-breadcrumb-item>
  </b-breadcrumb>

  <b-card class="p-0">
    <div class="alert alert-secondary mb-0 text-center h5" role="alert" v-if="threads.length == 0">
      Aucun sujet. Soyez le premier à en créer.
    </div>

    <b-pagination v-if="category.numberOfThreads > numberOfRowsPerPage" v-model="currentPage" :total-rows="category.numberOfThreads" :per-page="numberOfRowsPerPage" first-number align="center" @change="loadThreads"></b-pagination>

    <b-row align-v="center" :class="{'even-line-background' : threadIndex % 2 == 1}" class="p-2" v-for="(thread, threadIndex) in threads" :key="threadIndex">
      <b-col cols="12" class="p-2">
        <router-link :to="'/club-forum-thread.html?forumCategoryId=' + thread.categoryId + '&clubId=' + thread.clubId + '&threadId=' + thread.id" :id="'thread-category-' + threadIndex"><span class="text-break font-weight-bold text-uppercase">{{ thread.name }}</span></router-link>
        <span class="ml-2 small text-muted">{{ thread.numberOfMessages }} messages</span>
        <span class="ml-2 h5 mb-0 text-secondary"  v-if="!club.isAdmin && thread.isPinned"><pin-icon/></span>
        <span class="ml-2 c-hand h5 mb-0" v-on:click="addAlert(thread, false)" v-if="alertsMap[thread.id] == undefined"><bell-icon/></span>
        <span class="ml-2 c-hand h5 mb-0" v-on:click="deleteAlert(thread, false)" v-if="alertsMap[thread.id] != undefined"><bell-off-outline-icon/></span>
        <span class="ml-2 c-hand h5 mb-0" v-on:click="unpinThread(thread, false)" v-if="club.isAdmin && thread.isPinned"><pin-icon/></span>
        <span class="ml-2 c-hand h5 mb-0" v-on:click="pinThread(thread, false)" v-if="club.isAdmin && !thread.isPinned"><pin-outline-icon/></span>
        <span class="ml-2 c-hand text-danger h5 mb-0" v-on:click="deleteThread(thread, false)" v-if="club.isAdmin"><delete-icon/></span>
      </b-col>
    </b-row>

    <b-pagination v-if="category.numberOfThreads > numberOfRowsPerPage" v-model="currentPage" :total-rows="category.numberOfThreads" :per-page="numberOfRowsPerPage" first-number align="center" @change="loadThreads"></b-pagination>

  </b-card>

  <div class="text-center mt-4">
    <b-link class="btn btn-success" :to="'/club-forum-new-thread.html?forumCategoryId=' + category.id + '&clubId=' + category.clubId">Ajouter un sujet</b-link>
  </div>
</div>
</template>

<script>
import axios from 'axios'

export default {
  data: function () {
    return {
      alerts: [],
      alertsMap: new Map(),
      category: undefined,
      categoryId: undefined,
      club: undefined,
      clubId: undefined,
      threads: [],
      currentPage: 1,
      numberOfRowsPerPage: 20,
    }
  },
  methods: {
    addAlert: function(thread, confirm) {
      if (!confirm) {

        this.$bvModal.msgBoxConfirm('Voulez-vous ajouter une alerte email sur le sujet "' + thread.name + '" ?' , { okVariant: 'success', okTitle: 'Oui', cancelTitle: 'Non', hideHeaderClose: true, centered: true, noFade: true }).then((value) => {
            if (value) {
              this.addAlert(thread, true);
            }
          });
        return;
      }
      this.$parent.refresh = true;

      var json = {
        categoryId: thread.categoryId,
        clubId: thread.clubId,
        threadId: thread.id,
       };

      axios.post("/api/club-forum-thread-alert/add-club-forum-thread-alert/", json)
      .then(() => {
        let p1 = this.getForumThreads();
        let p2 = this.getForumAlerts();
        Promise.all([p1, p2]).then((responses) => {
          this.processGetForumThreadsResponse(responses[0]);
          this.processGetForumAlertsResponse(responses[1]);
          this.$parent.refresh = false;
        });
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
    deleteAlert: function(thread, confirm) {
      if (!confirm) {

        this.$bvModal.msgBoxConfirm('Voulez-vous supprimer l\'alerte email sur le sujet "' + thread.name + '" ?' , { okVariant: 'success', okTitle: 'Oui', cancelTitle: 'Non', hideHeaderClose: true, centered: true, noFade: true }).then((value) => {
            if (value) {
              this.deleteAlert(thread, true);
            }
          });
        return;
      }
      this.$parent.refresh = true;

      var json = {
        categoryId: thread.categoryId,
        clubId: thread.clubId,
        threadId: thread.id,
       };

      axios.post("/api/club-forum-thread-alert/delete-club-forum-thread-alert/", json)
      .then(() => {
        let p1 = this.getForumThreads();
        let p2 = this.getForumAlerts();
        Promise.all([p1, p2]).then((responses) => {
          this.processGetForumThreadsResponse(responses[0]);
          this.processGetForumAlertsResponse(responses[1]);
          this.$parent.refresh = false;
        });
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
    deleteThread: function(thread, confirm) {
      if (!confirm) {

        this.$bvModal.msgBoxConfirm('Voulez-vous supprimer le sujet "' + thread.name + '" ? ATTENTION: Tous les messages de ce sujet seront supprimés !' , { okVariant: 'success', okTitle: 'Oui', cancelTitle: 'Non', hideHeaderClose: true, centered: true, noFade: true }).then((value) => {
            if (value) {
              this.deleteThread(thread, true);
            }
          });
        return;
      }
      this.$parent.refresh = true;

      var json = {
        categoryId: thread.categoryId,
        clubId: thread.clubId,
        threadId: thread.id,
       };

      axios.post("/api/club-forum-thread/delete-club-forum-thread/", json)
      .then(() => {
        let p1 = this.getForumThreads();
        Promise.all([p1]).then((responses) => {
          this.processGetForumThreadsResponse(responses[0]);
          this.$parent.refresh = false;
        });
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
    getForumAlerts: function() {
      return axios.get("/api/club-forum-thread-alert/get-club-forum-category-alerts/" + this.$parent.userSession.user.hash + "/" + this.clubId + "/" + this.categoryId + "/");
    },
    processGetForumAlertsResponse: function(response) {
      this.alerts = response.data;

      this.alertsMap = new Map();
      for (let alert of this.alerts) {
        this.alertsMap[alert.threadId] = alert;
      }
    },
    getForumCategory: function() {
      return axios.get("/api/club-forum-category/get-club-forum-category/" + this.clubId + "/" + this.categoryId + "/");
    },
    processGetForumCategoryResponse: function(response) {
      this.category = response.data;
    },
    getForumThreads: function() {
      return axios.get("/api/club-forum-thread/get-club-forum-threads/" + this.clubId + "/" + this.categoryId + "/" + this.currentPage + "/");
    },
    processGetForumThreadsResponse: function(response) {
      this.threads = response.data;
    },
    loadThreads: function(pageNum) {
      this.currentPage = pageNum;
      this.$parent.refresh = true;
      this.getForumThreads().then(response => {
        this.processGetForumThreadsResponse(response);
        this.$parent.refresh = false;
      });
    },
    pinThread: function(thread, confirm) {
      if (!confirm) {

        this.$bvModal.msgBoxConfirm('Voulez-vous épingler le sujet "' + thread.name + '" ?' , { okVariant: 'success', okTitle: 'Oui', cancelTitle: 'Non', hideHeaderClose: true, centered: true, noFade: true }).then((value) => {
            if (value) {
              this.pinThread(thread, true);
            }
          });
        return;
      }
      this.$parent.refresh = true;

      var json = {
        categoryId: thread.categoryId,
        clubId: thread.clubId,
        threadId: thread.id,
        isPinned: true,
       };

      axios.post("/api/club-forum-thread/update-club-forum-thread-is-pinned/", json)
      .then(() => {
        let p1 = this.getForumThreads();
        Promise.all([p1]).then((responses) => {
          this.processGetForumThreadsResponse(responses[0]);
          this.$parent.refresh = false;
        });
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
    unpinThread: function(thread, confirm) {
      if (!confirm) {

        this.$bvModal.msgBoxConfirm('Voulez-vous dépingler le sujet "' + thread.name + '" ?' , { okVariant: 'success', okTitle: 'Oui', cancelTitle: 'Non', hideHeaderClose: true, centered: true, noFade: true }).then((value) => {
            if (value) {
              this.unpinThread(thread, true);
            }
          });
        return;
      }
      this.$parent.refresh = true;

      var json = {
        categoryId: thread.categoryId,
        clubId: thread.clubId,
        threadId: thread.id,
        isPinned: false,
       };

      axios.post("/api/club-forum-thread/update-club-forum-thread-is-pinned/", json)
      .then(() => {
        let p1 = this.getForumThreads();
        Promise.all([p1]).then((responses) => {
          this.processGetForumThreadsResponse(responses[0]);
          this.$parent.refresh = false;
        });
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
  },
  beforeMount() {
    this.$parent.init();
    this.categoryId = this.$route.query.forumCategoryId;
    this.clubId = this.$route.query.clubId;
    this.club = this.$parent.myUserClubMap[this.clubId].club;

    let p1 = this.getForumCategory();
    let p2 = this.getForumThreads();
    let p3 = this.getForumAlerts();
    Promise.all([p1, p2, p3]).then((responses) => {
      this.processGetForumCategoryResponse(responses[0]);
      this.processGetForumThreadsResponse(responses[1]);
      this.processGetForumAlertsResponse(responses[2]);
      document.title = this.$parent.websiteName + " - Forum " + this.category.name;
      this.$parent.loaded = true;
    }).catch((error)=>{
      console.log(error);
      //this.$router.push('/error.html');
    });
  }
}
</script>
