<template>
<div class="container-fluid flex-fill" v-if="$parent.loaded">
  <div v-for="(equipment, equipmentIndex) in equipments" :key="equipmentIndex">
    <b-row align-v="center" :class="{'even-line-background' : equipmentIndex % 2 == 1}">
      <b-col cols="9">
        <span class="font-weight-bold h1">{{ equipment.name }}</span>
        <span class="ml-2">{{ equipment.serialNumber }}</span><br>

      </b-col>
      <b-col cols="3">
        <span class="barcodeEan13 ml-2">{{ equipment.barcode }}</span>
        <span class="barcodeEan13 ml-2" v-if="equipment.barcode2">{{ equipment.barcode2 }}</span>
      </b-col>
    </b-row>
    <div align-v="center" :class="{'even-line-background' : equipmentIndex % 2 == 1}">
      <span class="barcode128 ml-2" v-if="equipment.code2">{{ equipment.code2 }}</span>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'

export default {
  data: function () {
    return {
      equipments: [],
      club: undefined,
      clubId: undefined,
    }
  },
  methods: {
    getClubEquipments: function() {
      return axios.get("/api/equipment/get-club-equipments/" + this.clubId + "/");
    },
    processGetEquipmentResponse: function(response) {
      this.equipments = response.data;

      for (let equipmentKey in this.equipments) {
        let equipment = this.equipments[equipmentKey];
        equipment.barcode = this.$parent.createEanBarCode(equipment.id);
        if (equipment.code1) {
          equipment.barcode2 = this.$parent.createEanBarCode(equipment.code1);
        }
      }
    }
  },
  beforeMount() {
    this.$parent.init();
    this.$parent.showHeaderAndFooter = false;
    this.clubId = this.$route.query.clubId;
    this.club = this.$parent.myUserClubMap[this.clubId].club;

    let p1 = this.getClubEquipments();
    Promise.all([p1]).then((responses) => {
      this.processGetEquipmentResponse(responses[0]);
      document.title = this.$parent.websiteName + " - Gestion des matériels de l'asso " + this.club.name;
      this.$parent.loaded = true;
    }).catch((error)=>{
      console.log(error);
      //this.$router.push('/error.html');
    });
  }
}
</script>
