<template>
<div class="d-flex align-items-center text-center flex-fill bg" v-if="$parent.loaded">
  <div class="container">
    <b-row>
      <b-col md="2">
        <img src="/static/images/logo.png" alt="logo" width="128" height="128">
      </b-col>
      <b-col>
        <h1 class="font-weight-bold text-white" style="text-shadow: 0px 0px 2px #000000, 0px 0px 4px #000000;">
          <p><span class="text-warning">{{ $parent.websiteName }}</span>
          <span> est un site <i>entièrement gratuit</i> qui vous permet de simplifier la gestion de votre association.</span></p>
        </h1>
      </b-col>
    </b-row>
    <p class="font-weight-bold h2"><router-link to="/faq.html">en savoir plus</router-link></p>
  </div>
</div>
</template>

<script>

export default {
  data: function () {
    return {

    }
  },
  methods: {
  },
  beforeMount() {
    this.$parent.init();

    if (this.$parent.userSession) {
      this.$router.push('/user-dashboard.html');
    }

    document.title = this.$parent.websiteName;
    document.querySelector('meta[name="description"]').setAttribute('content', "MonAsso.fr est un site gratuit simplifie la gestion de votre association." );

    this.$parent.loaded = true;
  }
}
</script>
