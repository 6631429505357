<template>
  <div class="container-fluid flex-fill" v-if="$parent.loaded">

  <b-breadcrumb>
    <b-breadcrumb-item to="/"><b-img class="mb-1" width="32" height="32" src="/static/images/logo.png" alt="logo"></b-img></b-breadcrumb-item>
    <b-breadcrumb-item active class="font-weight-bold">Se Connecter</b-breadcrumb-item>
  </b-breadcrumb>

    <div id="invalid-credentials-div" class="alert alert-danger" role="alert" v-if="invalidCredentials">
        Votre email ou votre mot de passe sont incorrects. Veuillez les vérifier et ré-essayer.
    </div>

    <b-input-group>
      <template #prepend>
        <b-input-group-text class="font-weight-bold">Email</b-input-group-text>
      </template>
      <b-form-input id="email" name="email" v-model="email" type="email" :state="isEmailValid()" autocomplete="email"></b-form-input>
    </b-input-group>

    <b-input-group class="mt-2">
      <template #prepend>
        <b-input-group-text class="font-weight-bold">Mot de passe</b-input-group-text>
      </template>
      <b-form-input id="password" name="password" v-model="password" type="password" :state="isPasswordValid()" autocomplete="current-password" @keyup.enter="login"></b-form-input>
    </b-input-group>

    <b-row class="text-center mt-4">
      <b-col sm="12">
        <b-button id="login-button" v-on:click="login" :variant="disableLoginButton() ? 'secondary' : 'success'" :disabled="disableLoginButton()" size="lg">
          <login-icon/> Se connecter
        </b-button>
        <div class="mt-4"><router-link to="/forget-password.html">
          <lock-open-outline-icon/> Vous avez oublié votre mot de passe ?</router-link>
        </div>
      </b-col>
      <b-col sm="12" class="mt-2">
        <div>Pas de compte utilisateur ? <span class="h5"><router-link to="/register.html">
          <account-plus-icon/> S'enregistrer</router-link></span>
          </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data: function () {
    return {
      email: '',
      invalidCredentials: false,
      password: '',
      serverError: undefined,
      submitted: false,
      redirect: '',
    }
  },
  methods: {
    disableLoginButton: function() {
      return !(this.isEmailValid() && this.isPasswordValid());
    },
    isEmailValid: function() {
      if (this.email.length == 0) return null;
      return this.$parent.emailRegexp.test(this.email);
    },
    isPasswordValid: function() {
      if (this.password.length == 0) return null;
      return this.password.length >= 8;
    },
    login: function() {
      if (this.disableLoginButton()) {
        return;
      }
      var json = {
          email: this.email,
          password: this.password
       };

      this.submitted = true;
      this.invalidCredentials = false;

      axios.post("/api/user/login/", json)
      .then(response => {
        if(response.status == 200) {
          this.$parent.createSession(response);
          if (this.redirect && this.redirect.length > 0) {
            this.$router.push(this.redirect);
          } else {
            let p1 = this.$parent.getMyUserClubs(this.$parent.userSession.user.hash);
            let p2 = this.$parent.getBookRights(this.$parent.userSession.user.hash);
            Promise.all([p1, p2]).then((responses) => {
              this.$parent.processGetMyUserClubsResponse(responses[0]);
              this.$parent.processGetBookRightsResponse(responses[1]);
              this.$router.push('/user-dashboard.html');
            });
          }
          return;
        }
      }).catch(error => {
        if(error.response.status == 400) {
            if (error.response.data.code == 1005) {
              this.invalidCredentials = true;
            } else {
              this.$bvModal.msgBoxOk("Une erreur est survenue. Code erreur " + error.response.data.code, { centered: true, noFade: true });
            }
        } else {
          this.$bvModal.msgBoxOk("Une erreur est survenue. Code HTTP " + error.response.status, { centered: true, noFade: true });
        }
      });
    }
  },
  beforeMount() {
    this.$parent.init();
    document.title = this.$parent.websiteName + " - Se connecter";

    this.$parent.loaded = true;
  }
}
</script>
