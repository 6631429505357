<template>
<div class="container-fluid flex-fill" v-if="$parent.loaded">

  <b-breadcrumb>
    <b-breadcrumb-item to="/"><b-img class="mb-1" width="32" height="32" src="/static/images/logo.png" alt="logo"></b-img></b-breadcrumb-item>
    <b-breadcrumb-item :to="'/club-dashboard.html?clubId=' + clubId" ><office-building-icon/></b-breadcrumb-item>
    <b-breadcrumb-item active class="font-weight-bold"><account-multiple-icon/> Liste adhérents</b-breadcrumb-item>
  </b-breadcrumb>

  <b-card no-body>
    <b-tabs card>
      <b-tab>
        <template v-slot:title>
          <account-multiple-icon/><span class="d-none d-md-inline"> Adhérents</span>
        </template>

        <b-row class="mb-4">
          <b-col cols="12" md="6">
            <b-input-group>
              <b-form-input type="search" v-model="searchText" placeholder="Chercher des adhérents"></b-form-input>
              <template v-slot:append>
                <b-button v-on:click="refresh()"><refresh-icon/></b-button>
              </template>
            </b-input-group>
          </b-col>
        </b-row>
        <b-pagination class="m-0" v-model="currentPage" :total-rows="verifiedUsers.length" :per-page="numberOfRowsPerPage" first-number align="center" v-if="numberOfRowsPerPage < verifiedUsers.length"></b-pagination>

        <div class="small text-muted text-center mt-2 mb-2"><span class="mr-2"><earth-off-icon/> utilisateur hors ligne</span> <span><crown-icon/> administrateur</span></div>

        <b-row class="text-center text-uppercase bg-secondary text-white font-weight-bold mt-2 mb-2 ml-0 mr-0 pt-2 pb-2 align-items-center">
          <b-col sm="1">ID</b-col>
          <b-col sm="4">Prénom NOM</b-col>
          <b-col sm="2">email</b-col>
          <b-col sm="2">Téléphone</b-col>
          <b-col sm="2">N° adhérent</b-col>
          <b-col sm="1"></b-col>
        </b-row>

        <b-row class="mb-2 ml-0 mr-0 text-center" :class="{'bg-light' : userIndex % 2 == 1, 'text-secondary' : !user.isVerified}" align-v="center" v-for="(user, userIndex) in verifiedUsers.slice((currentPage - 1) * numberOfRowsPerPage, (currentPage - 1) * numberOfRowsPerPage + numberOfRowsPerPage)" :key="userIndex">
          <b-col sm="1" class="small">{{ user.id }}</b-col>
          <b-col sm="4" class="font-weight-bold">{{ user.firstname }} {{ user.lastname }}
            <earth-off-icon class="text-secondary" v-if="user.isFake"/>
            <alert-outline-icon class="text-secondary" v-if="!user.isVerified"/> <crown-icon class="text-secondary" v-if="user.isAdmin"/>
          </b-col>
          <b-col sm="2">{{ user.email }}</b-col>
          <b-col sm="2">{{ user.phoneNumber }}</b-col>
          <b-col sm="2">{{ user.licenceNumber }}</b-col>
          <b-col sm="1" class="h3">
            <span class="mr-2 c-hand"><router-link :to="'/club-admin-user.html?userhash=' + user.hash + '&clubId=' + clubId"><eye-outline-icon/></router-link></span>
          </b-col>
        </b-row>

        <b-pagination class="mt-4 mb-0" v-model="currentPage" :total-rows="verifiedUsers.length" :per-page="numberOfRowsPerPage" first-number align="center" v-if="numberOfRowsPerPage < verifiedUsers.length"></b-pagination>

      </b-tab>
      <b-tab>
        <template v-slot:title>
          <account-clock-icon/><span class="d-none d-md-inline"> Demandes adhésion</span>
        </template>
        <b-row class="mt-3" align-v="center">
          <b-col cols="1">
            <b-button v-on:click="refresh()"><refresh-icon/></b-button>
          </b-col>
          <b-col cols="5">
            <b-pagination class="m-0" v-model="currentPage" :total-rows="notVerifiedUsers.length" :per-page="numberOfRowsPerPage" first-number align="center" v-if="numberOfRowsPerPage < notVerifiedUsers.length"></b-pagination>
          </b-col>
        </b-row>

        <b-row class="text-center text-uppercase bg-secondary text-white font-weight-bold mt-2 mb-2 ml-0 mr-0 pt-2 pb-2 align-items-center">
          <b-col sm="1">ID</b-col>
          <b-col sm="4">Prénom NOM</b-col>
          <b-col sm="2">email</b-col>
          <b-col sm="2">Téléphone</b-col>
          <b-col sm="2">Licence</b-col>
          <b-col sm="1">Actions</b-col>
        </b-row>

        <div class="alert alert-secondary mb-0 text-center h5" role="alert" v-if="notVerifiedUsers.length == 0">
          Aucun demande d'adhésion en cours.
        </div>

        <b-row class="mb-2 ml-0 mr-0 text-center" :class="{'bg-light' : userIndex % 2 == 1, 'text-secondary' : !user.isVerified}" align-v="center" v-for="(user, userIndex) in notVerifiedUsers.slice((currentPage - 1) * numberOfRowsPerPage, (currentPage - 1) * numberOfRowsPerPage + numberOfRowsPerPage)" :key="userIndex">
          <b-col sm="1">#{{ user.id }}</b-col>
          <b-col sm="4" class="font-weight-bold">{{ user.firstname }} {{ user.lastname }}</b-col>
          <b-col sm="2">{{ user.email }}</b-col>
          <b-col sm="2">{{ user.phoneNumber }}</b-col>
          <b-col sm="2">{{ user.licenceNumber }}</b-col>
          <b-col sm="1" class="h3">
            <span class="mr-2 c-hand"><router-link :to="'/club-admin-user.html?userhash=' + user.hash + '&clubId=' + clubId"><eye-outline-icon/></router-link></span>
          </b-col>
        </b-row>

        <b-pagination class="mt-4 mb-0" v-model="currentPage" :total-rows="notVerifiedUsers.length" :per-page="numberOfRowsPerPage" first-number align="center" v-if="numberOfRowsPerPage < notVerifiedUsers.length"></b-pagination>

      </b-tab>
      <b-tab>
        <template v-slot:title>
          <earth-off-icon/><span class="d-none d-md-inline"> Ajouter adhérent hors ligne</span>
        </template>
        <div class="alert alert-secondary" role="alert">
          Un adhérent hors ligne <earth-off-icon/> est un adhérent qui n'a pas accès à Internet et de lors ne peut créer de compte utilisateur sur {{$parent.websiteName}}. <b>Cette fonctionnalité n'est pas faite pour créer les adhérents paresseux qui ont accès à Internet</b>.
        </div>

        <b-input-group class="mt-2">
          <template #prepend>
              <b-input-group-text class="font-weight-bold">Prénom</b-input-group-text>
          </template>
          <b-form-input id="firstname" v-model="fakeUser.firstname" type="text" :state="isFirstnameValid()"></b-form-input>
        </b-input-group>

        <b-input-group class="mt-2">
          <template #prepend>
              <b-input-group-text class="font-weight-bold">Nom</b-input-group-text>
          </template>
          <b-form-input id="lastname" v-model="fakeUser.lastname" type="text" :state="isLastnameValid()"></b-form-input>
        </b-input-group>

        <b-input-group class="mt-2">
          <template #prepend>
              <b-input-group-text class="font-weight-bold">Numéro adhérent</b-input-group-text>
          </template>
          <b-form-input id="licence-number" v-model="fakeUser.licenceNumber" type="text" :state="isLicenceNumberValid()"></b-form-input>
        </b-input-group>

        <b-form inline class="mt-2">
          <b-form-group label="Sexe" label-class="font-weight-bold">
            <b-form-radio-group id="sex" v-model="fakeUser.sex" :options="sexOptions"></b-form-radio-group>
          </b-form-group>
        </b-form>

        <b-input-group class="mt-2">
          <template #prepend>
            <b-input-group-text class="font-weight-bold">Date de naissance</b-input-group-text>
          </template>
          <b-form-select id="birthdate-day" v-model="fakeUser.birthdateDay" :options="birthdateDayList" :state="isBirthdateDayValid()"></b-form-select>
          <b-form-select id="birthdate-month" v-model="fakeUser.birthdateMonth" :options="birthdateMonthList" :state="isBirthdateMonthValid()"></b-form-select>
          <b-form-select id="birthdate-year" v-model="fakeUser.birthdateYear" :options="birthdateYearList" :state="isBirthdateYearValid()"></b-form-select>
        </b-input-group>

        <b-input-group class="mt-2">
          <template #prepend>
            <b-input-group-text class="font-weight-bold">Téléphone</b-input-group-text>
          </template>
          <b-form-input id="phone-number" v-model="fakeUser.phoneNumber" type="text" :state="isPhoneNumberValid()"></b-form-input>
        </b-input-group>

        <div class="text-center mt-4">
            <b-button v-on:click="addFakeUser" :variant="isFormValid() ? 'success': 'secondary'" size="lg" :disabled="!isFormValid()"><account-plus-icon/> Ajouter l'adhérent</b-button>
        </div>

      </b-tab>
    </b-tabs>
  </b-card>
</div>
</template>

<script>
import axios from 'axios'

export default {
  data: function () {
    return {
      birthdateDayList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
      birthdateMonthList: [ { value: 1, text: "Janvier" }, { value: 2, text: "Février" }, { value: 2, text: "Mars" }, { value: 4, text: "Avril" }, { value: 5, text: "Mai" }, { value: 6, text: "Juin" }, { value: 7, text: "Juillet" }, { value: 8, text: "Août" }, { value: 9, text: "Septembre" }, { value: 10, text: "Octobre" }, { value: 11, text: "Novembre" }, { value: 12, text: "Décembre" }  ],
      birthdateYearList: [],
      club: undefined,
      clubId: undefined,
      currentPage: 1,
      currentYear: new Date().getFullYear(),
      fakeUser: {firstname: '', lastname: '', licenceNumber: '', phoneNumber: '', birthdateDay: '', birthdateMonth: '', birthdateYear: '', sex: 'male'},
      numberOfRowsPerPage: 50,
      searchText: '',
      sexOptions: [
          { text: 'Masculin', value: 'male' },
          { text: 'Féminin', value: 'female' },
      ],
      users: [],
    }
  },
  computed: {
    verifiedUsers: function () {

      let searchTextLC = this.searchText.toLowerCase();
      var searchUsers = this.users.filter(row => {
        let filterOnName = (this.searchText != undefined && this.searchText.length >= 2);
        let filterOnVerified = true;

        let filterOnNameIsOK = true;
        let filterOnVerifiedIsOK = true;

        if (filterOnName) {
          filterOnNameIsOK = (row.lastname.toLowerCase().includes(searchTextLC) || row.firstname.toLowerCase().includes(searchTextLC));
        }

        if (filterOnVerified) {
          filterOnVerifiedIsOK = row.isVerified;
        }
        return filterOnNameIsOK && filterOnVerifiedIsOK;
      });
      return searchUsers;
    },
    notVerifiedUsers: function () {
      return this.users.filter(row => {
        return !row.isVerified
      });
    }
  },
  methods: {
    addFakeUser: function() {
      this.$parent.refresh = true;
      let birthdateString = '';

      birthdateString += this.fakeUser.birthdateYear;
      birthdateString += '-';

      if (this.fakeUser.birthdateMonth < 10) {
        birthdateString += '0';
      }
      birthdateString += this.fakeUser.birthdateMonth;
      birthdateString += '-';

      if (this.fakeUser.birthdateDay < 10) {
        birthdateString += '0';
      }
      birthdateString += this.fakeUser.birthdateDay;

      var json = {
        birthdate: birthdateString,
        clubId: this.club.id,
        firstname: this.fakeUser.firstname,
        lastname: this.fakeUser.lastname,
        licenceNumber: this.fakeUser.licenceNumber,
        phoneNumber: this.fakeUser.phoneNumber,
        sex: this.fakeUser.sex
       };

      axios.post("/api/user/add-fake-user/", json)
      .then(response => {
        if(response.status == 200) {
          this.getUsers().then(response=>{
            this.processGetUsersResponse(response);
            this.fakeUser = {firstname: '', lastname: '', licenceNumber: '', phoneNumber: '', birthdateDay: '', birthdateMonth: '', birthdateYear: '', sex: 'male'},
            this.$bvModal.msgBoxOk("L'adhérent a été ajouté à votre asso.", { centered: true, noFade: true });
            this.$parent.refresh = false;
          });
        }
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
    isBirthdateDayValid: function() {
      if (this.fakeUser.birthdateDay == null || this.fakeUser.birthdateDay.length == 0) return null;
      return this.fakeUser.birthdateDay != null && this.fakeUser.birthdateDay >= 1 && this.fakeUser.birthdateDay <= 31;
    },
    isBirthdateMonthValid: function() {
      if (this.fakeUser.birthdateMonth.length == 0) return null;
      return this.fakeUser.birthdateMonth != null && this.fakeUser.birthdateMonth >= 1 && this.fakeUser.birthdateMonth <= 12;
    },
    isBirthdateYearValid: function() {
      if (this.fakeUser.birthdateYear.length == 0) return null;
      return this.fakeUser.birthdateYear != null && this.fakeUser.birthdateYear >= (this.currentYear - 100) && this.fakeUser.birthdateYear <= this.currentYear;
    },
    isFirstnameValid: function() {
      if (this.fakeUser.firstname.length == 0) return null;
      return this.fakeUser.firstname.length >= 2 && this.fakeUser.firstname.length <= 255;
    },
    isFormValid: function() {
      return this.isFirstnameValid() && this.isLastnameValid() && this.isPhoneNumberValid() && this.isBirthdateDayValid() && this.isBirthdateMonthValid()&& this.isBirthdateYearValid() && this.isLicenceNumberValid();
    },
    isLastnameValid: function() {
      if (this.fakeUser.lastname.length == 0) return null;
      return this.fakeUser.lastname.length >= 2 && this.fakeUser.lastname.length <= 255;
    },
    isLicenceNumberValid: function() {
      if (this.fakeUser.licenceNumber.length == 0) return null;
      return this.fakeUser.licenceNumber.length >= 6 && this.fakeUser.licenceNumber.length <= 20;
    },
    isPhoneNumberValid: function() {
      if (this.fakeUser.phoneNumber.length == 0) return null;
      return this.$parent.phoneNumberRegex.test(this.fakeUser.phoneNumber) && this.fakeUser.phoneNumber.length <= 20;
    },
    getUsers: function() {
      return axios.get("/api/user-club/get-club-users/" + this.clubId + "/");
    },
    processGetUsersResponse: function(response) {
      let rawUsers = response.data;
      this.users = [];
      for (let rawUserKey in rawUsers) {
        let rawUser = rawUsers[rawUserKey];

        this.users.push({hash: rawUser.user.hash, id: rawUser.user.id, firstname: rawUser.user.firstname, lastname: rawUser.user.lastname, licenceNumber: rawUser.licenceNumber, phoneNumber: rawUser.user.phoneNumber, email: rawUser.user.email, isVerified: rawUser.isVerified, isAdmin: rawUser.isAdmin, isFake: rawUser.user.isFake })

      }
    },
    refresh() {
      this.$parent.refresh = true;
      let p1 = this.getUsers();
      Promise.all([p1]).then((responses) => {
        this.processGetUsersResponse(responses[0]);
        this.$parent.refresh = false;
      });
    }
  },
  beforeMount() {
    this.$parent.init();
    this.clubId = this.$route.query.clubId;
    this.club = this.$parent.myUserClubMap[this.clubId].club;
    document.title = this.$parent.websiteName + " - Liste adhérents " + this.club.name;

    for (var i = this.currentYear; i >= this.currentYear - 100; i--) {
      this.birthdateYearList.push(i);
    }

    let p1 = this.getUsers();
    Promise.all([p1]).then((responses) => {
      this.processGetUsersResponse(responses[0]);
      this.$parent.loaded = true;
    }).catch(()=>{
      this.$router.push('/error.html');
    });

    this.$parent.loaded = true;
  }
}
</script>
