<template>
<div  class="flex-fill d-flex h-100" style="flex-direction: column" v-if="$parent.loaded">

  <div class="m-3">
    <b-breadcrumb>
      <b-breadcrumb-item to="/"><b-img class="mb-1" width="32" height="32" src="/static/images/logo.png" alt="logo"></b-img></b-breadcrumb-item>
      <b-breadcrumb-item :to="'/club-dashboard.html?clubId=' + book.clubId" ><office-building-icon/></b-breadcrumb-item>
      <b-breadcrumb-item :to="'/book.html?hash=' + book.hash" ><book-icon/></b-breadcrumb-item>
      <b-breadcrumb-item active class="font-weight-bold"><message-icon/> {{ book.name }} {{ startDatePrettyFormat }}</b-breadcrumb-item>
    </b-breadcrumb>
  </div>

  <div ref="messageListContainer" class="flex-fill ml-3">
    <div ref="messageList" id="message-list" class="group-talk-message-list pr-3">
      <div v-for="(groupTalkMessage, groupTalkMessageIndex) in groupTalkMessages" :key="groupTalkMessageIndex">

        <div class="mb-2" v-if="groupTalkMessage.user.id == $parent.userSession.user.id">
        <div class="group-talk-message group-talk-message-mine">
          <div v-html="groupTalkMessage.text" class="text-break"></div>
        </div>
          <div class="small text-muted text-right mr-2">{{ groupTalkMessage.creationDate }}</div>
        </div>

        <div class="mb-2" v-if="groupTalkMessage.user.id != $parent.userSession.user.id">
          <div class="group-talk-message group-talk-message-other">
            <div class="font-weight-bold">{{ groupTalkMessage.user.firstname }} {{ groupTalkMessage.user.lastname }}</div>
            <div v-html="groupTalkMessage.text" class="text-break"></div>
          </div>
          <div class="small text-muted text-left ml-2">{{ groupTalkMessage.creationDate }}</div>
        </div>

      </div>
      <div id="bottom-messages"></div>
    </div>
  </div>
  <div class="m-3">
    <div style="display: flex" class="mt-0">
      <div style="display: flex; width: 100%">
        <b-form-textarea style="background-color: #DDDDDD;" id="new-message" v-model="newMessage" rows="3" max-rows="3"></b-form-textarea>
      </div>
      <div style="display: flex" class="ml-2">
        <b-button variant="success" size="lg" v-on:click="addGroupTalkMessage()" :disabled="newMessage.length == 0"><send-icon/></b-button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

export default {
  data: function () {
    return {
      book: undefined,
      bookHash: undefined,
      slotStartDate: undefined,
      slotEndDate: undefined,
      slotStartDatePrettyFormat: undefined,
      groupTalkMessages: [],
      newMessage: '',
    }
  },
  methods: {
    addGroupTalkMessage: function() {
      if (this.newMessage.length == 0) {
        return;
      }
      this.$parent.refresh = true;
      let newMessageHtml = '<p>' + this.newMessage.replaceAll('\n', '</p><p>') + '</p>';

      var json = {
        bookId: this.book.id,
        slotStartDate: moment(this.slotStartDate, "YYYY-MM-DD-HH-mm-ss").format("yyyy-MM-DD HH:mm:ss"),
        slotEndDate: moment(this.slotEndDate, "YYYY-MM-DD-HH-mm-ss").format("yyyy-MM-DD HH:mm:ss"),
        text: newMessageHtml,
        userId: this.$parent.userSession.user.id,
       };

      axios.post("/api/book-slot-group-talk-message/add-group-talk-message/", json)
      .then(() => {
        this.lastFirstGroupTalkMessageId = 0;
        this.groupTalkMessages = [];
        this.getGroupTalkMessages().then(response => {
          this.processGetGroupTalkMessagesResponse(response);
          this.newMessage = '';
          this.$parent.refresh = false;
          this.$nextTick(() => {
            this.scrollToBottomMessages();
          });
        });
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
    getBook: function() {
      return axios.get("/api/book/get-book/" + this.bookHash + "/");
    },
    processGetBookResponse: function(response) {
      this.book = response.data;
    },
    getGroupTalkMessages: function() {
      return axios.get("/api/book-slot-group-talk-message/get-group-talk-messages/" + this.bookHash + "/" + this.slotStartDate + "/" + this.slotEndDate + "/");
    },
    processGetGroupTalkMessagesResponse: function(response) {
      this.groupTalkMessages = response.data;
    },
    scrollToBottomMessages: function() {
      window.document.getElementById('bottom-messages').scrollIntoView();
    }
  },
  mounted() {
    const interval = setInterval(() => {
      if (this.$refs.messageListContainer && this.$refs.messageList) {
        let messageList = window.document.getElementById('message-list');
        messageList.style.height = this.$refs.messageListContainer.clientHeight + 'px';
        this.scrollToBottomMessages();
        clearInterval(interval)
      }
    }, 50)
  },
  beforeMount() {
    this.$parent.init();
    this.bookHash = this.$route.query.bookHash;
    this.slotStartDate = this.$route.query.slotStartDate;
    this.slotEndDate = this.$route.query.slotEndDate;

    this.startDatePrettyFormat = this.$parent.convertToPrettyDate(moment(this.slotStartDate, "YYYY-MM-DD-HH-mm-ss"));

    let p1 = this.getBook();
    let p2 = this.getGroupTalkMessages();
    Promise.all([p1, p2]).then((responses) => {
      this.processGetBookResponse(responses[0]);
      this.processGetGroupTalkMessagesResponse(responses[1]);
      document.title = this.$parent.websiteName + " - Salon " + this.book.name + " - " + this.startDatePrettyFormat;

      this.$parent.loaded = true;
    }).catch((error)=>{
      console.log(error);
      //this.$router.push('/error.html');
    });
  }
}
</script>
