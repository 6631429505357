<template>
<div class="container-fluid flex-fill" v-if="$parent.loaded">

  <b-breadcrumb>
    <b-breadcrumb-item to="/"><b-img class="mb-1" width="32" height="32" src="/static/images/logo.png" alt="logo"></b-img></b-breadcrumb-item>
    <b-breadcrumb-item active class="font-weight-bold">Mon profil</b-breadcrumb-item>
  </b-breadcrumb>

  <h2>Notifications email</h2>
  <b-form-checkbox switch v-model="clubNewsMailNotification" v-on:change="updateUserSetting(clubNewsMailNotificationUserSettingName, clubNewsMailNotification)">Les actualités de mon asso</b-form-checkbox>
  <b-form-checkbox class="mt2" switch v-model="bookMailNotification" v-on:change="updateUserSetting(bookMailNotificationUserSettingName, bookMailNotification)">Mes réservations <span class="text-muted">(nous vous conseillons fortement de laisser les notifiations activées sur les réservations)</span></b-form-checkbox>
  <b-form-checkbox class="mt2" switch v-model="privateMessageMailNotification" v-on:change="updateUserSetting(privateMessageMailNotificationUserSettingName, privateMessageMailNotification)">Messages privés</b-form-checkbox>

</div>
</template>

<script>
import axios from 'axios'

export default {
  data: function () {
    return {
      bookMailNotification: true,
      bookMailNotificationUserSettingName: 'book-mail-notification',
      clubNewsMailNotification: true,
      clubNewsMailNotificationUserSettingName: 'club-news-mail-notification',
      privateMessageMailNotification: true,
      privateMessageMailNotificationUserSettingName: 'private-message-mail-notification',
    }
  },
  methods: {
    init: function() {
      this.bookMailNotification = this.$parent.userSettings[this.bookMailNotificationUserSettingName] ? this.$parent.userSettings[this.bookMailNotificationUserSettingName] == 'true' : true;
      this.clubNewsMailNotification = this.$parent.userSettings[this.clubNewsMailNotificationUserSettingName] ? this.$parent.userSettings[this.clubNewsMailNotificationUserSettingName] == 'true' : true;
      this.privateMessageMailNotification = this.$parent.userSettings[this.privateMessageMailNotificationUserSettingName] ? this.$parent.userSettings[this.privateMessageMailNotificationUserSettingName] == 'true' : true;
    },
    updateUserSetting(name, value) {
      this.$parent.refresh = true;
      var json = {
        name: name,
        value: value,
       };

      axios.post("/api/user-setting/update-user-setting/", json)
      .then(() => {
        this.$parent.getUserSettings(this.$parent.userSession.user.hash).then(response => {
          this.$parent.processGetUserSettingsResponse(response);
          this.init();
          this.$parent.refresh = false;
        });
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    }
  },
  beforeMount() {
    this.$parent.init();
    document.title = this.$parent.websiteName + " - Mon profil";

    let p1 = this.$parent.getUserSettings(this.$parent.userSession.user.hash);
    Promise.all([p1]).then((responses) => {
      this.$parent.processGetUserSettingsResponse(responses[0]);
      this.init();
      this.$parent.loaded = true;
    }).catch(()=>{
      this.$router.push('/error.html');
    });

  }
}
</script>
