import Vue from 'vue'
import VueRouter from 'vue-router'

import AdminAddClub from '../views/admin/AddClub.vue'
import AdminClub from '../views/admin/Club.vue'
import AdminClubs from '../views/admin/Clubs.vue'
import AdminDomains from '../views/admin/Domains.vue'
import AdminEditUser from '../views/admin/EditUser.vue'
import AdminForumCategory from '../views/admin/ForumCategory.vue'
import AdminHome from '../views/admin/Home.vue'
import AdminNews from '../views/admin/News.vue'
import AdminUsers from '../views/admin/Users.vue'

import AddBook from '../views/book/AddBook.vue'
import Book from '../views/book/Book.vue'
import Books from '../views/book/Books.vue'

import ClubAdminBooks from '../views/club/admin/book/ClubAdminBooks.vue'
import ClubAdminBookUsers from '../views/club/admin/book/ClubAdminBookUsers.vue'
import ClubAdminBookUsersReadOnly from '../views/club/admin/book/ClubAdminBookUsersReadOnly.vue'
import ClubAdminBookEdit from '../views/club/admin/book/ClubAdminBookEdit.vue'
import ClubAdminEditInformation from '../views/club/admin/club/ClubAdminEditInformation.vue'
import ClubAdminEquipment from '../views/club/admin/equipment/ClubAdminEquipment.vue'
import ClubAdminEditEquipment from '../views/club/admin/equipment/ClubAdminEditEquipment.vue'
import ClubAdminEquipmentPrint from '../views/club/admin/equipment/ClubAdminEquipmentPrint.vue'
import ClubAdminForumCategory from '../views/club/admin/forum/ClubAdminForumCategory.vue'
import ClubAdminGroupTalk from '../views/club/admin/grouptalk/ClubAdminGroupTalk.vue'
import ClubAdminNews from '../views/club/admin/news/ClubAdminNews.vue'
import ClubAdminPages from '../views/club/admin/page/ClubAdminPages.vue'
import ClubAdminEditPage from '../views/club/admin/page/ClubAdminEditPage.vue'
import ClubAdminPoll from '../views/club/admin/poll/ClubAdminPoll.vue'
import ClubAdminUser from '../views/club/admin/user/ClubAdminUser.vue'
import ClubAdminUsers from '../views/club/admin/user/ClubAdminUsers.vue'
import ClubAdminUserTags from '../views/club/admin/user/ClubAdminUserTags.vue'

import Clubs from '../views/club/Clubs.vue'
import ClubPage from '../views/club/page/ClubPage.vue'
import ClubPublicPage from '../views/club/ClubPublicPage.vue'
import ClubDashboard from '../views/club/ClubDashboard.vue'
import ClubForumCategory from '../views/club/forum/ClubForumCategory.vue'
import ClubForumNewThread from '../views/club/forum/ClubForumNewThread.vue'
import ClubForumThread from '../views/club/forum/ClubForumThread.vue'
import ClubNewsComments from '../views/club/news/comment/ClubNewsComments.vue'
import ClubPollComments from '../views/club/poll/comment/ClubPollComments.vue'
import ClubAddPoll from '../views/club/poll/AddPoll.vue'

import Domains from '../views/domain/Domains.vue'

import ClubGroupTalk from '../views/grouptalk/ClubGroupTalk.vue'
import BookSlotGroupTalk from '../views/grouptalk/BookSlotGroupTalk.vue'

import Changelog from '../views/help/Changelog.vue'
import Contact from '../views/help/Contact.vue'
import Donate from '../views/help/Donate.vue'
import Faq from '../views/help/Faq.vue'
import Help from '../views/help/Help.vue'
import Legal from '../views/help/Legal.vue'
import Thanks from '../views/help/Thanks.vue'

import Error from '../views/misc/Error.vue'
import PageNotFound from '../views/misc/PageNotFound.vue'

import SiteForumCategory from '../views/site/forum/SiteForumCategory.vue'
import SiteForumNewThread from '../views/site/forum/SiteForumNewThread.vue'
import SiteForumThread from '../views/site/forum/SiteForumThread.vue'
import SiteNewsComments from '../views/site/SiteNewsComments.vue'

import ForgetPassword from '../views/user/ForgetPassword.vue'
import Login from '../views/user/Login.vue'
import Logout from '../views/user/Logout.vue'
import PrivateMessage from '../views/user/PrivateMessage.vue'
import PrivateMessages from '../views/user/PrivateMessages.vue'
import Profile from '../views/user/Profile.vue'
import Register from '../views/user/Register.vue'
import UserDashboard from '../views/user/UserDashboard.vue'

import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Home', component: Home },
  { path: '/error.html', name: 'Error', component: Error },

  { path: '/admin/', name: 'AdminHome', component: AdminHome, meta: { requiresAuth: true } },
  { path: '/admin/add-club.html', name: 'AdminAddClub', component: AdminAddClub, meta: { requiresAuth: true } },
  { path: '/admin/club.html', name: 'AdminClub', component: AdminClub, meta: { requiresAuth: true } },
  { path: '/admin/clubs.html', name: 'AdminClubs', component: AdminClubs, meta: { requiresAuth: true } },
  { path: '/admin/domains.html', name: 'AdminDomains', component: AdminDomains, meta: { requiresAuth: true } },
  { path: '/admin/edit-user.html', name: 'AdminEditUser', component: AdminEditUser, meta: { requiresAuth: true } },
  { path: '/admin/forum-category.html', name: 'AdminForumCategory', component: AdminForumCategory, meta: { requiresAuth: true } },
  { path: '/admin/news.html', name: 'AdminNews', component: AdminNews, meta: { requiresAuth: true } },
  { path: '/admin/users.html', name: 'AdminUsers', component: AdminUsers, meta: { requiresAuth: true } },

  { path: '/add-book.html', name: 'AddBook', component: AddBook, meta: { requiresAuth: true } },
  { path: '/book.html', name: 'Book', component: Book, meta: { requiresAuth: true } },
  { path: '/books.html', name: 'Books', component: Books, meta: { requiresAuth: true } },

  { path: '/club-admin-user.html', name: 'ClubAdminUser', component: ClubAdminUser, meta: { requiresAuth: true } },
  { path: '/club-admin-books.html', name: 'ClubAdminBooks', component: ClubAdminBooks, meta: { requiresAuth: true } },
  { path: '/club-admin-book-edit.html', name: 'ClubAdminBookEdit', component: ClubAdminBookEdit, meta: { requiresAuth: true } },
  { path: '/club-admin-book-users.html', name: 'ClubAdminBookUsers', component: ClubAdminBookUsers, meta: { requiresAuth: true } },
  { path: '/club-admin-book-users-read-only.html', name: 'ClubAdminBookUsersReadOnly', component: ClubAdminBookUsersReadOnly, meta: { requiresAuth: true } },
  { path: '/club-admin-equipment.html', name: 'ClubAdminEquipment', component: ClubAdminEquipment, meta: { requiresAuth: true } },
  { path: '/club-admin-edit-equipment.html', name: 'ClubAdminEditEquipment', component: ClubAdminEditEquipment, meta: { requiresAuth: true } },
  { path: '/club-admin-edit-information.html', name: 'ClubAdminEditInformation', component: ClubAdminEditInformation, meta: { requiresAuth: true } },
  { path: '/club-admin-equipment-print.html', name: 'ClubAdminEquipmentPrint', component: ClubAdminEquipmentPrint, meta: { requiresAuth: true } },
  { path: '/club-admin-forum-category.html', name: 'ClubAdminForumCategory', component: ClubAdminForumCategory, meta: { requiresAuth: true } },
  { path: '/club-admin-group-talk.html', name: 'ClubAdminGroupTalk', component: ClubAdminGroupTalk, meta: { requiresAuth: true } },
  { path: '/club-admin-news.html', name: 'ClubAdminNews', component: ClubAdminNews, meta: { requiresAuth: true } },
  { path: '/club-admin-pages.html', name: 'ClubAdminPages', component: ClubAdminPages, meta: { requiresAuth: true } },
  { path: '/club-admin-edit-page.html', name: 'ClubAdminEditPage', component: ClubAdminEditPage, meta: { requiresAuth: true } },
  { path: '/club-admin-poll.html', name: 'ClubAdminPoll', component: ClubAdminPoll, meta: { requiresAuth: true } },
  { path: '/club-admin-users.html', name: 'ClubAdminUsers', component: ClubAdminUsers, meta: { requiresAuth: true } },
  { path: '/club-admin-user-tags.html', name: 'ClubAdminUserTags', component: ClubAdminUserTags, meta: { requiresAuth: true } },

  { path: '/book-slot-group-talk.html', name: 'BookSlotGroupTalk', component: BookSlotGroupTalk, meta: { requiresAuth: true } },
  { path: '/clubs.html', name: 'Clubs', component: Clubs },
  { path: '/club-forum-category.html', name: 'ClubForumCategory', component: ClubForumCategory, meta: { requiresAuth: true } },
  { path: '/club-forum-new-thread.html', name: 'ClubForumNewThread', component: ClubForumNewThread, meta: { requiresAuth: true } },
  { path: '/club-forum-thread.html', name: 'ClubForumThread', component: ClubForumThread, meta: { requiresAuth: true } },
  { path: '/club-public-page.html', name: 'ClubPublicPage', component: ClubPublicPage },
  { path: '/club-page.html', name: 'ClubPage', component: ClubPage },
  { path: '/club-group-talk.html', name: 'ClubGroupTalk', component: ClubGroupTalk, meta: { requiresAuth: true } },
  { path: '/club-dashboard.html', name: 'ClubDashboard', component: ClubDashboard, meta: { requiresAuth: true } },
  { path: '/club-news-comments.html', name: 'ClubNewsComments', component: ClubNewsComments, meta: { requiresAuth: true } },
  { path: '/club-poll-comments.html', name: 'ClubPollComments', component: ClubPollComments, meta: { requiresAuth: true } },
  { path: '/club-add-poll.html', name: 'ClubAddPoll', component: ClubAddPoll, meta: { requiresAuth: true } },

  { path: '/domains.html', name: 'Domains', component: Domains },

  { path: '/changelog.html', name: 'Changelog', component: Changelog },
  { path: '/contact.html', name: 'Contact', component: Contact },
  { path: '/donate.html', name: 'Donate', component: Donate },
  { path: '/faq.html', name: 'Faq', component: Faq },
  { path: '/help.html', name: 'Help', component: Help },
  { path: '/legal.html', name: 'Legal', component: Legal },
  { path: '/thanks.html', name: 'Thanks', component: Thanks },

  { path: '/site-forum-category.html', name: 'SiteForumCategory', component: SiteForumCategory },
  { path: '/site-forum-new-thread.html', name: 'SiteForumNewThread', component: SiteForumNewThread },
  { path: '/site-forum-thread.html', name: 'SiteForumThread', component: SiteForumThread },
  { path: '/site-news-comments.html', name: 'SiteNewsComments', component: SiteNewsComments },

  { path: '/forget-password.html', name: 'ForgetPassword', component: ForgetPassword },
  { path: '/login.html', name: 'Login', component: Login },
  { path: '/logout.html', name: 'Logout', component: Logout },
  { path: '/private-message.html', name: 'PrivateMessage', component: PrivateMessage, meta: { requiresAuth: true } },
  { path: '/private-messages.html', name: 'PrivateMessages', component: PrivateMessages, meta: { requiresAuth: true } },
  { path: '/profile.html', name: 'Profile', component: Profile, meta: { requiresAuth: true } },
  { path: '/register.html', name: 'Register', component: Register },
  { path: '/user-dashboard.html', name: 'UserDashboard', component: UserDashboard },

  { path: '*', name: 'PageNotFound', component: PageNotFound },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && (localStorage.sessionHash == undefined || localStorage.sessionHash == 'undefined')) {
    next('/login.html');
    return;
  }
    next();
});

export default router
