<template>
<div class="container-fluid flex-fill" v-if="$parent.loaded">

  <b-breadcrumb>
    <b-breadcrumb-item to="/"><b-img class="mb-1" width="32" height="32" src="/static/images/logo.png" alt="logo"></b-img></b-breadcrumb-item>
    <b-breadcrumb-item :to="'/club-dashboard.html?clubId=' + clubId"><office-building-icon/></b-breadcrumb-item>
    <b-breadcrumb-item :to="'/club-admin-users.html?clubId=' + clubId"><account-multiple-icon/></b-breadcrumb-item>
    <b-breadcrumb-item active class="font-weight-bold">{{ user.firstname }} {{ user.lastname }}</b-breadcrumb-item>
  </b-breadcrumb>

  <b-card no-body>
    <b-tabs card>
      <b-tab>
          <template v-slot:title>
            <card-account-details-outline-icon/> Profile
          </template>

          <div class="alert alert-secondary" role="alert" v-if="user.isFake">
            Il s'agit d'un adhérent hors ligne <earth-off-icon/>
          </div>

          <b-form inline class="mt-2">
            <b-form-group label="Sexe" label-class="font-weight-bold">
              <b-form-radio-group id="sex" v-model="user.sex" :options="sexOptions" :disabled="!user.isFake"></b-form-radio-group>
            </b-form-group>
          </b-form>

          <b-input-group>
            <template #prepend>
                <b-input-group-text class="font-weight-bold">Prénom</b-input-group-text>
            </template>
            <b-form-input id="firstname" v-model="user.firstname" :disabled="!user.isFake"></b-form-input>
          </b-input-group>

          <b-input-group class="mt-2">
            <template #prepend>
                <b-input-group-text class="font-weight-bold">Nom</b-input-group-text>
            </template>
            <b-form-input id="lastname" v-model="user.lastname" :disabled="!user.isFake"></b-form-input>
          </b-input-group>

          <b-input-group class="mt-2">
            <template #prepend>
                <b-input-group-text class="font-weight-bold">Email</b-input-group-text>
            </template>
            <b-form-input id="email" v-model="user.email" type="email" disabled></b-form-input>
          </b-input-group>

          <b-input-group class="mt-2">
            <template #prepend>
                <b-input-group-text class="font-weight-bold">Numéro de téléphone</b-input-group-text>
            </template>
            <b-form-input id="phoneNumber" v-model="user.phoneNumber" :disabled="!user.isFake"></b-form-input>
          </b-input-group>

          <b-input-group class="mt-2">
            <template #prepend>
                <b-input-group-text class="font-weight-bold">Licence</b-input-group-text>
            </template>
            <b-form-input id="licenceNumber" v-model="userClub.licenceNumber"></b-form-input>
          </b-input-group>

          <b-input-group class="mt-2">
            <template #prepend>
                <b-input-group-text class="font-weight-bold">Date de naissance</b-input-group-text>
            </template>
            <b-form-input id="birthdate" v-model="user.birthdate" :disabled="!user.isFake"></b-form-input>
          </b-input-group>

          <div class="text-center mt-4">
            <b-button variant="success" class="mr-2" v-on:click="updateUserClub(false, 2)" v-if="userClub.isVerified"><check-circle-icon/> Mettre à jour</b-button>
            <b-button variant="danger" class="mr-2" v-on:click="deleteUserClub(false, 2)" v-if="userClub.isVerified"><delete-icon/> Enlever l'adhérent de l'asso</b-button>
            <b-button variant="success" class="mr-2" v-on:click="acceptUser()" v-if="!userClub.isVerified">Autoriser la demande</b-button>
            <b-button variant="danger" class="mr-2" v-on:click="deleteUserClub(false, 1)" v-if="!userClub.isVerified">Refuser la demande</b-button>
          </div>
      </b-tab>

      <b-tab>
        <template v-slot:title>
          <office-building-icon/> Droits Asso
        </template>
        <div class="text-right"><router-link to="/help.html#club-rights" target="_blank"><information-outline-icon/> aide</router-link></div>
        <b-list-group>
          <b-list-group-item class="d-flex justify-content-between align-items-center">
            Type de profil
            <b-form-radio-group v-model="userClub.isAdmin" button-variant="outline-dark" name="radio-btn-outline" buttons v-on:change="updateUserClub(false, 2)">
              <template>
                <b-form-radio value="false" button-variant="outline-primary"><account-outline-icon/></b-form-radio>
                <b-form-radio value="true" button-variant="outline-danger"><shield-account-outline-icon/></b-form-radio>
              </template>
            </b-form-radio-group>
          </b-list-group-item>
        </b-list-group>
      </b-tab>

      <b-tab>
        <template v-slot:title>
          <book-icon/> Droits registre
        </template>

        <div class="text-right"><router-link to="/help.html#book-user-rights" target="_blank"><information-outline-icon/> aide</router-link></div>
        <b-list-group>
          <b-list-group-item class="d-flex justify-content-between align-items-center" v-for="(book, bookIndex) in books" :key="bookIndex">
            {{ book.name }}
            <b-form-radio-group v-model="book.newRight" button-variant="outline-dark" name="radio-btn-outline" buttons v-on:change="updateBookRight(book, false)">
              <template>
                <b-form-radio value="-100" v-if="book.newRight != -100"><delete-icon/></b-form-radio>
                <b-form-radio value="-1" button-variant="outline-secondary"><clock-outline-icon/></b-form-radio>
                <b-form-radio value="0"><eye-outline-icon/></b-form-radio>
                <b-form-radio value="1" button-variant="outline-primary"><account-outline-icon/></b-form-radio>
                <b-form-radio value="3" button-variant="outline-success"><shield-star-outline-icon/></b-form-radio>
                <b-form-radio value="4" button-variant="outline-danger"><shield-account-outline-icon/></b-form-radio>
              </template>
            </b-form-radio-group>
          </b-list-group-item>
        </b-list-group>
      </b-tab>

      <b-tab>
        <template v-slot:title>
          <tag-icon/> Tags
        </template>

        <div class="alert alert-secondary mb-0 text-center h5" role="alert" v-if="tags.length == 0">
          Aucun tag trouvé.
        </div>

        <b-form-checkbox class="mr-2" v-model="tag.checked" button button-variant="outline-secondary" v-for="(tag, tagIndex) in tags" :key="tagIndex" v-on:change="updateTag(tag)">
          {{ tag.name }}
        </b-form-checkbox>

      </b-tab>

    </b-tabs>
  </b-card>


</div>
</template>

<script>
import axios from 'axios'

export default {
  data: function () {
    return {
      bookUserRights: [],
      bookUserRightsMap: new Map(),
      books: [],
      booksMap: new Map(),
      club: undefined,
      clubId: undefined,
      currentPage: 1,
      numberOfRowsPerPage: 50,
      onlyVerifiedUsers: true,
      searchText: '',
      sexOptions: [
          { text: 'Masculin', value: 'male' },
          { text: 'Féminin', value: 'female' },
      ],
      tags: undefined,
      userTaggedList: undefined,
      userTaggedMap: new Map(),
      user: undefined,
      userClub: undefined,
      userHash: undefined,
    }
  },
  methods: {
    acceptUser: function() {
      this.userClub.isVerified = true;
      this.updateUserClub(false, 1);
    },
    deleteBookRight: function(book, confirm) {
      if (!confirm) {

        this.$bvModal.msgBoxConfirm('Voulez-vous enlever l\'adhérent du registre ' + book.name + ' ?' , { okVariant: 'success', okTitle: 'Oui', cancelTitle: 'Non', hideHeaderClose: true, centered: true, noFade: true }).then((value) => {
            if (value) {
              this.deleteBookRight(book, true);
            } else {
              book.newRight = book.right;
            }
          });
        return;
      }
      this.$parent.refresh = true;

      var json = {
        userId: this.user.id,
        bookId: book.id,
       };

      axios.post("/api/book-user-right/delete-user-right/", json)
      .then(() => {
        this.$parent.refresh = false;
      }).catch(error => {
        book.newRight = book.right;
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
    deleteUserClub: function(confirm, messageType) {
      let message = 'Voulez-vous enlever l\'adhérent ' + this.user.firstname + ' ' + this.user.lastname + ' de votre asso ?';
      if (messageType == 1) {
        message = 'Voulez-vous refuser la demande de l\'adhérent ' + this.user.firstname + ' ' + this.user.lastname + ' ?';
      }

      if (!confirm) {
        this.$bvModal.msgBoxConfirm(message , { okVariant: 'success', okTitle: 'Oui', cancelTitle: 'Non', hideHeaderClose: true, centered: true, noFade: true }).then((value) => {
            if (value) {
              this.deleteUserClub(true, messageType);
            }
          });
        return;
      }
      this.$parent.refresh = true;

      var json = {
        userId: this.user.id,
        clubId: this.club.id,
       };

      let apiUrl = "/api/user-club/delete-user-club/"
      if (this.user.isFake) {
        apiUrl = "/api/user/delete-fake-user/"
      }

      axios.post(apiUrl, json)
      .then(() => {
          let message = 'L\'adhérent a été enlevé avec succès.';
          if (messageType == 1) {
            message = 'La demande a été refusée.'
          }
          this.$bvModal.msgBoxOk(message, { centered: true, noFade: true }).then(() => {
            this.$router.push('/club-admin-users.html?clubId=' + this.club.id);
            this.$parent.refresh = false;
          });
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
    getBookUserRights: function() {
      return axios.get("/api/book-user-right/get-user-rights-for-club/" + this.userHash + "/" + this.club.id + "/");
    },
    processGetBookUserRightsResponse: function(response) {
      this.bookUserRights = response.data;

      this.bookUserRightsMap = new Map();
      for (let bookUserRightKey in this.bookUserRights) {
        let bookUserRight = this.bookUserRights[bookUserRightKey];
        this.bookUserRightsMap[bookUserRight.bookId] = bookUserRight;
      }
    },
    getClubBooks: function() {
      return axios.get("/api/book/get-club-books/" + this.clubId + "/");
    },
    processGetClubBooksResponse: function(response) {
      this.books = response.data;

        for (let bookKey in this.books) {
          let book = this.books[bookKey];
          this.booksMap[book.id] = book;
          if (this.bookUserRightsMap[book.id]) {
            this.$set(book, 'right', this.bookUserRightsMap[book.id].right);
            this.$set(book, 'newRight', this.bookUserRightsMap[book.id].right);
          } else {
            this.$set(book, 'right', -100);
            this.$set(book, 'newRight', -100);
          }
        }

        this.books.sort(function(a, b){
          if(a.name < b.name) { return -1; }
          if(a.name > b.name) { return 1; }
          return 0;
        });
    },
    getTags: function() {
      return axios.get("/api/club-user-tag/get-club-user-tags/" + this.clubId + "/");
    },
    processGetTagsResponse: function(response) {
      this.tags = response.data;

      for (let tag of this.tags) {
        if (this.userTaggedMap[tag.id] != undefined) {
          this.$set(tag, "checked", true);
        } else {
          this.$set(tag, "checked", false);
        }
      }
    },
    getUser: function() {
      return axios.get("/api/user/get-user/" + this.userHash + "/");
    },
    getUserTaggedList: function() {
      return axios.get("/api/club-user-tagged/get-club-user-tagged-list/" + this.userHash + "/" + this.clubId + "/");
    },
    processGetUserTaggedListResponse: function(response) {
      this.userTaggedList = response.data;

      this.userTaggedMap = new Map();
      for (let userTagged of this.userTaggedList) {
        this.userTaggedMap[userTagged.tagId] = userTagged;
      }
    },
    processGetUserResponse: function(response) {
      this.user = response.data;
    },
    getUserClub: function() {
      return axios.get("/api/user-club/get-user-club/" + this.userHash + "/" + this.club.id + "/");
    },
    processGetUserClubResponse: function(response) {
      this.userClub = response.data;
    },
    updateBookRight: function(book, confirm) {
      if (book.newRight == -100) {
        this.deleteBookRight(book, confirm);
        return;
      }

      if (!confirm) {
        this.$bvModal.msgBoxConfirm('Voulez-vous mettre à jour les droits de l\'adhérent ' + this.user.firstname + ' ' + this.user.lastname + ' sur le registre ' + book.name + ' ?' , { okVariant: 'success', okTitle: 'Oui', cancelTitle: 'Non', hideHeaderClose: true, centered: true, noFade: true }).then((value) => {
            if (value) {
              this.updateBookRight(book, true);
            } else {
              book.newRight = book.right;
            }
          });
        return;
      }
      this.$parent.refresh = true;

      var json = {
        userId:this.user.id,
        right: book.newRight,
        bookId: book.id,
       };

      axios.post("/api/book-user-right/update-user-right/", json)
      .then(() => {
        this.$parent.refresh = false;
      }).catch(error => {
        book.newRight = book.right;
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
    updateTag: function(tag) {
      this.$parent.refresh = true;
      var json = {
        userId: this.user.id,
        tagId: tag.id,
        clubId: this.club.id,
       };
      if (tag.checked) {
        axios.post("/api/club-user-tagged/add-tag-to-user/", json)
        .then(() => {
          this.$parent.refresh = false;
        }).catch(error => {
          this.$parent.displayErrorMessage(error);
          this.$parent.refresh = false;
        });
      } else {
        axios.post("/api/club-user-tagged/delete-tag-from-user/", json)
        .then(() => {
          this.$parent.refresh = false;
        }).catch(error => {
          this.$parent.displayErrorMessage(error);
          this.$parent.refresh = false;
        });
      }
    },
    updateUserClub: function(confirm, messageType) {
      if (!confirm) {
        let message = 'Voulez-vous mettre à jour l\'adhérent ' + this.user.firstname + ' ' + this.user.lastname + ' ?';
        if (messageType == 1) {
          message = 'Voulez-vous ajouter l\'adhérent ' + this.user.firstname + ' ' + this.user.lastname + ' à votre asso ?';
        }
        this.$bvModal.msgBoxConfirm(message , { okVariant: 'success', okTitle: 'Oui', cancelTitle: 'Non', hideHeaderClose: true, centered: true, noFade: true }).then((value) => {
            if (value) {
              this.updateUserClub(true, messageType);
            }
          });
        return;
      }
      this.$parent.refresh = true;

      var json1 = {
        userId: this.user.id,
        clubId: this.club.id,
        licenceNumber: this.userClub.licenceNumber,
        isAdmin: this.userClub.isAdmin,
        isVerified: this.userClub.isVerified,
       };

      let ps = [];
      let p1 = axios.post("/api/user-club/update-user-club/", json1)
      ps.push(p1);

      if (this.user.isFake) {
        var json2 = {
          userId: this.user.id,
          clubId: this.club.id,
          firstname: this.user.firstname,
          lastname: this.user.lastname,
          birthdate: this.user.birthdate,
          phoneNumber: this.user.phoneNumber,
          sex: this.user.sex,
         };

        let p2 = axios.post("/api/user/update-fake-user/", json2)
        ps.push(p2);
      }

      Promise.all(ps).then(() => {
        this.getUser().then(response=>{
          this.processGetUserResponse(response);
          if (messageType == 1) {
            this.$bvModal.msgBoxOk("L'adhérent a été ajouté à votre asso.", { centered: true, noFade: true });
          } else {
            this.$bvModal.msgBoxOk("L'adhérent a été mis à jour avec succès.", { centered: true, noFade: true });
          }
          this.$parent.refresh = false;
        });
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
  },
  beforeMount() {
    this.$parent.init();
    this.userHash = this.$route.query.userhash;
    this.clubId = this.$route.query.clubId;
    this.club = this.$parent.myUserClubMap[this.clubId].club;

    let p1 = this.getUser();
    let p2 = this.getUserClub();
    let p3 = this.getBookUserRights();
    let p4 = this.getClubBooks();
    let p5 = this.getUserTaggedList();
    let p6 = this.getTags();
    Promise.all([p1, p2, p3, p4, p5, p6]).then((responses) => {
      this.processGetUserResponse(responses[0]);
      this.processGetUserClubResponse(responses[1]);
      this.processGetBookUserRightsResponse(responses[2]);
      this.processGetClubBooksResponse(responses[3]);
      this.processGetUserTaggedListResponse(responses[4]);
      this.processGetTagsResponse(responses[5]);
      document.title = this.$parent.websiteName + ' - ' + this.user.firstname + ' ' + this.user.lastname;
      this.$parent.loaded = true;
    }).catch((error)=>{
      console.log(error);
      // this.$router.push('/error.html');
    });
  }
}
</script>
