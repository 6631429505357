<template>
<div class="container-fluid flex-fill" v-if="$parent.loaded">

  <b-breadcrumb>
    <b-breadcrumb-item to="/"><b-img class="mb-1" width="24" height="24" src="/static/images/logo.png" alt="logo"></b-img></b-breadcrumb-item>
    <b-breadcrumb-item :to="'/club-dashboard.html?clubId=' + page.clubId" ><office-building-icon/></b-breadcrumb-item>
    <b-breadcrumb-item active class="font-weight-bold">{{ page.title }}</b-breadcrumb-item>
  </b-breadcrumb>

  <div v-html="page.text" class="inner-html text-break"></div>

</div>
</template>

<script>
import axios from 'axios'

export default {
  data: function () {
    return {
      page: undefined,
      pageId: undefined,
    }
  },
  methods: {
    getClubPage: function() {
      return axios.get("/api/club-page/get-club-page/" + this.pageId + "/");
    },
    processGetClubPageResponse: function(response) {
      this.page = response.data;
    },
  },
  beforeMount() {
    this.$parent.init();
    this.pageId = this.$route.query.pageId;

    let p1 = this.getClubPage();
    Promise.all([p1]).then((responses) => {
      this.processGetClubPageResponse(responses[0]);
      document.title = this.$parent.websiteName + " - " + this.page.title;
      this.$parent.loaded = true;
    }).catch((error)=>{
      console.log(error);
      //this.$router.push('/error.html');
    });

  }
}
</script>
