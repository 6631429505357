<template>
<div class="container-fluid flex-fill" v-if="$parent.loaded">

  <b-breadcrumb>
    <b-breadcrumb-item to="/admin/"><cog-icon/></b-breadcrumb-item>
    <b-breadcrumb-item active class="font-weight-bold">Liste des domaines</b-breadcrumb-item>
  </b-breadcrumb>

  <b-row class="text-center bg-dark text-white font-weight-bold mt-3 mb-2 ml-0 mr-0 align-items-center">
    <b-col sm="1" class="align-center">ID</b-col>
    <b-col sm="10">Nom</b-col>
    <b-col sm="1">Actions</b-col>
  </b-row>

  <b-row class="mb-2 mr-0 ml-0">
    <b-col sm="1" class="align-center"></b-col>
    <b-col sm="10"><b-form-input v-model="domainName" type="text"></b-form-input></b-col>
    <b-col sm="1" class="text-center"><b><b-button v-on:click="addDomain()"><font-awesome-icon :icon="['fa', 'plus']"/></b-button></b></b-col>
  </b-row>

  <b-row class="mb-2 mr-0 ml-0" :class="{'even-line-background' : domainIndex % 2 == 0}" align-v="center" v-for="(domain, domainIndex) in $parent.domains" :key="domainIndex">
    <b-col sm="1" class="text-center"><b>{{ domain.id }}</b></b-col>
    <b-col sm="10"><b-form-input v-model="domain.name" type="text"></b-form-input></b-col>
    <b-col sm="1" class="text-center"><a href="#" v-on:click="updateDomain(domain)"><font-awesome-icon :icon="['fa', 'edit']"/></a></b-col>
  </b-row>

</div>
</template>

<script>
import axios from 'axios'

export default {
  data: function () {
    return {
      domainName: '',
    }
  },
  methods: {
    addDomain: function() {
      this.$parent.refresh = true;
      var json = {
          name: this.domainName,
       };

      axios.post("/api/domain/add-domain/", json)
      .then(response => {
        if(response.status == 200) {
          this.$parent.getDomains().then(response => {
            this.$parent.processGetDomainsResponse(response);
            this.domainName = '';
            this.$parent.refresh = false;
          });
        }
      }).catch(error => {
        if(error.response.status == 400) {
          this.$bvModal.msgBoxOk("Une erreur est survenue. Code erreur " + error.response.data.code, { centered: true, noFade: true });
        } else {
          this.$bvModal.msgBoxOk("Une erreur est survenue. Code HTTP " + error.response.status, { centered: true, noFade: true });
        }
        this.$parent.refresh = false;
      });
    },
    updateDomain: function(domain) {
      this.$parent.refresh = true;
      var json = {
          id: domain.id,
          name: domain.name,
       };

      axios.post("/api/domain/update-domain/", json)
      .then(response => {
        if(response.status == 200) {
          this.$parent.getDomains().then(response => {
            this.$parent.processGetDomainsResponse(response);
            this.$parent.refresh = false;
          });
          return;
        }
      }).catch(error => {
        if(error.response.status == 400) {
          this.$bvModal.msgBoxOk("Une erreur est survenue. Code erreur " + error.response.data.code, { centered: true, noFade: true });
        } else {
          this.$bvModal.msgBoxOk("Une erreur est survenue. Code HTTP " + error.response.status, { centered: true, noFade: true });
        }
        this.$parent.refresh = false;
      });
    }
  },
  beforeMount() {
    this.$parent.init();
    this.$parent.inAdmin = true;
    document.title = this.$parent.websiteName + " - Administration Domaines";
    this.$parent.loaded = true;
  }
}
</script>
