<template>
<div  class="flex-fill d-flex h-100" style="flex-direction: column" v-if="$parent.loaded">

  <div class="m-3">
    <b-breadcrumb>
      <b-breadcrumb-item to="/"><b-img class="mb-1" width="32" height="32" src="/static/images/logo.png" alt="logo"></b-img></b-breadcrumb-item>
      <b-breadcrumb-item :to="'/club-dashboard.html?clubId=' + groupTalk.clubId" ><office-building-icon/></b-breadcrumb-item>
      <b-breadcrumb-item active class="font-weight-bold"><message-icon/> {{ groupTalk.name }}</b-breadcrumb-item>
    </b-breadcrumb>
  </div>

  <div ref="messageListContainer" class="flex-fill ml-3">
    <div ref="messageList" id="message-list" class="group-talk-message-list pr-3">
      <div class="col-12 text-center bg-light c-hand h1" style="border-radius: 5px 5px 5px 5px;" v-on:click="loadPreviousPage()" v-if="groupTalkMessages.length % messageSliceSize == 0 && lastFirstGroupTalkMessageId != 0">
        <arrow-up-icon/>
      </div>

      <div v-for="(groupTalkMessage, groupTalkMessageIndex) in groupTalkMessages" :key="groupTalkMessageIndex">

        <div class="mb-2" v-if="groupTalkMessage.user.id == $parent.userSession.user.id">
        <div class="group-talk-message group-talk-message-mine">
          <div v-html="groupTalkMessage.text" class="text-break"></div>
        </div>
          <div class="small text-muted text-right mr-2">{{ groupTalkMessage.creationDate }}</div>
        </div>

        <div class="mb-2" v-if="groupTalkMessage.user.id != $parent.userSession.user.id">
          <div class="group-talk-message group-talk-message-other">
            <div>
              <span class="font-weight-bold">{{ groupTalkMessage.user.firstname }} {{ groupTalkMessage.user.lastname }}</span>
              <span><router-link class="text-dark ml-2" title="message privé" :to="'/private-message.html?userHash=' + groupTalkMessage.user.hash"><message-outline-icon/></router-link></span>
            </div>
            <hr class="mt-1 mb-1">
            <div v-html="groupTalkMessage.text" class="text-break"></div>
          </div>
          <div class="small text-muted text-left ml-2">{{ groupTalkMessage.creationDate }}</div>
        </div>

      </div>
      <div id="bottom-messages"></div>
    </div>
  </div>
  <div class="m-3">
    <div style="display: flex" class="mt-0">
      <div style="display: flex; width: 100%">
        <b-form-textarea style="background-color: #DDDDDD;" id="new-message" v-model="newMessage" rows="3" max-rows="3"></b-form-textarea>
      </div>
      <div style="display: flex" class="ml-2">
        <b-button variant="success" size="lg" v-on:click="addGroupTalkMessage()" :disabled="newMessage.length == 0"><send-icon/></b-button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'

export default {
  data: function () {
    return {
      groupTalk: undefined,
      groupTalkId: undefined,
      groupTalkMessages: [],
      newMessage: '',
      messageSliceSize: 100,
      lastFirstGroupTalkMessageId: 0,
    }
  },
  methods: {
    addGroupTalkMessage: function() {
      if (this.newMessage.length == 0) {
        return;
      }
      this.$parent.refresh = true;
      let newMessageHtml = '<p>' + this.newMessage.replaceAll('\n', '</p><p>') + '</p>';

      var json = {
        groupTalkId: this.groupTalk.id,
        text: newMessageHtml,
        userId: this.$parent.userSession.user.id,
       };

      axios.post("/api/group-talk-message/add-group-talk-message/", json)
      .then(() => {
        this.lastFirstGroupTalkMessageId = 0;
        this.groupTalkMessages = [];
        this.getGroupTalkMessages().then(response => {
          this.processGetGroupTalkMessagesResponse(response);
          this.newMessage = '';
          this.$parent.refresh = false;
          this.$nextTick(() => {
            this.scrollToBottomMessages();
          });
        });
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
    getGroupTalk: function() {
      return axios.get("/api/group-talk/get-group-talk/" + this.groupTalkId + "/");
    },
    processGetGroupTalkResponse: function(response) {
      this.groupTalk = response.data;
    },
    getGroupTalkMessages: function() {
      return axios.get("/api/group-talk-message/get-group-talk-messages/" + this.groupTalkId + "/" + this.lastFirstGroupTalkMessageId + "/");
    },
    processGetGroupTalkMessagesResponse: function(response) {
      let messages = response.data;

      for (let messageKey in this.groupTalkMessages) {
        messages.push(this.groupTalkMessages[messageKey]);
      }

      this.groupTalkMessages = messages;

      if (this.groupTalkMessages.length > 0 && this.lastFirstGroupTalkMessageId == 0 && this.groupTalkMessages[this.groupTalkMessages.length - 1].id != this.$parent.lastGroupTalkMessageIdMap.get(''+this.groupTalk.id)) {
        var json = {
          groupTalkId: this.groupTalkId,
          groupTalkMessageId: this.groupTalkMessages[this.groupTalkMessages.length - 1].id,
         };

        axios.post("/api/group-talk/update-last-read-group-talk-message-id/", json);
      }
    },
    loadPreviousPage: function() {
      this.$parent.refresh = true;
      this.lastFirstGroupTalkMessageId = this.groupTalkMessages[0].id;
      this.getGroupTalkMessages().then(response => {
        this.processGetGroupTalkMessagesResponse(response);
        this.$parent.refresh = false;
      });
    },
    scrollToBottomMessages: function() {
      window.document.getElementById('bottom-messages').scrollIntoView();
    }
  },
  mounted() {
    const interval = setInterval(() => {
      if (this.$refs.messageListContainer && this.$refs.messageList) {
        let messageList = window.document.getElementById('message-list');
        messageList.style.height = this.$refs.messageListContainer.clientHeight + 'px';
        this.scrollToBottomMessages();
        clearInterval(interval)
      }
    }, 50)
  },
  beforeMount() {
    this.$parent.init();
    this.groupTalkId = this.$route.query.groupTalkId;

    let p1 = this.getGroupTalk();
    let p2 = this.getGroupTalkMessages();
    Promise.all([p1, p2]).then((responses) => {
      this.processGetGroupTalkResponse(responses[0]);
      this.processGetGroupTalkMessagesResponse(responses[1]);
      document.title = this.$parent.websiteName + " - Salon " + this.groupTalk.name;

      this.$parent.loaded = true;
    }).catch((error)=>{
      console.log(error);
      //this.$router.push('/error.html');
    });
  }
}
</script>
