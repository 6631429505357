<template>
<div class="container-fluid flex-fill" v-if="$parent.loaded">

  <h1 class="text-uppercase">{{ club.name }}</h1>
  <b-row>
    <b-col md="4">
      <div>{{ club.address }}<br/>{{ club.zipCode }} {{ club.city }}</div>
      <div v-if="club.phoneNumber">
        <phone-classic-icon/> {{ club.phoneNumber }}
      </div>
    </b-col>
    <b-col md="4">
      <div class="inner-html" v-html="club.hours"></div>
    </b-col>
    <b-col md="4">
      <div class="inner-html" v-html="club.description"></div>
    </b-col>
  </b-row>

  <b-card class="mt-4" no-body>
    <b-tabs card>
      <b-tab>
        <template v-slot:title>
          <newspaper-icon/><span class="d-none d-md-inline"> Actualités</span>
        </template>
        <div class="alert alert-secondary mb-0 text-center h5" role="alert" v-if="newsList.length == 0">
          Aucune actualité
        </div>
        <b-card-group columns v-if="newsList.length >= 0">
          <b-card bg-variant="light" v-for="(news, newsIndex) in newsList" :key="newsIndex">
            <b-card-body>
              <b-card-text>
                <div class="inner-html" v-html="news.text"></div>
              </b-card-text>
              <b-card-text class="text-right small text-muted">
                <span>{{ news.creationDate }}</span>
              </b-card-text>
            </b-card-body>
          </b-card>
        </b-card-group>
      </b-tab>

      <b-tab class="p-0" >
        <template v-slot:title>
          <text-box-icon/><span class="d-none d-md-inline"> Informations</span>
        </template>
        <div class="alert alert-secondary m-3 text-center h5" role="alert" v-if="publicPages.length == 0">
          Aucune information
        </div>

        <b-card no-body style="border: none" v-if="publicPages && publicPages.length > 0">
          <b-tabs pills card vertical>
            <b-tab :title="page.title" v-for="(page, pageIndex) in publicPages" :key="pageIndex">
              <b-card-text>
                <div class="inner-html" v-html="page.text"></div>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-tab>

    </b-tabs>
  </b-card>

  <div class="mt-4 small text-center">page générée par <router-link to="/">{{ $parent.websiteName }}</router-link></div>

</div>
</template>

<script>
import axios from 'axios'

export default {
  data: function () {
    return {
      club: undefined,
      clubId: undefined,
      newsList: [],
      publicPages: undefined,
    }
  },
  methods: {
    getClub: function() {
      return axios.get("/api/club/get-club/" + this.clubId + "/");
    },
    processGetClubResponse: function(response) {
      this.club = response.data;
    },
    getPublicNews: function() {
      return axios.get("/api/club-news/get-public-club-news/" + this.clubId + "/");
    },
    processGetPublicNewsResponse: function(response) {
      this.newsList = response.data;
    },
    getPublicPages: function() {
      return axios.get("/api/club-page/get-public-club-pages/" + this.clubId + "/");
    },
    processGetPublicPagesResponse: function(response) {
      this.publicPages = response.data;
    },
  },
  beforeMount() {
    this.$parent.init();
    this.$parent.showHeaderAndFooter = false;
    this.clubId = this.$route.query.clubId;

    let p1 = this.getClub();
    let p2 = this.getPublicNews();
    let p3 = this.getPublicPages();
    Promise.all([p1, p2, p3]).then((responses) => {
      this.processGetClubResponse(responses[0]);
      this.processGetPublicNewsResponse(responses[1]);
      this.processGetPublicPagesResponse(responses[2]);
      document.title = this.club.name;

      this.$parent.loaded = true;
    }).catch((error)=>{
      console.log(error);
      //this.$router.push('/error.html');
    });
  }
}
</script>
