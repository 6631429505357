<template>
<div class="container-fluid flex-fill" v-if="$parent.loaded">

  <h1>Administration</h1>
  <p>En construction</p>

</div>
</template>

<script>
export default {
  data: function () {
    return {

    }
  },
  methods: {
  },
  beforeMount() {
    this.$parent.init();
    this.$parent.inAdmin = true;
    document.title = this.$parent.websiteName + " - Administration";
    this.$parent.loaded = true;
  }
}
</script>
