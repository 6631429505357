<template>
  <div class="container-fluid flex-fill" v-if="$parent.loaded">

    <b-breadcrumb>
      <b-breadcrumb-item to="/"><b-img class="mb-1" width="32" height="32" src="/static/images/logo.png" alt="logo"></b-img></b-breadcrumb-item>
      <b-breadcrumb-item active class="font-weight-bold">Oubli de mot de passe</b-breadcrumb-item>
    </b-breadcrumb>

    <b-input-group>
      <template #prepend>
          <b-input-group-text class="font-weight-bold">Email</b-input-group-text>
      </template>
      <b-form-input id="email" v-model="email" type="email" :state="isEmailValid()"></b-form-input>
      <template #append>
        <b-button id="activationCodeButton" v-on:click="sendActivationCode()" :variant="isEmailValid() ? 'success': 'secondary'" :disabled="!activationCodeEnabled || !isEmailValid()">Envoyer code activation</b-button>
      </template>
    </b-input-group>
    <b-form-text>Veuillez indiquer votre email. Puis cliquez sur le bouton <em>Envoyer code d'activation</em>. Un email vous sera envoyé à cette addresse avec un code que vous devez recopier dans le champ suivant.</b-form-text>

    <b-input-group class="mt-2">
      <template #prepend>
          <b-input-group-text class="font-weight-bold">Code d'activation</b-input-group-text>
      </template>
      <b-form-input id="activation-code" v-model="activationCode" type="text" :state="isActivationCodeValid()"></b-form-input>
    </b-input-group>
    <b-form-text>Renseignez le code d'activation qui vous a été envoyé.</b-form-text>

    <b-input-group class="mt-2">
      <template #prepend>
          <b-input-group-text class="font-weight-bold">Nouveau Mot de passe</b-input-group-text>
      </template>
      <b-form-input id="password" v-model="password" type="password" :state="isPasswordValid()"></b-form-input>
    </b-input-group>
    <b-form-text>Veuillez indiquer un nouveau mot de passe avec <b>8 caractères minimum</b>.</b-form-text>

    <b-input-group class="mt-2">
      <template #prepend>
          <b-input-group-text class="font-weight-bold">Confirmation mot de passe</b-input-group-text>
      </template>
      <b-form-input id="password-confirmation" v-model="passwordConfirmation" type="password" :state="isPasswordConfirmationValid()"></b-form-input>
    </b-input-group>
    <b-form-text>Veuillez confirmer votre nouveau mot de passe.</b-form-text>

    <captcha ref="captcha" class="mt-2"></captcha>

    <b-row class="text-center">
      <b-col sm="12">
        <b-button id="reset-password-button" v-on:click="resetPassword" :variant="isFormValid() ? 'success': 'secondary'" size="lg" :disabled="!isFormValid()">Réinitialiser le mot de passe</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from 'axios'
import captcha from '@/components/Captcha.vue'

export default {
  components: {
    captcha
  },
  data: function () {
    return {
      activationCode: '',
      activationCodeEnabled: true,
      email: '',
      password: '',
      passwordConfirmation: '',
    }
  },
  methods: {
    isEmailValid: function() {
        if (this.email.length == 0) return null;
        return this.$parent.emailRegexp.test(this.email);
    },
    isActivationCodeValid: function() {
      if (this.activationCode.length == 0) return null;
      return this.activationCode.length >= 4;
    },
    isFormValid: function() {
      return this.isEmailValid() && this.isPasswordValid() && this.isPasswordConfirmationValid() && this.isActivationCodeValid();
    },
    isPasswordValid: function() {
      if (this.password.length == 0) return null;
      return this.password.length >= 8;
    },
    isPasswordConfirmationValid: function() {
      if (this.passwordConfirmation.length == 0) return null;
      return this.passwordConfirmation.length >= 8 && this.password == this.passwordConfirmation;
    },
    resetPassword: function() {
      this.$parent.refresh = true;

      var json = {
        activationCode: this.activationCode,
        captchaCloud: this.$refs.captcha.captchaCloud,
        captchaTree: this.$refs.captcha.captchaTree,
        captchaFlower: this.$refs.captcha.captchaFlower,
        email: this.email,
        password: this.password,
       };

      axios.post("/api/user/reset-password/", json)
      .then(response => {
        if(response.status == 200) {
          this.$parent.createSession(response);
          let p1 = this.$parent.getMyUserClubs(this.$parent.userSession.user.hash);
          let p2 = this.$parent.getBookRights(this.$parent.userSession.user.hash);
          Promise.all([p1, p2]).then((responses) => {
            this.$parent.processGetMyUserClubsResponse(responses[0]);
            this.$parent.processGetBookRightsResponse(responses[1]);
            this.$parent.refresh = false;
            this.$router.push('/user-dashboard.html');
          });
          return;
        }
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
    sendActivationCode: function() {
      this.$parent.refresh = true;
      var json = {
          email: this.email
       };

      this.activationCodeEnabled = false;
      axios.post("/api/user/send-activation-code/", json)
      .then(response => {
        if(response.status == 200) {
          this.activationCodeEnabled = true;
          this.$bvModal.msgBoxOk("Un code d'activation a été envoyé sur votre adresse email. Veillez à vérifier votre dossier Spams/Courriers indésirables.", { id: 'activationCodeModal', centered: true, noFade: true });
        }

        this.$parent.refresh = false;
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
  },
  beforeMount() {
    this.$parent.init();

    document.title = this.$parent.websiteName + " - Oubli de mot de passe";

    this.$parent.loaded = true;
  }
}
</script>
