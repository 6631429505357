<template>
<div class="container-fluid flex-fill" v-if="$parent.loaded">

  <b-breadcrumb>
    <b-breadcrumb-item to="/"><b-img class="mb-1" width="32" height="32" src="/static/images/logo.png" alt="logo"></b-img></b-breadcrumb-item>
    <b-breadcrumb-item :to="'/club-dashboard.html?clubId=' + clubId" ><office-building-icon/></b-breadcrumb-item>
    <b-breadcrumb-item :to="'/club-forum-category.html?clubId=' + clubId + '&forumCategoryId=' + categoryId" ><forum-icon/></b-breadcrumb-item>
    <b-breadcrumb-item active class="font-weight-bold">Nouveau sujet</b-breadcrumb-item>
  </b-breadcrumb>

  <b-input-group>
    <template #prepend>
        <b-input-group-text class="font-weight-bold">Sujet</b-input-group-text>
    </template>
    <b-form-input id="thread-name" v-model="newThread.name" ></b-form-input>
  </b-input-group>

  <b-form-group class="mt-2 mb-0" label="Votre message" label-class="font-weight-bold">
    <quilleditor ref="quilleditor" id="message" v-model="newMessage.text"></quilleditor>
  </b-form-group>

  <div class="text-center mt-4">
    <b-button id="add-thread-button" v-on:click="addThread" :variant="isFormValid() ? 'success': 'secondary'" :disabled="!isFormValid()">Ajouter le sujet</b-button>
  </div>

</div>
</template>

<script>
import axios from 'axios'
import quilleditor from '@/components/QuillEditor.vue'

export default {
  components: {
    quilleditor
  },
  data: function () {
    return {
      category: undefined,
      categoryId: undefined,
      clubId: undefined,
      newThread: {name: ''},
      newMessage: {text: ''},
    }
  },
  methods: {
    addThread: function() {
      this.$parent.refresh = true;
      var json = {
        clubId: this.clubId,
        userId: this.$parent.userSession.user.id,
        categoryId: this.categoryId,
        name: this.newThread.name,
        message: this.newMessage.text,
       };

      axios.post("/api/club-forum-thread/add-club-forum-thread/", json)
      .then((response) => {
        let thread = response.data;
        this.$router.push('/club-forum-thread.html?forumCategoryId=' + this.categoryId + '&clubId=' + this.clubId + '&threadId=' + thread.id);
        this.$parent.refresh = false;
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
    isFormValid: function() {
      return this.newMessage.text && this.newMessage.text.length >= 2 && this.newMessage.text.length <= 2000 && this.newThread.name && this.newThread.name.length >= 5;
    },
    getForumCategory: function() {
      return axios.get("/api/club-forum-category/get-club-forum-category/" + this.clubId + "/" + this.categoryId + "/");
    },
    processGetForumCategoryResponse: function(response) {
      this.category = response.data;
    },
  },
  beforeMount() {
    this.$parent.init();
    this.categoryId = this.$route.query.forumCategoryId;
    this.clubId = this.$route.query.clubId;

    let p1 = this.getForumCategory();
    Promise.all([p1]).then((responses) => {
      this.processGetForumCategoryResponse(responses[0]);
      document.title = this.$parent.websiteName + " - Forum " + this.category.name + ' Nouveau sujet';
      this.$parent.loaded = true;
    }).catch((error)=>{
      console.log(error);
      //this.$router.push('/error.html');
    });
  }
}
</script>
