<template>
<div class="container-fluid flex-fill" v-if="$parent.loaded">

  <b-breadcrumb>
    <b-breadcrumb-item to="/"><b-img class="mb-1" width="32" height="32" src="/static/images/logo.png" alt="logo"></b-img></b-breadcrumb-item>
    <b-breadcrumb-item to="/domains.html"><domain-icon/></b-breadcrumb-item>
    <b-breadcrumb-item active class="font-weight-bold"><office-building-icon/> Liste des assos Discipline {{ $parent.domainsMap[this.domainId].name }}</b-breadcrumb-item>
  </b-breadcrumb>

  <b-card-group columns>
    <b-card bg-variant="light" v-for="(club, clubIndex) in clubs" :key="clubIndex">
      <template v-slot:header>
        <router-link :to="'/club-dashboard.html?clubId=' + club.id" :id="'my-club-' + clubIndex" v-if="$parent.myUserClubMap[club.id] && $parent.myUserClubMap[club.id].isVerified">{{ club.name }}</router-link>
        <span v-if="!$parent.myUserClubMap[club.id] || !$parent.myUserClubMap[club.id].isVerified">{{ club.name }}</span>
      </template>
      <b-card-text>
        <ul class="pl-3">
          <li>
            {{ club.address }} {{ club.zipCode }} {{ club.city }}
          </li>
          <li v-if="club.phoneNumber">
            <phone-classic-icon/> {{ club.phoneNumber }}
          </li>
          <li v-if="$parent.myUserClubMap[club.id]">
            <b>Status Inscription</b>:
            <span v-if="$parent.myUserClubMap[club.id].isVerified">vérifié <check-circle-icon class="text-success"/></span>
            <span v-if="!$parent.myUserClubMap[club.id].isVerified">en attente de vérification <clock-outline-icon class="text-secondary"/></span>
          </li>
        </ul>
        <div class="text-right">
          <router-link :to="'/club-public-page.html?clubId=' + club.id"><earth-icon/> voir la page publique</router-link>
        </div>
      </b-card-text>
    </b-card>
  </b-card-group>

</div>
</template>

<script>
import axios from 'axios'

export default {
  data: function () {
    return {
      clubs: [],
      domainId: undefined,
    }
  },
  methods: {
    getClubs: function() {
      return axios.get("/api/club/get-clubs/" + this.domainId + "/");
    },
    processGetClubsResponse: function(response) {
      this.clubs = response.data;
    },
  },
  beforeMount() {
    this.$parent.init();
    this.domainId = this.$route.query.domainId;

    let p1 = this.getClubs();
    Promise.all([p1]).then((responses) => {
      this.processGetClubsResponse(responses[0]);
      document.title = this.$parent.websiteName + " - Assos " + this.$parent.domainsMap[this.domainId].name;

      this.$parent.loaded = true;
    }).catch((error)=>{
      console.log(error);
      //this.$router.push('/error.html');
    });
  }
}
</script>
