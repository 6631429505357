<template>
<div class="container-fluid flex-fill" v-if="$parent.loaded">

  <b-breadcrumb>
    <b-breadcrumb-item to="/"><b-img class="mb-1" width="32" height="32" src="/static/images/logo.png" alt="logo"></b-img></b-breadcrumb-item>
    <b-breadcrumb-item :to="'/club-dashboard.html?clubId=' + clubId" ><office-building-icon/></b-breadcrumb-item>
    <b-breadcrumb-item active class="font-weight-bold">Les salons de discussion</b-breadcrumb-item>
  </b-breadcrumb>

  <b-card no-body>
    <b-tabs card>
      <b-tab>
        <template v-slot:title>
          <message-icon/> Les salons
        </template>

        <div class="alert alert-secondary mb-0 text-center h5" role="alert" v-if="groupTalks.length == 0">
          Aucun salon trouvée.
        </div>

        <div v-if="groupTalks.length > 0"><b-pagination v-model="currentPage" :total-rows="groupTalks.length" :per-page="numberOfRowsPerPage" first-number align="center"></b-pagination></div>

        <b-row align-v="center" :class="{'even-line-background' : groupTalkIndex % 2 == 1}" class="p-2" v-for="(groupTalk, groupTalkIndex) in groupTalks.slice((currentPage - 1) * numberOfRowsPerPage, (currentPage - 1) * numberOfRowsPerPage + numberOfRowsPerPage)" :key="groupTalkIndex">
          <b-col cols="10">
            <b-form-group class="mt-2 mb-0" label-class="font-weight-bold">
              <b-form-input :id="'group-talk-text-' + groupTalkIndex" v-model="groupTalk.name" type="text"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="2" class="text-right">
            <span class="ml-2 c-hand h3 text-primary" v-on:click="updateGroupTalk(groupTalk);"><content-save-icon/></span>
            <span class="ml-2 c-hand h3 text-danger" v-on:click="deleteGroupTalk(groupTalk.id, false);"><delete-icon/></span>
          </b-col>
        </b-row>
      </b-tab>

      <b-tab>
        <template v-slot:title>
          <plus-circle-outline-icon/> Ajout d'un salon
        </template>

        <b-form-group class="mt-2 mb-0" label-class="font-weight-bold">
          <b-form-input id="new-group-talk" v-model="newGroupTalk.name" type="text"></b-form-input>
        </b-form-group>

        <b-row class="text-center mt-4">
          <b-col sm="12">
            <b-button id="add-group-talk-button" v-on:click="addGroupTalk">Ajouter le salon</b-button>
          </b-col>
        </b-row>

      </b-tab>
    </b-tabs>
  </b-card>
</div>
</template>

<script>
import axios from 'axios'

export default {
  data: function () {
    return {
      groupTalks: [],
      club: undefined,
      clubId: undefined,
      newGroupTalk: {name: ''},
      numberOfRowsPerPage: 10,
      currentPage: 1,
    }
  },
  methods: {
    addGroupTalk: function() {
      this.$parent.refresh = true;
      var json = {
        clubId: this.club.id,
        name: this.newGroupTalk.name,
        userId: this.$parent.userSession.user.id,
       };

      axios.post("/api/group-talk/add-group-talk/", json)
      .then(() => {
        this.getGroupTalk().then(response => {
          this.processGetGroupTalkResponse(response);
          this.$bvModal.msgBoxOk("Le salon a été ajoutée.", { centered: true, noFade: true });
          this.newGroupTalk = {text: ''}
          this.$parent.refresh = false;
        });
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
    deleteGroupTalk: function(groupTalkId, confirm) {
      if (!confirm) {

        this.$bvModal.msgBoxConfirm('Voulez-vous supprimer le salon ?' , { okVariant: 'success', okTitle: 'Oui', cancelTitle: 'Non', hideHeaderClose: true, centered: true, noFade: true }).then((value) => {
            if (value) {
              this.deleteGroupTalk(groupTalkId, true);
            }
          });
        return;
      }
      this.$parent.refresh = true;

      var json = {
        groupTalkId: groupTalkId,
       };

      axios.post("/api/group-talk/delete-group-talk/", json)
      .then(() => {
        this.getGroupTalk().then(response => {
          this.processGetGroupTalkResponse(response);
          this.$bvModal.msgBoxOk("Le salon a été supprimé.", { centered: true, noFade: true });
          this.$parent.refresh = false;
        });
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
    getGroupTalk: function() {
      return axios.get("/api/group-talk/get-group-talks/" + this.clubId + "/");
    },
    processGetGroupTalkResponse: function(response) {
      this.groupTalks = response.data;
    },
    updateGroupTalk: function(groupTalk) {
      this.$parent.refresh = true;
      var json = {
        groupTalkId: groupTalk.id,
        name: groupTalk.name,
       };

      axios.post("/api/group-talk/update-group-talk/", json)
      .then(() => {
        this.getGroupTalk().then(response => {
          this.processGetGroupTalkResponse(response);
          this.$parent.refresh = false;
        });
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
  },
  beforeMount() {
    this.$parent.init();
    this.clubId = this.$route.query.clubId;
    this.club = this.$parent.myUserClubMap[this.clubId].club;

    let p1 = this.getGroupTalk();
    Promise.all([p1]).then((responses) => {
      this.processGetGroupTalkResponse(responses[0]);
      document.title = this.$parent.websiteName + " - Gestion des salons de discussion de l'asso " + this.club.name;
      this.$parent.loaded = true;
    }).catch((error)=>{
      console.log(error);
      //this.$router.push('/error.html');
    });
  }
}
</script>
