<template>
<div class="container-fluid flex-fill" v-if="$parent.loaded">

  <b-breadcrumb>
    <b-breadcrumb-item to="/"><b-img class="mb-1" width="32" height="32" src="/static/images/logo.png" alt="logo"></b-img></b-breadcrumb-item>
    <b-breadcrumb-item active class="font-weight-bold">Nous contacter</b-breadcrumb-item>
  </b-breadcrumb>

  <b-input-group class="mt-2">
    <template #prepend>
        <b-input-group-text class="font-weight-bold">Votre email</b-input-group-text>
    </template>
    <b-form-input id="email" v-model="email" type="text" :state="isEmailValid()"></b-form-input>
  </b-input-group>

  <b-input-group class="mt-2">
    <template #prepend>
        <b-input-group-text class="font-weight-bold">Sujet</b-input-group-text>
    </template>
    <b-form-select id="subject" v-model="subject" :options="subjectOptions" :state="isSubjectValid()"></b-form-select>
  </b-input-group>

  <b-form-group label="Description" class="mt-2 mb-0" label-class="font-weight-bold">
    <quilleditor ref="quilleditor" id="message" v-model="message" :state="isMessageValid()"></quilleditor>
  </b-form-group>
  <b-form-text></b-form-text>

  <captcha ref="captcha" class="mt-2" v-if="!$parent.userSession"></captcha>

  <b-row class="text-center mt-4">
    <b-col sm="12">
      <b-button id="contact-button" v-on:click="sendMessage" :variant="isFormValid() ? 'success': 'secondary'" size="lg" :disabled="!isFormValid()">
        <email-outline-icon/> Envoyer le message</b-button>
    </b-col>
  </b-row>

</div>
</template>

<script>
import axios from 'axios'
import captcha from '@/components/Captcha.vue'
import quilleditor from '@/components/QuillEditor.vue'

export default {
  components: {
    captcha,
    quilleditor
  },
  data: function () {
    return {
      email: undefined,
      message: undefined,
      subject: undefined,
      subjectOptions: [
        'Mon club ou ma discipline n\'apparaît pas',
        'je ne comprends pas comment faire',
        'J\'ai trouvé un bug',
        'Je souhaiterai une nouvelle fonctionnalité',
        'Ma demande concerne les dons',
        'Autre demande',
      ]
    }
  },
  methods: {

test1: function() {
  this.message = '<p>coucoucoucoucou</p>';
},
test2: function() {
  this.message = '';
},
    isEmailValid: function() {
        if (this.email == undefined) return null;
        return this.$parent.emailRegexp.test(this.email);
    },
    isFormValid: function() {
      return this.isEmailValid() && this.isMessageValid() && this.isSubjectValid();
    },
    isMessageValid: function() {
      if (this.message == undefined) return null;
      return this.message.length >= 2 && this.message.length <= 10000;
    },
    isSubjectValid: function() {
      if (this.subject == undefined) return null;
      return this.subject.length > 0 && this.subject.length <= 200;
    },
    sendMessage: function() {
      this.$parent.refresh = true;
      let json = {
        email: this.email,
        message: this.message,
        subject: this.subject,
       };

      if (!this.$parent.userSession) {
        json.captchaCloud = this.$refs.captcha.captchaCloud;
        json.captchaTree = this.$refs.captcha.captchaTree;
        json.captchaFlower = this.$refs.captcha.captchaFlower;
      }

      axios.post("/api/contact-us/send-message/", json)
      .then(() => {
        this.email = undefined;
        this.message = undefined;
        this.subject = undefined;

        this.$bvModal.msgBoxOk("Votre message a bien été envoyé. Nous tâcherons d'y répondre dans les plus brefs délais.", { centered: true, noFade: true });

        this.$parent.refresh = false;
      }).catch(error => {

        if(error.response.status == 400) {
          var errorData = error.response.data;
          if (errorData.code == 1001) {
            this.$bvModal.msgBoxOk("Le captcha est invalide. Veuillez cliquer sur le captcha pour le rafraîchir et entrer à nouveau les éléments.", { centered: true, noFade: true });
            this.$refs.captcha.refreshCaptcha();
          } else {
            this.$bvModal.msgBoxOk("Une erreur est survenue. Code erreur " + error.response.data.code, { centered: true, noFade: true });
          }
        } else {
          this.$bvModal.msgBoxOk("Une erreur est survenue. Code HTTP " + error.response.status, { centered: true, noFade: true });
        }
        this.$parent.refresh = false;
      });
    },
  },
  beforeMount() {
    this.$parent.init();
    document.title = this.$parent.websiteName + " - Nous Contacter";

    this.$parent.loaded = true;
  }
}
</script>
