<script>
  import Quill from 'quill';
  import 'quill/dist/quill.core.css';
  import 'quill/dist/quill.snow.css';

  export default {
    props: {
      value: {
        type: String,
        default: ''
      },
    },
    watch: {
      value(value) {
        if (value != this.editor.root.innerHTML) {
          this.editor.root.innerHTML = value;
        }
      }
    },

    data() {
      return {
        editor: null
      };
    },
    mounted() {
      this.editor = new Quill(this.$refs.editor, {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike']
          ]
        },
        theme: 'snow',
        formats: ['bold', 'underline', 'header', 'italic', 'strike']
      });

      this.editor.root.innerHTML = this.value;
      this.editor.on('text-change', () => this.update());
    },

    methods: {
      update() {
        this.$emit('input', this.editor.getText() ? this.editor.root.innerHTML : '');
      }
    }
  }
</script>

<template>
  <div ref="editor"></div>
</template>
