<template>
<div class="container-fluid flex-fill" v-if="$parent.loaded">

  <b-breadcrumb>
    <b-breadcrumb-item to="/"><b-img class="mb-1" width="32" height="32" src="/static/images/logo.png" alt="logo"></b-img></b-breadcrumb-item>
    <b-breadcrumb-item :to="'/club-dashboard.html?clubId=' + equipment.clubId" ><office-building-icon/></b-breadcrumb-item>
    <b-breadcrumb-item :to="'/club-admin-equipment.html?clubId=' + equipment.clubId" ><package-variant-closed-icon/></b-breadcrumb-item>
    <b-breadcrumb-item active class="font-weight-bold">Mise à jour du matériel</b-breadcrumb-item>
  </b-breadcrumb>
  <b-input-group class="mt-2">
    <template #prepend>
        <b-input-group-text class="font-weight-bold">Nom</b-input-group-text>
    </template>
    <b-form-input id="equipment-name" v-model="equipment.name" type="text" :state="isNameValid()"></b-form-input>
  </b-input-group>
  <b-form-text>Le nom du matériel doit être suffisamment explicite pour le différencer d'un autre.</b-form-text>

  <b-form-group label="Description" class="mt-2 mb-0">
    <quilleditor ref="quilleditor" id="equipment-description" v-model="equipment.description"></quilleditor>
  </b-form-group>

  <b-input-group class="mt-2">
    <template #prepend>
        <b-input-group-text>Code EAN</b-input-group-text>
    </template>
    <b-form-input id="equipment-code1" v-model="equipment.code1" type="text" :state="isCode1Valid()"></b-form-input>
  </b-input-group>
  <b-form-text>Le code EAN sont utilisés par les fabricants européens pour identifier leur produit. C'est le code barre qu'on retrouve derrière les produits achetés en supermarchés.</b-form-text>

  <b-input-group class="mt-2">
    <template #prepend>
        <b-input-group-text>Code 128 ASCII</b-input-group-text>
    </template>
    <b-form-input id="equipment-code2" v-model="equipment.code2" type="text" :state="isCode2Valid()"></b-form-input>
  </b-input-group>
  <b-form-text>Le code 128 ASCII permet de générer un code barre contenant jusqu'à 128 caractères.</b-form-text>

  <b-input-group class="mt-2">
    <template #prepend>
        <b-input-group-text>Numéro de série</b-input-group-text>
    </template>
    <b-form-input id="equipment-serial-number" v-model="equipment.serialNumber" type="text"></b-form-input>
  </b-input-group>
  <b-form-text>Le numéro de série permet de différencier 2 matériels identiques.</b-form-text>

  <b-row class="text-center mt-4">
    <b-col sm="12">
      <b-button id="update-equipment-button" v-on:click="updateEquipment" :variant="isFormValid() ? 'success': 'secondary'" :disabled="!isFormValid()">Mettre à jour le matériel</b-button>
    </b-col>
  </b-row>
</div>
</template>

<script>
import axios from 'axios'
import quilleditor from '@/components/QuillEditor.vue'

export default {
  components: {
    quilleditor
  },
  data: function () {
    return {
      equipmentId: undefined,
      equipment: undefined,
    }
  },
  methods: {
    isCode1Valid: function() {
      if (!this.equipment.code1 || this.equipment.code1.length == 0) return true;
      return this.equipment.code1.length == 13 && !isNaN(this.equipment.code1);
    },
    isCode2Valid: function() {
      if (!this.equipment.code2 || this.equipment.code2.length == 0) return true;
      return this.equipment.code2.length <= 128;
    },
    isFormValid: function() {
      return this.isCode1Valid() && this.isCode2Valid() && this.isNameValid();
    },
    isNameValid: function() {
      if (this.equipment.name.length == 0) return null;
      return this.equipment.name.length > 0 && this.equipment.name.length <= 100;
    },
    getEquipment: function() {
      return axios.get("/api/equipment/get-equipment/" + this.equipmentId + "/");
    },
    processGetEquipmentResponse: function(response) {
      this.equipment = response.data;
    },
    updateEquipment: function() {
      this.$parent.refresh = true;
      var json = {
        id: this.equipment.id,
        code1: this.equipment.code1,
        code2: this.equipment.code2,
        description: this.equipment.description,
        name: this.equipment.name,
        serialNumber: this.equipment.serialNumber,
       };

      if (json.description != undefined && json.description.length == 0) {
        json.description = undefined;
      }

      if (json.code1 != undefined && json.code1.length == 0) {
        json.code1 = undefined;
      }

      if (json.code2 != undefined && json.code2.length == 0) {
        json.code2 = undefined;
      }

      if (json.serialNumber != undefined && json.serialNumber.length == 0) {
        json.serialNumber = undefined;
      }

      axios.post("/api/equipment/update-equipment/", json)
      .then(() => {
        this.getEquipment().then(response => {
          this.processGetEquipmentResponse(response);
          this.$bvModal.msgBoxOk("Le matériel a été mis à jour.", { centered: true, noFade: true });
          this.$parent.refresh = false;
        });
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
  },
  beforeMount() {
    this.$parent.init();
    this.equipmentId = this.$route.query.equipmentId;

    let p1 = this.getEquipment();
    Promise.all([p1]).then((responses) => {
      this.processGetEquipmentResponse(responses[0]);
      document.title = this.$parent.websiteName + " - Mise à jour du matériel " + this.equipment.name;
      this.$parent.loaded = true;
    }).catch((error)=>{
      console.log(error);
      //this.$router.push('/error.html');
    });
  }
}
</script>
