<template>
<div class="container-fluid flex-fill" v-if="$parent.loaded">

  <b-breadcrumb>
    <b-breadcrumb-item to="/"><b-img class="mb-1" width="32" height="32" src="/static/images/logo.png" alt="logo"></b-img></b-breadcrumb-item>
    <b-breadcrumb-item active class="font-weight-bold"><domain-icon/> Liste des disciplines</b-breadcrumb-item>
  </b-breadcrumb>

  <b-card-group columns>
    <b-card bg-variant="light" v-for="(domain, domainIndex) in $parent.domains" :key="domainIndex">
      <b-card-body>
        <b-card-text>
          <div class="font-weight-bold text-uppercase"><router-link class="text-dark" :to="'/clubs.html?domainId=' + domain.id">{{ domain.name }}</router-link></div>
          <div>{{ domain.description }}</div>
          <div class="small text-right"><router-link :to="'/clubs.html?domainId=' + domain.id">voir les assos</router-link></div>
        </b-card-text>
      </b-card-body>
    </b-card>
  </b-card-group>

</div>
</template>

<script>
export default {
  data: function () {
    return {

    }
  },
  methods: {
  },
  beforeMount() {
    this.$parent.init();
    document.title = this.$parent.websiteName + " - Liste des disciplines";

    this.$parent.loaded = true;
  }
}
</script>
