<template>
<div class="container-fluid flex-fill" v-if="$parent.loaded">

  <b-breadcrumb>
    <b-breadcrumb-item to="/"><b-img class="mb-1" width="32" height="32" src="/static/images/logo.png" alt="logo"></b-img></b-breadcrumb-item>
    <b-breadcrumb-item :to="'/club-dashboard.html?clubId=' + clubId"><office-building-icon/></b-breadcrumb-item>
    <b-breadcrumb-item active class="font-weight-bold"><book-icon/> Tous les registres</b-breadcrumb-item>
  </b-breadcrumb>

  <div class="alert alert-secondary mb-0" role="alert">
    Sélectionner les registres dont vous voulez voir les créneaux. Sur cette page, vous ne pouvez pas réserver. Chaque registre peut avoir des règles particulières. Cliquer sur le nom du registre, afin de connaître les règles de réservation et avoir la possibilité de réserver un créneau.
    <hr>
    <div class="text-center">
      <b-form-checkbox inline class="mr-2" switch v-model="hideOldSlots">Cacher les vieux créneaux</b-form-checkbox>
      <b-form-checkbox inline switch v-model="hideEmptySlots">Cacher les créneaux vides</b-form-checkbox>
    </div>
    <hr>
    <div class="text-center">
      <b-form-checkbox inline class="mr-2" switch v-model="book.selected" v-for="(book, bookIndex) in myBooks" :key="bookIndex">{{ book.name }}</b-form-checkbox>
    </div>
  </div>

  <div class="small text-muted text-center mt-2 mb-2">
    <span class="mr-2"><shield-account-outline-icon/> Administrateur</span>
    <span class="mr-2"><shield-star-outline-icon/> Modérateur</span>
    <span class="mr-2"><bookmark-outline-icon/> Responsable</span>
  </div>

  <div id="topPage"></div>

  <div class="row m-0 mb-2 alert alert-warning h5 text-uppercase font-weight-bold bg-warning">
    <div class="col-2 p-0 text-left align-middle h1 m-0">
      <arrow-left-circle-icon class="c-hand" v-on:click="loadPreviousMonth(false)"/>
    </div>
    <div class="col-8 text-center">
      <span>{{ monthNames[this.currentFirstDateOfMonth.format('M') - 1] }} {{ currentFirstDateOfMonth.format('YYYY') }} </span><br><span class="small"> {{ slots.length }} créneaux</span>
    </div>
    <div class="col-2 p-0 text-right align-middle h1 m-0">
      <arrow-right-circle-icon class="c-hand" v-on:click="loadNextMonth(false)"/>
    </div>
  </div>

  <div class="alert alert-secondary mb-0 text-center h5" role="alert" v-if="slotsFiltered.length == 0">
    Aucun créneau trouvé
  </div>

  <div class="row mb-1 text-secondary h1" v-if="slotSliceIndex > 0">
    <div class="col-12 text-center">
      <arrow-up-circle-icon class="c-hand" v-on:click="loadPreviousPage()"/>
    </div>
  </div>
  <div v-for="(slot, slotIndex) in slotsFiltered.slice(this.slotSliceIndex, this.slotSliceIndex + this.numberOfSlotsPerPage)" :key="slotIndex">
    <b-container fluid class="border mb-4">
      <b-row class="bg-dark mb-0 p-4 text-center">
        <b-col cols="12">
          <div class="font-weight-bold h5 text-uppercase"><router-link class="text-white" :to="'/book.html?hash=' + slot.bookHash"><book-icon/> {{ slot.bookName }}</router-link></div>
          <div class="font-weight-bold h5 text-uppercase text-white">
            <div class="mr-2 d-inline-block"><calendar-blank-icon/> {{ slot.prettyDate }}</div>
            <div class="d-inline-block"><clock-outline-icon/> {{ slot.prettyTime }}</div>
          </div>

          <div class="text-white">
            <span class="mr-2"><account-multiple-icon class="mr-1"/>
              <span class="font-weight-bold" v-if="slot.numberOfPlaces > slot.bookings.length">{{ slot.bookings.length }} / {{ slot.numberOfPlaces }}</span>
              <span class="text-danger font-weight-bold" v-if="slot.numberOfPlaces <= slot.bookings.length">COMPLET</span>
            </span>
            <span class="mr-2"><clock-outline-icon/> {{ slot.duration }} min</span>
            <span><router-link class="text-white" :to="'/book-slot-group-talk.html?bookHash=' + slot.bookHash +'&slotStartDate=' + slot.startDateUrlFormat + '&slotEndDate=' + slot.endDateUrlFormat" :class="{'text-warning': slot.numberOfMessages > 0}"><message-icon/> {{ slot.numberOfMessages }}</router-link></span>
          </div>
        </b-col>
      </b-row>
      <b-row class="h5 p-3 mb-0" align-v="start" :class="{'even-line-background' : bookingIndex % 2 == 1 && !booking.isMyBooking, 'bg-yellow-200': booking.isMyBooking}" v-for="(booking, bookingIndex) in slot.bookings" :key="bookingIndex">
        <b-col cols="12">
          <clock-outline-icon class="text-secondary mr-2" v-if="booking.status == -1" title="en liste d'attente"/>
          <circle-icon class="text-secondary mr-2" v-if="booking.status == 0" title="en attente de validation"/>
          <check-circle-icon class="text-success mr-2" v-if="booking.status == 1" title="validé"/>

          <span>{{ booking.user.firstname }} {{ booking.user.lastname }}</span>

          <span class="ml-2" v-if="booksRightsMap[slot.bookHash][booking.user.id] && booksRightsMap[slot.bookHash][booking.user.id].right == 4"><shield-account-outline-icon title="administrateur du registre"/></span>
          <span class="ml-2" v-if="booksRightsMap[slot.bookHash][booking.user.id] && booksRightsMap[slot.bookHash][booking.user.id].right == 3"><shield-star-outline-icon title="modérateur du registre"/></span>

          <span v-if="booksFlagsMap[slot.bookHash][booking.user.id]">
            <span v-for="(flag, flagIndex) in booksFlagsMap[slot.bookHash][booking.user.id]" :key="flagIndex" class="ml-2">
              <bookmark-outline-icon title="responsable" v-if="flag.id == 1"/>
            </span>
          </span>
        </b-col>
      </b-row>
    </b-container>
  </div>

  <div class="row m-0 alert text-secondary h1" v-if="slots.length > 0">
    <div class="col-2 p-0 text-left ">
      <arrow-left-circle-icon class="c-hand" v-on:click="loadPreviousMonth(true)"/>
    </div>
    <div class="col-8 text-center">
      <arrow-down-circle-icon class="c-hand" v-on:click="loadNextPage()" v-if="(slotSliceIndex + numberOfSlotsPerPage) < slotsFiltered.length"/>
    </div>
    <div class="col-2 p-0 text-right">
      <arrow-right-circle-icon class="c-hand" v-on:click="loadNextMonth(true)"/>
    </div>
  </div>

</div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

export default {
  data: function () {
    return {
      books: [],
      booksFlagsMap: new Map(),
      booksMap: new Map(),
      booksRightsMap: new Map(),
      clubId: undefined,
      currentFirstDateOfMonth: moment().startOf('month'),
      hideOldSlots: true,
      hideEmptySlots: false,
      monthNames: [' Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
      numberOfSlotsPerPage: 50,
      slots: [],
      slotsMap: new Map(),
      slotSliceIndex: 0,
      weekDayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    }
  },
  computed: {
    myBooks: function () {
      return this.books.filter(row => {
        return this.$parent.bookRightsMap[row.id] && this.$parent.bookRightsMap[row.id].right >= 0;
      });
    },
    slotsFiltered: function() {
      let slots = this.slots.filter(slot => {
        let filterOnHideBooks = true;
        let filterOnHideOldEmptySlots = true;
        let filterOnHideOldSlots = this.hideOldSlots;
        let filterOnHideEmptySlots = this.hideEmptySlots;

        let filterOnHideBooksIsOk = true;
        let filterOnHideOldEmptySlotsIsOk = true;
        let filterOnHideEmptySlotsIsOk = true;
        let filterOnHideOldSlotsIsOk = true;

        if (filterOnHideOldEmptySlots) {
          filterOnHideOldEmptySlotsIsOk = !(slot.isOld && slot.bookings.length == 0);
        }

        if (filterOnHideBooks) {
          filterOnHideBooksIsOk = this.booksMap[slot.bookHash].selected;
        }

        if (filterOnHideEmptySlots) {
          filterOnHideEmptySlotsIsOk = slot.bookings.length > 0;
        }

        if (filterOnHideOldSlots) {
          filterOnHideOldSlotsIsOk = !slot.isOld;
        }

        return filterOnHideBooksIsOk && filterOnHideOldEmptySlotsIsOk && filterOnHideEmptySlotsIsOk && filterOnHideOldSlotsIsOk;
      });

      return slots;
    }
  },
  methods: {
    init: function(goToTop) {
      this.$parent.refresh = true;
      this.slots = [];
      this.slotsMap = new Map();
      this.booksFlagsMap = new Map();
      this.booksRightsMap = new Map();
      let promises = [];
      let selectedBooks = [];
      for (let bookKey in this.myBooks) {
        let book = this.myBooks[bookKey];
        if (book.selected) {
          selectedBooks.push(book);
          promises.push(this.getSlots(book));
          promises.push(this.getBookings(book));
          promises.push(this.getBookAllUsersRights(book));
          promises.push(this.getAllFlagsByBook(book));
        }
      }

      Promise.all(promises).then((responses) => {
        let responseIndex = 0;
        let bookIndex = 0;
        for (let response of responses) {
          if (responseIndex % 4 == 0) {
            this.processGetSlotsResponse(response, selectedBooks[bookIndex]);
          } else if (responseIndex % 4 == 1) {
            this.processGetBookingsResponse(response, selectedBooks[bookIndex]);
          } else if (responseIndex % 4 == 2) {
            this.processGetBookAllUsersRightsResponse(response, selectedBooks[bookIndex]);
          } else {
            this.processGetAllFlagsByBookResponse(response, selectedBooks[bookIndex]);
            bookIndex++;
          }
          responseIndex++;
        }

        this.slots.sort(function(slot1, slot2) {
          let slot1StartDate = moment(slot1.startDate, 'YYYY-MM-DD HH:mm:ss');
          let slot2StartDate = moment(slot2.startDate, 'YYYY-MM-DD HH:mm:ss');
          return slot1StartDate.diff(slot2StartDate);
        });

        if (goToTop) {
          let container = this.$el.querySelector("#topPage");
          container.scrollIntoView();
        }
        this.$parent.refresh = false;
      });
    },
    getBookAllUsersRights: function(book) {
      return axios.get("/api/book-user-right/get-book-all-users-rights/" + book.hash + "/");
    },
    processGetBookAllUsersRightsResponse: function(response, book) {
       let bookRights = response.data;

      let bookRightsMap = new Map();
      for (let userRightKey in bookRights) {
        let userRight = bookRights[userRightKey];
        bookRightsMap[userRight.userId] = userRight;
      }

      this.booksRightsMap[book.hash] = bookRightsMap;
    },
    getBookings: function(book) {
      return axios.get("/api/book-booking/get-book-bookings/" + book.hash + "/" + this.currentFirstDateOfMonth.format("YYYY") + "/" + this.currentFirstDateOfMonth.format("MM") + "/");
    },
    processGetBookingsResponse: function(response, book) {
      let bookings = response.data;
      for (let bookingKey in bookings) {
        let booking = bookings[bookingKey];
        let slotKey = book.hash + ' ' + booking.startDate +  ' ' + booking.endDate;

        this.slotsMap[slotKey].bookings.push(booking);
        if (booking.user.id == this.$parent.userSession.user.id) {
          booking.isMyBooking = true;
          this.slotsMap[slotKey].isBookedByUser = true;
        }
      }
    },
    getClubBooks: function() {
      this.booksMap = new Map();
      return axios.get("/api/book/get-club-books/" + this.clubId + "/");
    },
    processGetClubBooksResponse: function(response) {
      this.books = response.data;

      for (let bookKey in this.books) {
        let book = this.books[bookKey];
        this.booksMap[book.hash] = book;
        this.$set(book, "selected", true);
      }
    },
    getAllFlagsByBook: function(book) {
      return axios.get("/api/book-user-flag/get-all-flags-by-book/" + book.hash + "/");
    },
    processGetAllFlagsByBookResponse: function(response, book) {
      this.booksFlagsMap[book.hash] = response.data;
    },
    getSlots: function(book) {
      return axios.get("/api/book-slot/get-book-slots/" + book.hash + "/" + this.currentFirstDateOfMonth.format("YYYY") + "/" + this.currentFirstDateOfMonth.format("MM") + "/");
    },
    processGetSlotsResponse: function(response, book) {
      let slots = response.data;

      let todayDate = moment();
      for (let slotKey in slots) {
        let slot = slots[slotKey];
        if (slot.status == 1) {
          slot.bookHash = book.hash;
          slot.bookName = book.name;
          this.slotsMap[book.hash + ' ' + slot.startDate + ' ' + slot.endDate] = slot;
          slot.bookings = [];
          slot.isOld = moment(slot.startDate, "YYYY-MM-DD HH:mm:ss").isBefore(todayDate);
          let slotStartDate = moment(slot.startDate, "YYYY-MM-DD HH:mm:ss");
          let slotEndDate = moment(slot.endDate, "YYYY-MM-DD HH:mm:ss");
          slot.prettyDate = this.weekDayNames[slotStartDate.day()] + ' ' + slotStartDate.date() + ' ' + this.monthNames[slotStartDate.month()];
          slot.prettyTime = slotStartDate.format('HH:mm') + ' - ' + slotEndDate.format('HH:mm');
          slot.startDateUrlFormat = moment(slot.startDate, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD-HH-mm-ss");
          slot.endDateUrlFormat = moment(slot.endDate, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD-HH-mm-ss");

          this.slots.push(slot);
        }
      }
    },
    loadNextMonth: function(goToTop) {
      this.$parent.refresh = true;
      this.slotSliceIndex = 0;
      this.currentFirstDateOfMonth.add(1, 'M').startOf('month');
      this.init(goToTop);
    },
    loadPreviousMonth: function(goToTop) {
      this.$parent.refresh = true;
      this.slotSliceIndex = 0;
      this.currentFirstDateOfMonth.subtract(1, 'M').startOf('month');
      this.init(goToTop);
    },
    loadNextPage: function() {
      this.slotSliceIndex = this.slotSliceIndex + this.numberOfSlotsPerPage;
      var container = this.$el.querySelector("#topPage");
      container.scrollIntoView();
    },
    loadPreviousPage: function() {
      this.slotSliceIndex = this.slotSliceIndex - this.numberOfSlotsPerPage;
    },
  },
  beforeMount() {
    this.$parent.init();
    this.clubId = this.$route.query.clubId;

    document.title = this.$parent.websiteName + " - Tous les registres";

    let p1 = this.getClubBooks();
    Promise.all([p1]).then((responses) => {
      this.processGetClubBooksResponse(responses[0]);
      this.init(false);
      this.$parent.loaded = true;
    }).catch((error)=>{
      console.log(error);
      // this.$router.push('/error.html');
    });

  }
}
</script>
