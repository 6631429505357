import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-material-design-icons/styles.css';

import AccountIcon from 'vue-material-design-icons/Account.vue';
import AccountClockIcon from 'vue-material-design-icons/AccountClock.vue';
import AccountMultipleIcon from 'vue-material-design-icons/AccountMultiple.vue';
import AccountOutlineIcon from 'vue-material-design-icons/AccountOutline.vue';
import AccountPlusIcon from 'vue-material-design-icons/AccountPlus.vue';
import AccountSwitchIcon from 'vue-material-design-icons/AccountSwitch.vue';
import AlertCircleIcon from 'vue-material-design-icons/AlertCircle.vue';
import AlertOutlineIcon from 'vue-material-design-icons/AlertOutline.vue';
import ArchiveIcon from 'vue-material-design-icons/Archive.vue';
import ArrowDownCircleOutlineIcon from 'vue-material-design-icons/ArrowDownCircleOutline.vue';
import ArrowDownCircleIcon from 'vue-material-design-icons/ArrowDownCircle.vue';
import ArrowLeftCircleIcon from 'vue-material-design-icons/ArrowLeftCircle.vue';
import ArrowRightCircleIcon from 'vue-material-design-icons/ArrowRightCircle.vue';
import ArrowUpIcon from 'vue-material-design-icons/ArrowUp.vue';
import ArrowUpCircleIcon from 'vue-material-design-icons/ArrowUpCircle.vue';
import ArrowUpCircleOutlineIcon from 'vue-material-design-icons/ArrowUpCircleOutline.vue';
import AutorenewIcon from 'vue-material-design-icons/Autorenew.vue';
import BellIcon from 'vue-material-design-icons/Bell.vue';
import BellOffOutlineIcon from 'vue-material-design-icons/BellOffOutline.vue';
import BookIcon from 'vue-material-design-icons/Book.vue';
import BookMultipleIcon from 'vue-material-design-icons/BookMultiple.vue';
import BookmarkOutlineIcon from 'vue-material-design-icons/BookmarkOutline.vue';
import BullseyeIcon from 'vue-material-design-icons/Bullseye.vue';
import CalendarBlankIcon from 'vue-material-design-icons/CalendarBlank.vue';
import CardAccountDetailsOutlineIcon from 'vue-material-design-icons/CardAccountDetailsOutline.vue';
import CashIcon from 'vue-material-design-icons/Cash.vue';
import CircleIcon from 'vue-material-design-icons/Circle.vue';
import CircleMediumIcon from 'vue-material-design-icons/CircleMedium.vue';
import CheckCircleIcon from 'vue-material-design-icons/CheckCircle.vue';
import ClockAlertOutlineIcon from 'vue-material-design-icons/ClockAlertOutline.vue';
import ClockOutlineIcon from 'vue-material-design-icons/ClockOutline.vue';
import CogIcon from 'vue-material-design-icons/Cog.vue';
import ContentSaveIcon from 'vue-material-design-icons/ContentSave.vue';
import CrownIcon from 'vue-material-design-icons/Crown.vue';
import DeleteIcon from 'vue-material-design-icons/Delete.vue';
import DomainIcon from 'vue-material-design-icons/Domain.vue';
import EarthIcon from 'vue-material-design-icons/Earth.vue';
import EarthOffIcon from 'vue-material-design-icons/EarthOff.vue';
import EmailOutlineIcon from 'vue-material-design-icons/EmailOutline.vue';
import EyeOutlineIcon from 'vue-material-design-icons/EyeOutline.vue';
import ForumIcon from 'vue-material-design-icons/Forum.vue';
import HeartOutlineIcon from 'vue-material-design-icons/HeartOutline.vue';
import HelpCircleOutlineIcon from 'vue-material-design-icons/HelpCircleOutline.vue';
import HomeIcon from 'vue-material-design-icons/Home.vue';
import InformationOutlineIcon from 'vue-material-design-icons/InformationOutline.vue';
import LockOpenOutlineIcon from 'vue-material-design-icons/LockOpenOutline.vue';
import LoginIcon from 'vue-material-design-icons/Login.vue';
import LogoutIcon from 'vue-material-design-icons/Logout.vue';
import MagnifyIcon from 'vue-material-design-icons/Magnify.vue';
import MessageIcon from 'vue-material-design-icons/Message.vue';
import MessageOutlineIcon from 'vue-material-design-icons/MessageOutline.vue';
import NewspaperIcon from 'vue-material-design-icons/Newspaper.vue';
import OfficeBuildingIcon from 'vue-material-design-icons/OfficeBuilding.vue';
import PackageVariantClosedIcon from 'vue-material-design-icons/PackageVariantClosed.vue';
import PencilIcon from 'vue-material-design-icons/Pencil.vue';
import PinIcon from 'vue-material-design-icons/Pin.vue';
import PinOutlineIcon from 'vue-material-design-icons/PinOutline.vue';
import PhoneClassicIcon from 'vue-material-design-icons/PhoneClassic.vue';
import PlusCircleOutlineIcon from 'vue-material-design-icons/PlusCircleOutline.vue';
import PollIcon from 'vue-material-design-icons/Poll.vue';
import RefreshIcon from 'vue-material-design-icons/Refresh.vue';
import RunIcon from 'vue-material-design-icons/Run.vue';
import SendIcon from 'vue-material-design-icons/Send.vue';
import ShapeIcon from 'vue-material-design-icons/Shape.vue';
import ShieldAccountOutlineIcon from 'vue-material-design-icons/ShieldAccountOutline.vue';
import ShieldStarOutlineIcon from 'vue-material-design-icons/ShieldStarOutline.vue';
import TagIcon from 'vue-material-design-icons/Tag.vue';
import TextBoxIcon from 'vue-material-design-icons/TextBox.vue';
import TrophyIcon from 'vue-material-design-icons/Trophy.vue';
import ViewDashboardOutlineIcon from 'vue-material-design-icons/ViewDashboardOutline.vue';

Vue.component('account-icon', AccountIcon)
Vue.component('account-clock-icon', AccountClockIcon)
Vue.component('account-multiple-icon', AccountMultipleIcon)
Vue.component('account-outline-icon', AccountOutlineIcon)
Vue.component('account-plus-icon', AccountPlusIcon)
Vue.component('account-switch-icon', AccountSwitchIcon)
Vue.component('alert-circle-icon', AlertCircleIcon)
Vue.component('alert-outline-icon', AlertOutlineIcon)
Vue.component('archive-icon', ArchiveIcon)
Vue.component('arrow-down-circle-icon', ArrowDownCircleIcon)
Vue.component('arrow-down-circle-outline-icon', ArrowDownCircleOutlineIcon)
Vue.component('arrow-left-circle-icon', ArrowLeftCircleIcon)
Vue.component('arrow-right-circle-icon', ArrowRightCircleIcon)
Vue.component('arrow-up-icon', ArrowUpIcon)
Vue.component('arrow-up-circle-icon', ArrowUpCircleIcon)
Vue.component('arrow-up-circle-outline-icon', ArrowUpCircleOutlineIcon)
Vue.component('autorenew-icon', AutorenewIcon)
Vue.component('bell-icon', BellIcon)
Vue.component('bell-off-outline-icon', BellOffOutlineIcon)
Vue.component('book-icon', BookIcon)
Vue.component('book-multiple-icon', BookMultipleIcon)
Vue.component('bookmark-outline-icon', BookmarkOutlineIcon)
Vue.component('bullseye-icon', BullseyeIcon)
Vue.component('calendar-blank-icon', CalendarBlankIcon)
Vue.component('card-account-details-outline-icon', CardAccountDetailsOutlineIcon)
Vue.component('cash-icon', CashIcon)
Vue.component('circle-icon', CircleIcon)
Vue.component('circle-medium-icon', CircleMediumIcon)
Vue.component('check-circle-icon', CheckCircleIcon)
Vue.component('clock-alert-outline-icon', ClockAlertOutlineIcon)
Vue.component('clock-outline-icon', ClockOutlineIcon)
Vue.component('cog-icon', CogIcon)
Vue.component('content-save-icon', ContentSaveIcon)
Vue.component('crown-icon', CrownIcon)
Vue.component('delete-icon', DeleteIcon)
Vue.component('domain-icon', DomainIcon)
Vue.component('earth-icon', EarthIcon)
Vue.component('earth-off-icon', EarthOffIcon)
Vue.component('email-outline-icon', EmailOutlineIcon)
Vue.component('eye-outline-icon', EyeOutlineIcon)
Vue.component('forum-icon', ForumIcon)
Vue.component('heart-outline-icon', HeartOutlineIcon)
Vue.component('help-circle-outline-icon', HelpCircleOutlineIcon)
Vue.component('home-icon', HomeIcon)
Vue.component('information-outline-icon', InformationOutlineIcon)
Vue.component('lock-open-outline-icon', LockOpenOutlineIcon)
Vue.component('login-icon', LoginIcon)
Vue.component('logout-icon', LogoutIcon)
Vue.component('magnify-icon', MagnifyIcon)
Vue.component('message-icon', MessageIcon)
Vue.component('message-outline-icon', MessageOutlineIcon)
Vue.component('newspaper-icon', NewspaperIcon)
Vue.component('office-building-icon', OfficeBuildingIcon)
Vue.component('package-variant-closed-icon', PackageVariantClosedIcon)
Vue.component('pencil-icon', PencilIcon)
Vue.component('pin-icon', PinIcon)
Vue.component('pin-outline-icon', PinOutlineIcon)
Vue.component('phone-classic-icon', PhoneClassicIcon)
Vue.component('plus-circle-outline-icon', PlusCircleOutlineIcon)
Vue.component('poll-icon', PollIcon)
Vue.component('refresh-icon', RefreshIcon)
Vue.component('run-icon', RunIcon)
Vue.component('send-icon', SendIcon)
Vue.component('shape-icon', ShapeIcon)
Vue.component('shield-account-outline-icon', ShieldAccountOutlineIcon)
Vue.component('shield-star-outline-icon', ShieldStarOutlineIcon)
Vue.component('tag-icon', TagIcon)
Vue.component('text-box-icon', TextBoxIcon)
Vue.component('trophy-icon', TrophyIcon)
Vue.component('view-dashboard-outline-icon', ViewDashboardOutlineIcon)

Vue.config.productionTip = false
Vue.use(BootstrapVue);
Vue.use(IconsPlugin)
Vue.use(require('vue-cookies'))

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
