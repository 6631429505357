<template>
<div class="container-fluid flex-fill" v-if="$parent.loaded">

  <b-breadcrumb>
    <b-breadcrumb-item active class="font-weight-bold"><b-img class="mb-1 mr-3" width="32" height="32" src="/static/images/logo.png" alt="logo"></b-img>Mon tableau de bord</b-breadcrumb-item>
  </b-breadcrumb>

  <b-card no-body>
    <b-tabs v-model="$parent.userDashboardSeletedTab" card>
      <b-tab>
        <template v-slot:title>
          <newspaper-icon/><span class="d-none d-md-inline"> Actualités du site</span>
        </template>
        <div class="alert alert-secondary mb-0 text-center h5" role="alert" v-if="siteNewsList.length == 0">
          Aucune nouvelle
        </div>
        <b-card-group columns v-if="siteNewsList.length >= 0">
          <b-card bg-variant="light" v-for="(news, newsIndex) in siteNewsList" :key="newsIndex">
            <b-card-body>
              <b-card-text>
                <div class="inner-html" v-html="news.text"></div>
              </b-card-text>
              <b-card-text class="text-right small text-muted">
                <span class="mr-4"><router-link class="text-dark" :to="'/site-news-comments.html?siteNewsId=' + news.id"><message-outline-icon/> {{ news.numberOfComments }}</router-link></span>
                <span>{{ news.creationDate }}</span>
              </b-card-text>
            </b-card-body>
          </b-card>
        </b-card-group>
      </b-tab>

      <b-tab v-if="forumCategories && forumCategories.length > 0">
        <template v-slot:title>
          <forum-icon/><span class="d-none d-md-inline"> Forums</span>
        </template>

        <div class="alert alert-secondary mb-4" role="alert">
          Il s'agit du forum du site {{ $parent.websiteName }}. <b>Il traite des sujets relatifs uniquement au fonctionnement du site</b>. Si votre sujet concerne une asso, utilisez le forum de votre asso.
        </div>

        <b-row align-v="center" :class="{'even-line-background' : forumCategoryIndex % 2 == 1}" class="p-2" v-for="(forumCategory, forumCategoryIndex) in forumCategories" :key="forumCategoryIndex">
          <b-col cols="12" class="p-2">
            <router-link :to="'/site-forum-category.html?forumCategoryId=' + forumCategory.id" :id="'forum-category-' + forumCategoryIndex"><span class="text-break font-weight-bold text-uppercase">{{ forumCategory.name }}</span></router-link>
            <span class="ml-4 small text-muted">{{ forumCategory.numberOfThreads }} sujets</span>
          </b-col>
        </b-row>
      </b-tab>

      <b-tab id="my-clubs-tab">
        <template v-slot:title>
          <office-building-icon/><span class="d-none d-md-inline"> Mes Assos</span>
        </template>
        <div class="alert alert-secondary mb-0 text-center h5" role="alert" v-if="$parent.userClubs.length == 0">
          Vous n'êtes inscrit à aucune association.
        </div>
        <b-card-group columns v-if="$parent.userClubs.length > 0">
          <b-card bg-variant="light" v-for="(userClub, userClubIndex) in $parent.userClubs" :key="userClubIndex">
            <template v-slot:header>
              <router-link :to="'/club-dashboard.html?clubId=' + userClub.club.id" :id="'my-club-' + userClubIndex" v-if="userClub.isVerified">{{ userClub.club.name }}</router-link>
              <span v-if="!userClub.isVerified">{{ userClub.club.name }}</span>
            </template>
            <b-card-text>
              <ul class="pl-3">
                <li>
                  <b>Status</b>:
                  <span v-if="userClub.isVerified">vérifié <check-circle-icon class="text-success"/></span>
                  <span v-if="!userClub.isVerified">en attente de vérification <clock-outline-icon class="text-secondary"/></span>
                </li>
                <li>
                  <account-multiple-icon/> {{ userClub.club.numberOfVerifiedUsers }} adhérents
                </li>
                <li>
                  <book-icon/> {{ userClub.club.numberOfBooks }} registres
                </li>
                <li>
                  <newspaper-icon/> {{ userClub.club.numberOfNews }} Actualités
                </li>
                <li>
                  <message-icon/> {{ userClub.club.numberOfGroupTalks }} salons
                </li>
              </ul>
              <div class="text-right"><router-link :to="'/club-dashboard.html?clubId=' + userClub.club.id" :id="'my-club-' + userClubIndex" v-if="userClub.isVerified"><office-building-icon/> Accéder à l'asso</router-link></div>
            </b-card-text>
          </b-card>
        </b-card-group>
      </b-tab>

      <b-tab id="my-clubs-tab" v-if="bookings.length > 0">
        <template v-slot:title>
          <book-icon/><span class="d-none d-md-inline"> Mes réservations</span>
        </template>

        <b-card-group columns v-if="bookings.length >= 0">
          <b-card bg-variant="light" v-for="(booking, bookingIndex) in bookings" :key="bookingIndex">
            <template v-slot:header>
            <router-link class="text-dark" :to="'/book.html?hash=' + booking.book.hash">{{booking.book.name}}</router-link>
            </template>
            <b-card-text>
              <ul>
                <li><b>Asso</b>: <router-link class="text-dark" :to="'/club-dashboard.html?clubId=' + booking.club.id">{{ booking.club.name }}</router-link></li>
                <li><b>Date</b>: {{booking.prettyDate}}</li>
                <li><b>Status</b>:
                  <span v-if="booking.status == -1"><clock-outline-icon class="text-secondary"/> en liste d'attente</span>
                  <span v-if="booking.status == 0"><circle-icon class="text-secondary"/> en attente de validation</span>
                  <span v-if="booking.status == 1"><check-circle-icon class="text-success"/> validé</span>
                </li>
              </ul>
            </b-card-text>
          </b-card>
        </b-card-group>
      </b-tab>
    </b-tabs>
  </b-card>

</div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

export default {
  data: function () {
    return {
      bookings: [],
      forumCategories: [],
      siteNewsList: [],
    }
  },
  methods: {
    getBookings: function() {
      let todayDate = moment();
      return axios.get("/api/book-booking/get-future-user-book-bookings/" + this.$parent.userSession.user.hash + "/" + todayDate.format("YYYY") + "/"  + todayDate.format("MM") + "/"  + todayDate.format("DD") + "/");
    },
    processGetBookingsResponse: function(response) {
      this.bookings = response.data;
      for (let bookingKey in this.bookings) {
        let booking = this.bookings[bookingKey];
        booking.prettyDate = this.$parent.convertToPrettyDate(booking.startDate);
      }
    },
    getSiteForumCategories: function() {
      return axios.get("/api/site-forum-category/get-site-forum-categories/");
    },
    processGetSiteForumCategoriesResponse: function(response) {
      this.forumCategories = response.data;
    },
    getSiteNews: function() {
      return axios.get("/api/site-news/get-site-news/");
    },
    processGetSiteNewsResponse: function(response) {
      this.siteNewsList = response.data;
    },
  },
  beforeMount() {
    this.$parent.init();
    document.title = this.$parent.websiteName + " - Mon tableau de bord";

    let p1 = this.getSiteNews();
    let p2 = this.getBookings();
    let p3 = this.getSiteForumCategories();
    let p4 = this.$parent.getUserSettings(this.$parent.userSession.user.hash);
    Promise.all([p1, p2, p3, p4]).then((responses) => {
      this.processGetSiteNewsResponse(responses[0]);
      this.processGetBookingsResponse(responses[1]);
      this.processGetSiteForumCategoriesResponse(responses[2]);
      this.$parent.processGetUserSettingsResponse(responses[3]);
      this.$parent.loaded = true;
    }).catch(()=>{
      this.$router.push('/error.html');
    });
  }
}
</script>
