<template>
  <div id="app">
    <div>
      <b-overlay :show="!loaded || !parentLoad || refresh" fixed no-wrap rounded="lg"></b-overlay>
      <div class="container-fluid min-vh-100 d-flex flex-column p-0">
        <NavigationBar v-if="loaded && parentLoad && showHeaderAndFooter"></NavigationBar>
        <router-view v-if="parentLoad"/>
        <Footer v-if="loaded && parentLoad && showHeaderAndFooter"></Footer>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import Footer from './components/Footer.vue'
import NavigationBar from './components/NavigationBar.vue'

export default {
  components: {
    Footer, NavigationBar,
  },
  data: function () {
    return {
      bookRights: [],
      bookRightsMap: new Map(),
      clubDashboardSeletedTab: 0,
      constants: undefined,
      domains: [],
      domainsMap: new Map(),
      emailRegexp: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      inAdmin: false,
      lastGroupTalkMessageIdMap: new Map(),
      lastUserTalkMessageIdMap: new Map(),
      loaded: true,
      appVersion: undefined,
      parentLoad: false,
      phoneNumberRegex: /^(\d\.* *){10,}$/,
      monthNames: [' Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
      myUserClubMap: new Map(),
      refresh: false,
      showHeaderAndFooter: true,
      switchBookUserId: undefined,
      switchBookUserName: undefined,
      userDashboardSeletedTab: 0,
      userClubs: [],
      userSession: undefined,
      userSettings: new Map(),
      weekDayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      websiteName: 'MonAsso.ovh',
      websiteUrl: 'https://monasso.ovh',
    }
  },
  methods: {
    init: function() {
      this.showHeaderAndFooter = true;
      this.loaded = false;
      this.inAdmin = false;
    },
    convertToPrettyDate: function(date) {
      let momentDate = moment(date, "YYYY-MM-DD HH:mm:ss");
      return this.weekDayNames[momentDate.day()] + ' ' + momentDate.date() + ' ' + this.monthNames[momentDate.month()] + ' ' + momentDate.format('HH:mm');
    },
    createEanBarCode(id) {
      let barcode = '' + id;
      while (barcode.length < 13) {
        barcode = '0' + barcode;
      }
      return barcode;
    },
    createSession: function(httpResponse) {
      this.userSession = httpResponse.data
      localStorage.sessionHash = this.userSession.sessionHash;
      this.$cookies.set('session_hash', this.userSession.sessionHash, '365d');
      this.$cookies.set('user_hash', this.userSession.user.hash, '365d');
    },
    getBookRights: function(userHash) {
      return axios.get("/api/book-user-right/get-user-rights/" + userHash + "/");
    },
    processGetBookRightsResponse: function(response) {
      this.bookRights = response.data;

      this.bookRightsMap = new Map();
      for (let userRightKey in this.bookRights) {
        let userRight = this.bookRights[userRightKey];
        this.bookRightsMap[userRight.bookId] = userRight;
      }
    },
    getConstants: function() {
      return axios.get("/api/constants/get-constants/");
    },
    processGetConstantsResponse: function(response) {
      if(response.status == 200) {
        this.constants = response.data;
      }
    },
    getDomains: function() {
      return axios.get("/api/domain/get-domains/");
    },
    processGetDomainsResponse: function(response) {
      if(response.status != 200) {
        return;
      }
      this.domains = response.data;
        this.domainsMap = new Map();
        for (let domainKey in this.domains) {
          let domain = this.domains[domainKey];
          this.domainsMap[domain.id] = domain;
        }
    },
    getMyUserClubs: function(userHash) {
      return axios.get("/api/user-club/get-my-user-clubs/" + userHash + "/");
    },
    processGetMyUserClubsResponse: function(response) {
      if(response.status != 200) {
        return;
      }
      this.userClubs = response.data;
      this.myUserClubMap = new Map();
      for (let userClubKey in this.userClubs) {
        let userClub = this.userClubs[userClubKey];
        userClub.club.isAdmin = userClub.isAdmin;
        this.myUserClubMap[userClub.club.id] = userClub;
        if (userClub.isAdmin) {
          this.userSession.atLeastAdminOfOneDomain = true;
        }
      }
    },
    getUserSession: function(userHash, sessionHash) {
      localStorage.sessionHash = sessionHash;
      return axios.get("/api/user-session/get-user-session/" + userHash + "/" + sessionHash + "/");
    },
    processGetUserSessionResponse: function(response) {
      if(response.status == 200) {
        this.userSession = response.data;
        this.userSession.hash = localStorage.sessionHash;
        this.userSession.atLeastAdminOfOneDomain = false;
      }
    },
    getUserSettings: function(userHash) {
      return axios.get("/api/user-setting/get-user-settings/" + userHash + "/");
    },
    processGetUserSettingsResponse: function(response) {
      let userSettingsArray = response.data;
      this.userSettings = new Map();
      for (let userSettingsArrayKey in userSettingsArray) {
        let userSetting  = userSettingsArray[userSettingsArrayKey];
        this.userSettings[userSetting.name] = userSetting.value;
      }
      if (this.userSettings['last-group-talk-message-id']) {
        this.lastGroupTalkMessageIdMap = new Map(Object.entries(JSON.parse(this.userSettings['last-group-talk-message-id'])));
      }
      if (this.userSettings['last-user-talk-message-id']) {
        this.lastUserTalkMessageIdMap = new Map(Object.entries(JSON.parse(this.userSettings['last-user-talk-message-id'])));
      }
    },
    getUserTalks: function() {
      return axios.get("/api/user-talk/get-user-talks/" + this.$parent.userSession.user.id + "/");
    },
    processGetUserTalksResponse: function(response) {
      this.talks = response.data;
    },
    displayErrorMessage: function(error) {
        if(error.response.status == 400) {
          var errorData = error.response.data;
          if (errorData.code == 1004) {
            this.$bvModal.msgBoxOk("Vous n'êtes pas autorisé à faire cette action. Pour plus de renseignement, vous pouvez consulter la FAQ. Code erreur " + errorData.code, { centered: true, noFade: true });
          } else if (errorData.code == 7004) {
            this.$bvModal.msgBoxOk("Vous n'êtes pas autorisé à faire cette action. Pour plus de renseignement, vous pouvez consulter la FAQ. Code erreur " + errorData.code, { centered: true, noFade: true });
          } else if (errorData.code == 15001) {
            this.$bvModal.msgBoxOk("Vous ne pouvez pas réserver ou annuler ce créneau. Soit le créneau est déjà passé, soit il y a un délai minimum de réservation ou d'annulation qui a été positionné par le responsable du registre. Code erreur " + errorData.code, { centered: true, noFade: true });
          } else if (errorData.code == 15002) {
            this.$bvModal.msgBoxOk("Vous avez déjà réservé ce créneau. Code erreur " + errorData.code, { centered: true, noFade: true });
          } else if (errorData.code == 16001) {
            this.$bvModal.msgBoxOk("Le créneau a été fermé. Code erreur " + errorData.code, { centered: true, noFade: true });
          } else if (errorData.code == 24005) {
            this.$bvModal.msgBoxOk("Un équipement existe déjà avec ce code barre. Code erreur " + errorData.code, { centered: true, noFade: true });
          } else {
            this.$bvModal.msgBoxOk("Une erreur est survenue. Code erreur " + errorData.code, { centered: true, noFade: true });
          }
        } else {
          this.$bvModal.msgBoxOk("Une erreur est survenue. Code HTTP " + error.response.status, { centered: true, noFade: true });
        }
    },
    readBackendVersionFromResponses: function(responses) {
      for (let responseKey in responses) {
        this.readBackendVersion(responses[responseKey]);
      }
    },
    readBackendVersion: function(response) {
      let backendVersion = response.headers['monasso-version'];
      if (!this.appVersion) {
        this.appVersion = backendVersion;
      }

      if (this.appVersion != backendVersion) {
        console.log("new version");
      }
    },

    switchUser: function(userId, userName) {
      if (this.switchBookUserId && this.switchBookUserId == userId) {
        this.switchBookUserId = undefined;
        this.switchBookUserName = undefined;
        return;
      }

      this.switchBookUserId = userId;
      this.switchBookUserName = userName;

      if (this.clearSwitchUserTimer) {
        clearTimeout(this.clearSwitchUserTimer);
      }

      this.clearSwitchUserTimer = setTimeout(() => {
          this.switchBookUserId = undefined;
          this.switchBookUserName = undefined;
      }, 900000);
    },
    purgeSession: function() {
      this.$cookies.remove('session_hash');
      this.$cookies.remove('user_hash');
      this.userSession = undefined;
      localStorage.sessionHash = undefined;
      this.$router.push('/');
    }
  },
  async beforeMount() {

    localStorage.sessionHash = undefined;
    let sessionHash = this.$cookies.get('session_hash');
    let userHash = this.$cookies.get('user_hash');
    if (sessionHash != undefined && userHash != undefined) {
      let p1 = this.getUserSession(userHash, sessionHash);
      let p2 = this.getDomains();
      let p3 = this.getConstants();
      let p4 = this.getMyUserClubs(userHash);
      let p5 = this.getBookRights(userHash);
      let p6 = this.getUserSettings(userHash);
      Promise.all([p1, p2, p3, p4, p5, p6]).then((responses) => {
        this.readBackendVersionFromResponses(responses);
        this.processGetUserSessionResponse(responses[0]);
        this.processGetDomainsResponse(responses[1]);
        this.processGetConstantsResponse(responses[2]);
        this.processGetMyUserClubsResponse(responses[3]);
        this.processGetBookRightsResponse(responses[4]);
        this.processGetUserSettingsResponse(responses[5]);
        this.parentLoad = true;
      }).catch(() => {
        this.purgeSession();
      });
    } else {
      let p1 = this.getDomains();
      let p2 = this.getConstants();
      Promise.all([p1, p2]).then((responses) => {
        this.readBackendVersionFromResponses(responses);
        this.processGetDomainsResponse(responses[0]);
        this.processGetConstantsResponse(responses[1]);
        this.parentLoad = true;
      });
    }
  }
}
</script>

<style>

  @font-face {
    font-family: "LibreBarcodeEAN13Text";
    src: url(./fonts/LibreBarcodeEAN13Text-Regular.ttf) format("truetype");
  }

  @font-face {
    font-family: "LibreBarcode128";
    src: url(./fonts/LibreBarcode128-Regular.ttf) format("truetype");
  }

  @media screen and (max-width: 1420px) {
    .order-step {
      display: none;
    }
  }

  @media screen and (max-width: 1200px) {
    .card-columns {
        column-count: 1 !important;
    }
  }

  @media screen and (min-width: 1200px) {
    .card-columns {
        column-count: 2 !important;
    }
  }

  @media screen and (min-width: 1600px) {
    .card-columns {
        column-count: 3 !important;
    }
  }

  .barcodeEan13 {
    font-family: "LibreBarcodeEAN13Text";
    font-size: 8rem;
  }

  .barcode128 {
    font-family: "LibreBarcode128";
    font-size: 3rem;
  }

  .bg {
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("/static/images/home-background.jpg?v=1.0.1");
  }

  .bg-yellow-200 {
    background-color: #ffe69c;
  }

  .even-line-background {
    background-color: rgba(0,0,0,.1);
  }

  .breadcrumb {
    font-size: 1.4rem;
    margin-top: 0 !important;
    padding: 0 !important;
    text-transform: uppercase !important;
    background: none !important;
    display: block !important;
    overflow: hidden;
    white-space: nowrap;
  }

  .breadcrumb-item + .breadcrumb-item::before {
    font-size: 1.2rem;
    opacity: 0.7;
    content: "❯" !important;
  }

  .breadcrumb .breadcrumb-item {
    display: inline-block;
    word-break: break-all;
  }

  .breadcrumb-item:first-child {
    text-align: center;
    width:1.4em;
  }

  .breadcrumb-item, .breadcrumb-item a {
    color: #545b62 !important;
  }

  .buy-button {
    z-index: 2;
    position: relative;
  }

  .c-hand {
      cursor: pointer;
  }

  .carousel-inner {
    overflow: visible !important;
  }

  .container-fluid.flex-fill {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  div.inner-html p{
    margin: 0;
  }

  .group-talk-message {
    border-radius: 5px 5px 5px 5px;
    padding: 0.6rem;
  }

  .group-talk-message-list {
    overflow: auto;
    height: 0px;
  }

  .group-talk-message-other {
    margin-right: 10%;
    background: #DDDDDD;
  }

  .group-talk-message-mine {
    margin-left: 10%;
    background: #cbcaff;
  }

  .group-talk-message p {
    margin: 0;
  }

  div.help h2 {
    background-color: #EEEEEE;
    border-bottom: solid 1px #444444;
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 2rem;
    padding: 0.2rem;
    text-transform: uppercase;
  }

  div.help h3 {
    background-color: #EEEEEE;
    font-size: 1.3rem;
    padding: 0.2rem;
    text-transform: uppercase;
  }
  div.help h4 {
    font-size: 1.2rem;
    font-weight: bold;
  }

  .img-thumbnail {
    box-shadow: 0 0 0.5em black;
  }

  .message {
    border-radius: 5px 5px 5px 5px;
    padding: 0.6rem;
  }

  .message-even {
    background: #a8a8d4;
  }

  .message-odd {
    background: #cbcaff;
  }

  .navbar {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }

  .navbar-brand {
    font-size: 1.4rem !important;
  }

  .nav-item {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }

  nav.navbar * .nav-link {
    color: white !important;
  }

  .opacity60 {
    opacity: 0.6;
  }

  .text-orange {
    color: #FF6600;
  }
</style>
