<template>
<div class="d-flex align-items-center text-center flex-fill bg" v-if="$parent.loaded">
  <div class="container font-weight-bold text-white" style="text-shadow: 0px 0px 2px #000000, 0px 0px 4px #000000;">
    <h1>
      Page d'erreur
    </h1>
    <h2>La page que vous avez demandée est inaccessible pour l'instant.</h2>
  </div>
</div>
</template>

<script>

export default {
  data: function () {
    return {

    }
  },
  methods: {
  },
  beforeMount() {
    this.$parent.init();
    document.title = this.$parent.websiteName + "Page Erreur";
    this.$parent.loaded = true;
  }
}
</script>
