<template>
<div class="container-fluid flex-fill" v-if="$parent.loaded">

  <b-breadcrumb>
    <b-breadcrumb-item to="/"><b-img class="mb-1" width="32" height="32" src="/static/images/logo.png" alt="logo"></b-img></b-breadcrumb-item>
    <b-breadcrumb-item :to="'/club-dashboard.html?clubId=' + clubId" ><office-building-icon/></b-breadcrumb-item>
    <b-breadcrumb-item active class="font-weight-bold">Les Registres du club</b-breadcrumb-item>
  </b-breadcrumb>

  <div class="alert alert-secondary mb-0 text-center h5" role="alert" v-if="books.length == 0">
    Aucun registre trouvé.
  </div>

  <b-row align-v="center" :class="{'even-line-background' : bookIndex % 2 == 1}" class="p-2 ml-2 mr-2" v-for="(book, bookIndex) in books" :key="bookIndex">
    <b-col cols="10" class="font-weight-bold text-uppercase"><router-link :to="'/book.html?hash=' + book.hash" class="text-dark">{{ book.name }}</router-link></b-col>
    <b-col cols="2" class="h3 text-right">
      <router-link :to="'/club-admin-book-users.html?hash=' + book.hash + '&clubId=' + clubId" class="mr-2"><account-multiple-icon/></router-link>
      <router-link :to="'/club-admin-book-edit.html?hash=' + book.hash" class="mr-2"><pencil-icon/></router-link>
      <span class="c-hand text-danger" v-on:click="deleteBook(book, false)"><delete-icon/></span>
    </b-col>
  </b-row>

</div>
</template>

<script>
import axios from 'axios'

export default {
  data: function () {
    return {
      books: [],
      club: undefined,
      clubId: undefined,
    }
  },
  methods: {
    deleteBook: function(book, confirm) {
      if (!confirm) {

        this.$bvModal.msgBoxConfirm('Voulez-vous supprimer le registre "' + book.name + '" ? TOUTES LES RESERVATIONS DE CE REGISTRE SERONT SUPPRIMEES !' , { okVariant: 'success', okTitle: 'Oui', cancelTitle: 'Non', hideHeaderClose: true, centered: true, noFade: true }).then((value) => {
            if (value) {
              this.deleteBook(book, true);
            }
          });
        return;
      }
      this.$parent.refresh = true;

      var json = {
        bookId: book.id,
       };

      axios.post("/api/book/delete-book/", json)
      .then(() => {
        let p1 = this.getClubBooks();
        Promise.all([p1]).then((responses) => {
          this.processGetClubBooksResponse(responses[0]);
          this.$parent.refresh = false;
        });
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
    getClubBooks: function() {
      return axios.get("/api/book/get-club-books/" + this.clubId + "/");
    },
    processGetClubBooksResponse: function(response) {
      this.books = response.data;
    },
  },
  beforeMount() {
    this.$parent.init();
    this.clubId = this.$route.query.clubId;
    this.club = this.$parent.myUserClubMap[this.clubId].club;

    let p1 = this.getClubBooks();
    Promise.all([p1]).then((responses) => {
      this.processGetClubBooksResponse(responses[0]);
      document.title = this.$parent.websiteName + " - Registre Asso " + this.club.name;
      this.$parent.loaded = true;
    }).catch(()=>{
      this.$router.push('/error.html');
    });
  }
}
</script>
