<template>
  <div>
    <div v-if="!$parent.inAdmin">
      <b-navbar toggleable="sm" type="dark" variant="dark" class="h5">

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item id="login-link" to="/login.html" title="Se connecter" v-if="!$parent.userSession">
              <login-icon/> Se connecter
            </b-nav-item>
            <b-nav-item-dropdown id="user-menu" v-if="$parent.userSession">
              <template v-slot:button-content>
                <span><account-icon/> {{ $parent.userSession.user.firstname }} {{ $parent.userSession.user.lastname }}</span>
                <span class="text-warning ml-1" v-if="$parent.userSettings['unread-private-message'] =='1'"><circle-icon/></span>
              </template>
              <b-dropdown-item to="/user-dashboard.html"><view-dashboard-outline-icon/> Mon tabeau de bord</b-dropdown-item>
              <b-dropdown-item to="/profile.html"><card-account-details-outline-icon/> Mon profil</b-dropdown-item>
              <b-dropdown-item to="/private-messages.html"><span><message-outline-icon/> Messages privés</span> <span class="text-warning ml-1" v-if="$parent.userSettings['unread-private-message'] =='1'"><circle-icon/></span></b-dropdown-item>
              <!--<b-dropdown-item to="/my-challenges.html"><bullseye-icon/> Mes challenges</b-dropdown-item>-->
              <b-dropdown-item to="/logout.html" id="logout-link"><logout-icon/> Se déconnecter</b-dropdown-item>
            </b-nav-item-dropdown>

            <!--<b-nav-item-dropdown id="challenge-menu" v-if="$parent.userSession && (verifiedUserClubs.length > 0 || $parent.userSession.atLeastAdminOfOneDomain || $parent.userSession.isSuperAdmin)">
              <template v-slot:button-content>
                <bullseye-icon/> Challenges
              </template>
              <b-dropdown-item v-if="$parent.userSession.atLeastAdminOfOneDomain || $parent.userSession.isSuperAdmin" id="add-challenge-link" to="/add-challenge.html">
                <plus-circle-outline-icon/> Créer un nouveau challenge</b-dropdown-item>
              <b-dropdown-item :to='"/domain-public-challenges.html?domain=" + userDomain.domainId' v-for="(userDomain, userDomainIndex) in verifiedUserClubs" :key="userDomainIndex"><bullseye-icon/> Challenges {{ $parent.domainsMap[userDomain.domainId].name }}</b-dropdown-item>
            </b-nav-item-dropdown>-->

            <b-nav-item to="/domains.html" title="Disciplines"><domain-icon/> Disciplines</b-nav-item>

            <b-nav-item-dropdown>
              <template v-slot:button-content>
                <help-circle-outline-icon/> Aide
              </template>
              <b-dropdown-item to="/help.html"><information-outline-icon/> Aide</b-dropdown-item>
              <b-dropdown-item to="/donate.html"><cash-icon/> Faire un don</b-dropdown-item>
              <b-dropdown-item to="/faq.html"><help-circle-outline-icon/> FAQ</b-dropdown-item>
              <b-dropdown-item to="/contact.html"><email-outline-icon/> Nous Contacter</b-dropdown-item>
              <b-dropdown-item to="/thanks.html"><heart-outline-icon/> Remerciements</b-dropdown-item>
            </b-nav-item-dropdown>

            <b-nav-item to="/admin/" title="Administration" v-if="$parent.userSession && $parent.userSession.user.isSuperAdmin"><cog-icon/></b-nav-item>

          </b-navbar-nav>
        </b-collapse>

      </b-navbar>
    </div>
    <div v-if="$parent.inAdmin">
      <b-navbar toggleable="xl" type="dark" variant="dark" class="h5">

        <b-nav-item to="/" title="Home" v-if="$parent.userSession.user.isSuperAdmin"><home-icon/></b-nav-item>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item-dropdown id="admin-domain-menu">
              <template v-slot:button-content>
                <run-icon/> Domain &amp; Clubs
              </template>
              <b-dropdown-item to="/admin/domains.html"><bullseye-icon/> Liste des domaines</b-dropdown-item>
              <b-dropdown-item to="/admin/clubs.html"><run-icon/> Liste des clubs</b-dropdown-item>
              <b-dropdown-item to="/admin/add-club.html"><plus-circle-outline-icon/> Ajouter un club</b-dropdown-item>
            </b-nav-item-dropdown>

            <b-nav-item-dropdown id="admin-user-menu">
              <template v-slot:button-content>
                <account-icon/> Utilisateurs
              </template>
              <b-dropdown-item to="/admin/users.html"><account-multiple-icon/> Liste des utilisateurs</b-dropdown-item>
            </b-nav-item-dropdown>

            <b-nav-item-dropdown id="admin-user-menu">
              <template v-slot:button-content>Misc
              </template>
              <b-dropdown-item to="/admin/news.html"><newspaper-icon/> News</b-dropdown-item>
              <b-dropdown-item to="/admin/forum-category.html"><shape-icon/> Gestion des catégories du forum</b-dropdown-item>
            </b-nav-item-dropdown>

          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>

  </div>
</template>


<script>
export default {
  computed: {
    verifiedUserClubs: function () {
      return this.$parent.userClubs.filter(userClub => userClub.isVerified === true)
    },
  }
}
</script>
