<template>
<b-form>
  <b-form-group>
    <b-input-group>
      <template #prepend>
        <b-input-group-text>Combien de nuages,<br>d'arbres et de<br>fleurs voyez-vous ?</b-input-group-text>
      </template>
      <b-img fluid :src="captchaUrl" class="captcha"></b-img>
      <template #append>
        <b-button v-on:click="refreshCaptcha()"><autorenew-icon/></b-button>
      </template>
    </b-input-group>
  </b-form-group>

  <b-form-group>
    <b-input-group>
      <template #prepend>
        <b-input-group-text>nuage</b-input-group-text>
      </template>
      <b-form-select :options="['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']" v-model="captchaCloud"></b-form-select>
    </b-input-group>
  </b-form-group>

  <b-form-group>
    <b-input-group>
      <template #prepend>
        <b-input-group-text>arbre</b-input-group-text>
      </template>
      <b-form-select :options="['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']" v-model="captchaTree"></b-form-select>
    </b-input-group>
  </b-form-group>

  <b-form-group>
    <b-input-group>
      <template #prepend>
        <b-input-group-text>fleur</b-input-group-text>
      </template>
      <b-form-select :options="['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']" v-model="captchaFlower"></b-form-select>
    </b-input-group>
  </b-form-group>
</b-form>
</template>

<script>
export default {
  data: function () {
    return {
      captchaCloud: 1,
      captchaTree: 1,
      captchaFlower: 1,
      captchaUrl: '/api/ressources/captcha.png?t=' + new Date().getTime()
    }
  },
  methods: {
    refreshCaptcha() {
      this.captchaUrl = '/api/ressources/captcha.png?t=' + new Date().getTime();
    }
  }
}
</script>

<style>
</style>
