<template>
<div class="container-fluid flex-fill" v-if="$parent.loaded">

  <b-breadcrumb>
    <b-breadcrumb-item to="/"><b-img class="mb-1" width="32" height="32" src="/static/images/logo.png" alt="logo"></b-img></b-breadcrumb-item>
    <b-breadcrumb-item :to="'/club-dashboard.html?clubId=' + clubId" ><office-building-icon/></b-breadcrumb-item>
    <b-breadcrumb-item active class="font-weight-bold">Les pages de l'asso</b-breadcrumb-item>
  </b-breadcrumb>

  <b-card no-body>
    <b-tabs card>
      <b-tab>
        <template v-slot:title>
          <text-box-icon/> Les pages publiques
        </template>

        <div class="alert alert-secondary mb-0 text-center h5" role="alert" v-if="publicPages.length == 0">
          Aucun page publique trouvée.
        </div>

        <b-row align-v="center" :class="{'even-line-background' : pageIndex % 2 == 1}" class="p-2" v-for="(page, pageIndex) in publicPages" :key="pageIndex">
          <b-col cols="8">
           {{ page.title }}
          </b-col>
          <b-col cols="4" class="text-right h5">
            <router-link :to="'/club-admin-edit-page.html?pageId=' + page.id" class="mr-2"><pencil-icon/></router-link>
            <span class="c-hand" v-on:click="switchPageOrder(publicPages, pageIndex, true)"><arrow-up-circle-icon/></span>
            <span class="c-hand" v-on:click="switchPageOrder(publicPages, pageIndex, false)"><arrow-down-circle-icon/></span>
          </b-col>
        </b-row>

        <b-row class="text-center mt-4">
          <b-col sm="12">
            <b-button variant="success" v-on:click="updatePageOrders(publicPages)">Mettre à jour</b-button>
          </b-col>
        </b-row>

      </b-tab>

      <b-tab>
        <template v-slot:title>
          <text-box-icon/> Les pages privées
        </template>

        <div class="alert alert-secondary mb-0 text-center h5" role="alert" v-if="privatePages.length == 0">
          Aucun page privée trouvée.
        </div>

        <b-row align-v="center" :class="{'even-line-background' : pageIndex % 2 == 1}" class="p-2" v-for="(page, pageIndex) in privatePages" :key="pageIndex">
          <b-col cols="8">
            {{ page.title }}
          </b-col>
          <b-col cols="4" class="text-right h5">
            <router-link :to="'/club-admin-edit-page.html?pageId=' + page.id" class="mr-2"><pencil-icon/></router-link>
            <span class="c-hand" v-on:click="switchPageOrder(privatePages, pageIndex, true)"><arrow-up-circle-icon/></span>
            <span class="c-hand" v-on:click="switchPageOrder(privatePages, pageIndex, false)"><arrow-down-circle-icon/></span>
          </b-col>
        </b-row>

        <b-row class="text-center mt-4">
          <b-col sm="12">
            <b-button variant="success" v-on:click="updatePageOrders(privatePages)">Mettre à jour</b-button>
          </b-col>
        </b-row>
      </b-tab>

      <b-tab>
        <template v-slot:title>
          <plus-circle-outline-icon/> Ajout d'une page
        </template>

        <b-input-group class="mt-2">
          <template #prepend>
              <b-input-group-text class="font-weight-bold">Nom</b-input-group-text>
          </template>
          <b-form-input v-model="newPage.title" type="text"></b-form-input>
        </b-input-group>

        <b-form-group class="mt-2 mb-0" label-class="font-weight-bold">
          <quilleditor ref="quilleditor" id="pageText" v-model="newPage.text"></quilleditor>
        </b-form-group>

        <b-input-group class="mt-4">
          <b-form-checkbox inline switch v-model="newPage.isPublic">page publique</b-form-checkbox>
        </b-input-group>

        <b-row class="text-center mt-4">
          <b-col sm="12">
            <b-button variant="success" v-on:click="addPage" :disabled="!(newPage.text && newPage.text.length > 0 && newPage.title && newPage.title.length > 0)">Ajouter la page</b-button>
          </b-col>
        </b-row>

      </b-tab>
    </b-tabs>
  </b-card>
</div>
</template>

<script>
import axios from 'axios'
import quilleditor from '@/components/QuillEditor.vue'

export default {
  components: {
    quilleditor
  },
  data: function () {
    return {
      publicPages: [],
      privatePages: [],
      club: undefined,
      clubId: undefined,
      newPage: {isPublic: false, text: '', title: ''},
    }
  },
  methods: {
    addPage: function() {
      var json = {
        clubId: this.club.id,
        isPublic: this.newPage.isPublic || false,
        text: this.newPage.text,
        title: this.newPage.title,
       };

      axios.post("/api/club-page/add-club-page/", json)
      .then(() => {
        this.getPages().then(response => {
          this.processGetPagesResponse(response);
          this.$bvModal.msgBoxOk("La page a été ajoutée.", { centered: true, noFade: true });
          this.newPage = {text: ''}
          this.$parent.refresh = false;
        });
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
    deletePage: function(pageId, confirm) {
      if (!confirm) {

        this.$bvModal.msgBoxConfirm('Voulez-vous supprimer la page ?' , { okVariant: 'success', okTitle: 'Oui', cancelTitle: 'Non', hideHeaderClose: true, centered: true, noFade: true }).then((value) => {
            if (value) {
              this.deletePage(pageId, true);
            }
          });
        return;
      }
      this.$parent.refresh = true;

      var json = {
        pageId: pageId,
       };

      axios.post("/api/club-page/delete-club-page/", json)
      .then(() => {
        this.getPages().then(response => {
          this.processGetPagesResponse(response);
          this.$bvModal.msgBoxOk("L'actualité a été supprimée.", { centered: true, noFade: true });
          this.$parent.refresh = false;
        });
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
    getPages: function() {
      return axios.get("/api/club-page/get-club-pages/" + this.clubId + "/");
    },
    processGetPagesResponse: function(response) {
      let pages = response.data;
      this.publicPages = [];
      this.privatePages = [];
      for (let page of pages) {
        if (page.isPublic) {
          this.publicPages.push(page);
        } else {
          this.privatePages.push(page);
        }
      }
    },
    switchPageOrder: function(pages, index, up) {
      if (up && index == 0) {
        return;
      }
      if (!up && index > pages.length - 2) {
        return;
      }

      if (up) {
        pages = pages.splice(index - 1, 2, pages[index], pages[index - 1] );
      } else {
        pages = pages.splice(index, 2, pages[index + 1], pages[index] );
      }
    },
    updatePageOrders: function(pages) {
      var promises = [];

      for (var i = 0; i < pages.length; i++) {
        var json = {
          pageId: pages[i].id,
          order: i+1,
        };

        promises.push(axios.post("/api/club-page/update-club-page-order/", json));
      }

      Promise.all(promises).then(() => {
        this.getPages().then(response => {
          this.processGetPagesResponse(response);
          this.$bvModal.msgBoxOk("L'ordre des pages a été mise à jour.", { centered: true, noFade: true });
          this.$parent.refresh = false;
        });
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
  },
  beforeMount() {
    this.$parent.init();
    this.clubId = this.$route.query.clubId;
    this.club = this.$parent.myUserClubMap[this.clubId].club;

    let p1 = this.getPages();
    Promise.all([p1]).then((responses) => {
      this.processGetPagesResponse(responses[0]);
      document.title = this.$parent.websiteName + " - Gestion des pages de l'asso " + this.club.name;
      this.$parent.loaded = true;
    }).catch((error)=>{
      console.log(error);
      //this.$router.push('/error.html');
    });
  }
}
</script>
