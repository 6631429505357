<template>
<div class="container-fluid flex-fill" v-if="$parent.loaded">

  <b-breadcrumb>
    <b-breadcrumb-item to="/"><b-img class="mb-1" width="32" height="32" src="/static/images/logo.png" alt="logo"></b-img></b-breadcrumb-item>
    <b-breadcrumb-item :to="'/club-dashboard.html?clubId=' + book.clubId"><office-building-icon/></b-breadcrumb-item>
    <b-breadcrumb-item active class="font-weight-bold"><book-icon/> {{ book.name }}</b-breadcrumb-item>
  </b-breadcrumb>

  <div class="alert alert-secondary mb-0" role="alert">
    <div class="inner-html" v-html="book.description"></div>
    <hr>
    <div class="text-center">
      <b-form-checkbox inline class="mr-2" switch v-model="hideOldSlots">Cacher les vieux créneaux</b-form-checkbox>
      <b-form-checkbox inline switch v-model="hideEmptySlots">Cacher les créneaux vides</b-form-checkbox>
      <router-link :to="'/club-admin-book-users.html?hash=' + book.hash +'&clubId=' + book.clubId" class="m-0 h3" v-if="$parent.bookRightsMap[book.id] && $parent.bookRightsMap[book.id].right == 4"><account-multiple-icon/></router-link>
      <router-link :to="'/club-admin-book-users-read-only.html?hash=' + book.hash +'&clubId=' + book.clubId" class="m-0 h3" v-if="$parent.bookRightsMap[book.id] && $parent.bookRightsMap[book.id].right == 3"><account-multiple-icon/></router-link>
    </div>
    <hr>
    <div class="text-secondary text-center">
      <span class="mr-2"><clock-alert-outline-icon title="Nombre de jour avant réservation ou annulation"/> {{ book.bookingCancellationDays }} jours</span>
      <span><router-link to="/help.html#book" target="_blank"><information-outline-icon/> aide sur les registres</router-link></span>
    </div>
  </div>

  <div class="text-secondary mt-2 mb-2 text-center">
    <span class="mr-2"><shield-account-outline-icon/> Administrateur</span>
    <span class="mr-2"><shield-star-outline-icon/> Modérateur</span>
    <span class="mr-2"><bookmark-outline-icon/> Responsable</span>
  </div>

  <div id="topPage"></div>

  <div class="row m-0 mb-2 alert alert-warning h5 text-uppercase font-weight-bold bg-warning">
    <div class="col-2 p-0 text-left align-middle h1 m-0">
      <arrow-left-circle-icon class="c-hand" v-on:click="loadPreviousMonth(false)"/>
    </div>
    <div class="col-8 text-center">
      <span>{{ monthNames[this.currentFirstDateOfMonth.format('M') - 1] }} {{ currentFirstDateOfMonth.format('YYYY') }} </span><br><span class="small"> {{ slots.length }} créneaux</span>
    </div>
    <div class="col-2 p-0 text-right align-middle h1 m-0">
      <arrow-right-circle-icon class="c-hand" v-on:click="loadNextMonth(false)"/>
    </div>
  </div>

  <div class="alert alert-secondary mb-0 text-center h5" role="alert" v-if="slotsFiltered.length == 0">
    Aucun créneau trouvé
  </div>

  <div class="row mb-1 text-secondary h1" v-if="slotSliceIndex > 0">
    <div class="col-12 text-center">
      <arrow-up-circle-icon class="c-hand" v-on:click="loadPreviousPage()"/>
    </div>
  </div>
  <div v-for="(slot, slotIndex) in slotsFiltered.slice(this.slotSliceIndex, this.slotSliceIndex + this.numberOfSlotsPerPage)" :key="slotIndex">
    <b-container fluid class="border mb-4">
      <b-row class="bg-dark mb-0 p-4 text-center">
        <b-col cols="12">
          <div class="font-weight-bold h5 text-uppercase text-white">
            <div class="mr-2 d-inline-block"><calendar-blank-icon/> {{ slot.prettyDate }}</div>
            <div class="d-inline-block"><clock-outline-icon/> {{ slot.prettyTime }}</div>
          </div>

          <div class="text-white">
            <span class="mr-2"><account-multiple-icon class="mr-1"/>
              <span class="font-weight-bold" v-if="slot.numberOfPlaces > slot.bookings.length">{{ slot.bookings.length }} / {{ slot.numberOfPlaces }}</span>
              <span class="text-danger font-weight-bold" v-if="slot.numberOfPlaces <= slot.bookings.length">COMPLET</span>
            </span>
            <span class="mr-2"><clock-outline-icon/> {{ slot.duration }} min</span>
            <span class="mr-2">
              <router-link class="text-white" :to="'/book-slot-group-talk.html?bookHash=' + book.hash +'&slotStartDate=' + slot.startDateUrlFormat + '&slotEndDate=' + slot.endDateUrlFormat" :class="{'text-warning': slot.numberOfMessages > 0}"><message-icon/> {{ slot.numberOfMessages }}</router-link>
            </span>

          </div>
          <div class="text-white mt-4" v-if="!slot.isOld && $parent.bookRightsMap[book.id] && $parent.bookRightsMap[book.id].right >= 3">
            <b-form-checkbox inline switch v-model="slot.isOpenNewStatus" v-on:change="updateSlotStatus(slot, false)">Créneau {{ slot.isOpen ? 'ouvert' : 'fermé' }}</b-form-checkbox>
          </div>
        </b-col>
      </b-row>

      <b-row class="h5 p-3 mb-0" align-v="start" :class="{'even-line-background' : bookingIndex % 2 == 1 && !booking.isMyBooking, 'bg-yellow-200': booking.isMyBooking}" v-for="(booking, bookingIndex) in slot.bookings" :key="bookingIndex">
        <b-col :cols="bookAllUsersRightsMap[$parent.userSession.user.id] && (bookAllUsersRightsMap[$parent.userSession.user.id].right == 3 || bookAllUsersRightsMap[$parent.userSession.user.id].right == 4) ? '8': '12'">
          <clock-outline-icon class="text-secondary mr-2" v-if="booking.status == -1" title="en liste d'attente"/>
          <circle-icon class="text-secondary mr-2" v-if="booking.status == 0" title="en attente de validation"/>
          <check-circle-icon class="text-success mr-2" v-if="booking.status == 1" title="validé"/>

          <span>{{ booking.user.firstname }} {{ booking.user.lastname }}</span>

          <span class="ml-2" v-if="bookAllUsersRightsMap[booking.user.id] && bookAllUsersRightsMap[booking.user.id].right == 4"><shield-account-outline-icon title="administrateur du registre"/></span>
          <span class="ml-2" v-if="bookAllUsersRightsMap[booking.user.id] && bookAllUsersRightsMap[booking.user.id].right == 3"><shield-star-outline-icon title="modérateur du registre"/></span>

          <span v-if="flags[booking.user.id]">
            <span v-for="(flag, flagIndex) in flags[booking.user.id]" :key="flagIndex" class="ml-2">
              <bookmark-outline-icon title="responsable" v-if="flag.id == 1"/>
            </span>
          </span>
        </b-col>
        <b-col cols="4" class="text-right" v-if="bookAllUsersRightsMap[$parent.userSession.user.id] && (bookAllUsersRightsMap[$parent.userSession.user.id].right == 3 || bookAllUsersRightsMap[$parent.userSession.user.id].right == 4)">
          <div v-if="!slot.isOld">
            <span class="ml-2 c-hand h3 text-danger" v-on:click="deleteBooking(slot, false, true, booking);">
            <delete-icon/>
            </span>

            <span class="ml-2 c-hand h3 text-success" v-if="booking.status != 1 && booking.status != -1" v-on:click="changeBookingStatus(booking, 1, false);">
            <check-circle-icon/>
            </span>

            <span class="ml-2 c-hand h3 text-secondary" v-if="booking.status != 0 && booking.status != -1" v-on:click="changeBookingStatus(booking, 0, false);">
            <circle-icon/>
            </span>
          </div>
        </b-col>
      </b-row>

      <b-row class=" text-center" v-if="$parent.bookRightsMap[book.id] && $parent.bookRightsMap[book.id].right >= 3 && !slot.isOpen">
        <b-col cols="12" class=" p-3 alert alert-secondary mb-0 text-center h5">
            Créneau fermé
        </b-col>
      </b-row>

      <b-row class="m-0 p-2 text-center" v-if="!slot.isOld && slot.isOpen">
        <b-col cols="12">
          <div v-if="!$parent.switchBookUserId">
            <b-button :id="'add-booking-' + slotIndex" variant="success" v-on:click="addBooking(slot);" v-if="!slot.isBookedByUser && slot.numberOfPlaces > slot.bookings.length"><plus-circle-outline-icon/> Réserver ce créneau</b-button>

            <b-button :id="'add-booking-' + slotIndex" variant="secondary" v-on:click="addBooking(slot);" v-if="!slot.isBookedByUser && slot.numberOfPlaces <= slot.bookings.length"><clock-outline-icon/> Etre en liste d'attente</b-button>
            <b-button :id="'delete-booking-' + slotIndex" variant="danger" v-on:click="deleteBooking(slot, false, false, undefined);" v-if="slot.isBookedByUser"><delete-icon/> Supprimer ma réservation</b-button>
          </div>

          <div v-if="$parent.switchBookUserId">
            <b-button :id="'add-booking-' + slotIndex" variant="success" v-on:click="addBooking(slot);" v-if="!slot.isBookedBySwitchUser && slot.numberOfPlaces > slot.bookings.length"><plus-circle-outline-icon/> Réserver pour {{ $parent.switchBookUserName }}</b-button>

            <b-button :id="'add-booking-' + slotIndex" variant="secondary" v-on:click="addBooking(slot);" v-if="!slot.isBookedBySwitchUser && slot.numberOfPlaces <= slot.bookings.length"><clock-outline-icon/> Mettre {{ $parent.switchBookUserName }} en liste d'attente</b-button>

            <b-button :id="'delete-booking-' + slotIndex" variant="danger" v-on:click="deleteBooking(slot, false, false, undefined);" v-if="slot.isBookedBySwitchUser"><delete-icon/> Supprimer la réservation de {{ $parent.switchBookUserName }}</b-button>
          </div>
        </b-col>
      </b-row>

    </b-container>
  </div>

  <div class="row m-0 alert text-secondary h1" v-if="slots.length > 0">
    <div class="col-2 p-0 text-left ">
      <arrow-left-circle-icon class="c-hand" v-on:click="loadPreviousMonth(true)"/>
    </div>
    <div class="col-8 text-center">
      <arrow-down-circle-icon class="c-hand" v-on:click="loadNextPage()" v-if="(slotSliceIndex + numberOfSlotsPerPage) < slotsFiltered.length"/>
    </div>
    <div class="col-2 p-0 text-right">
      <arrow-right-circle-icon class="c-hand" v-on:click="loadNextMonth(true)"/>
    </div>
  </div>

</div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

export default {
  data: function () {
    return {
      book: undefined,
      bookHash: undefined,
      bookings: [],
      bookAllUsersRights: [],
      bookAllUsersRightsMap: new Map(),
      currentFirstDateOfMonth: moment().startOf('month'),
      flags: new Map(),
      hideOldSlots: true,
      hideEmptySlots: false,
      weekDayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      monthNames: [' Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
      numberOfSlotsPerPage: 50,
      slotSliceIndex: 0,
      slots: [],
      slotsMap: new Map(),
      today: moment(),
    }
  },
  computed: {
    slotsFiltered: function() {
      let slots = this.slots.filter(slot => {
        let filterOnIsOpen = true;
        let filterOnHideOldSlots = this.hideOldSlots;
        let filterOnHideEmptySlots = this.hideEmptySlots;
        let filterOnHideOldEmptySlots = true;

        let filerOnIsOpenIsOK = true;
        let filterOnHideOldSlotsIsOk = true;
        let filterOnHideEmptySlotsIsOk = true;
        let filterOnHideOldEmptySlotsIsOk = true;

        if (filterOnIsOpen) {
          filerOnIsOpenIsOK = (this.$parent.bookRightsMap[this.book.id] && this.$parent.bookRightsMap[this.book.id].right >= 3) || slot.isOpen;
        }

        if (filterOnHideOldSlots) {
          filterOnHideOldSlotsIsOk = !slot.isOld;
        }

        if (filterOnHideEmptySlots) {
          filterOnHideEmptySlotsIsOk = slot.bookings.length > 0;
        }

        if (filterOnHideOldEmptySlots) {
          filterOnHideOldEmptySlotsIsOk = !(slot.isOld && slot.bookings.length == 0);
        }

        return filerOnIsOpenIsOK && filterOnHideOldSlotsIsOk && filterOnHideEmptySlotsIsOk && filterOnHideOldEmptySlotsIsOk
      });

      return slots;
    }
  },
  methods: {
    addBooking: function(slot) {
      this.$parent.refresh = true;
      var json = {
        bookId: this.book.id,
        slotStartDate: slot.startDate,
        slotEndDate: slot.endDate,
        userId: this.$parent.userSession.user.id,
       };

      if (this.$parent.switchBookUserId) {
        json.userId = this.$parent.switchBookUserId;
      }

      axios.post("/api/book-booking/add-booking/", json)
      .then(response => {
        if(response.status == 200) {
          let p1 = this.getSlots();
          let p2 = this.getBookings();
          Promise.all([p1, p2]).then((responses) => {
            this.processGetSlotsResponse(responses[0]);
            this.processGetBookingsResponse(responses[1]);
            this.$parent.refresh = false;
          });
        }
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
    changeBookingStatus: function(booking, status, confirm) {
      if (!confirm) {
        let message = '';
        if (status == 0) {
          message = 'Voulez-vous mettre en attente de validation la réservation de ' + booking.user.firstname + ' ' + booking.user.lastname + '?';
        } else if (status == 1) {
          message = 'Voulez-vous accepter la réservation de ' + booking.user.firstname + ' ' + booking.user.lastname + '?';
        }
        this.$bvModal.msgBoxConfirm(message, { okVariant: 'success', okTitle: 'Oui', cancelTitle: 'Non', hideHeaderClose: true, centered: true, noFade: true }).then((value) => {
            if (value) {
              this.changeBookingStatus(booking, status, true);
            }
          });
        return;
      }

      this.$parent.refresh = true;
      let json = {
        bookId: this.book.id,
        slotStartDate: booking.startDate,
        slotEndDate: booking.endDate,
        userId: booking.user.id,
        status: status
       };

      axios.post("/api/book-booking/update-booking-status/", json)
      .then(response => {
        if(response.status == 200) {
          let p1 = this.getSlots();
          let p2 = this.getBookings();
          Promise.all([p1, p2]).then((responses) => {
            this.processGetSlotsResponse(responses[0]);
            this.processGetBookingsResponse(responses[1]);
            this.$parent.refresh = false;
          });
        }
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
    deleteBooking: function(slot, confirm, askByAdmin, booking) {
      if (!confirm) {
        let messageText = 'Voulez-vous supprimer votre réservation du ' + slot.prettyDate + ' ' + ' ?';
        if (askByAdmin) {
          messageText = 'Voulez-vous supprimer la réservation de ' + booking.user.firstname + ' ' + booking.user.lastname + ' ?';
        }
        if (!askByAdmin && this.$parent.switchBookUserId) {
          messageText = 'Voulez-vous supprimer la réservation de ' + this.$parent.switchBookUserName + ' ?';
        }
        this.$bvModal.msgBoxConfirm(messageText, { okVariant: 'success', okTitle: 'Oui', cancelTitle: 'Non', hideHeaderClose: true, centered: true, noFade: true }).then((value) => {
            if (value) {
              this.deleteBooking(slot, true, askByAdmin, booking);
            }
          });
        return;
      }

      this.$parent.refresh = true;
      let json = {
        bookId: this.book.id,
        slotStartDate: slot.startDate,
        slotEndDate: slot.endDate,
        userId: this.$parent.userSession.user.id,
       };

      if (this.$parent.switchBookUserId) {
        json.userId = this.$parent.switchBookUserId;
      }

      if (askByAdmin) {
        json = {
          bookId: this.book.id,
          slotStartDate: booking.startDate,
          slotEndDate: booking.endDate,
          userId: booking.user.id,
         };
      }

      axios.post("/api/book-booking/delete-booking/", json)
      .then(response => {
        if(response.status == 200) {
          let p1 = this.getSlots();
          let p2 = this.getBookings();
          Promise.all([p1, p2]).then((responses) => {
            this.processGetSlotsResponse(responses[0]);
            this.processGetBookingsResponse(responses[1]);
            this.$parent.refresh = false;
          });
        }
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
    getBook: function() {
      return axios.get("/api/book/get-book/" + this.bookHash + "/");
    },
    processGetBookResponse: function(response) {
      this.book = response.data;
    },
    getBookings: function() {
      return axios.get("/api/book-booking/get-book-bookings/" + this.bookHash + "/" + this.currentFirstDateOfMonth.format("YYYY") + "/" + this.currentFirstDateOfMonth.format("MM") + "/");
    },
    processGetBookingsResponse: function(response) {
      this.bookings = response.data;

      for (let bookingKey in this.bookings) {
        let booking = this.bookings[bookingKey];
        let slotKey = booking.startDate +  ' ' + booking.endDate;
        this.slotsMap[slotKey].bookings.push(booking);
        if (booking.user.id == this.$parent.userSession.user.id) {
          booking.isMyBooking = true;
          this.slotsMap[slotKey].isBookedByUser = true;
        }

        if (this.$parent.switchBookUserId && this.$parent.switchBookUserId == booking.user.id) {
          this.slotsMap[slotKey].isBookedBySwitchUser = true;
        }
      }

    },
    getBookAllUsersRights: function() {
      return axios.get("/api/book-user-right/get-book-all-users-rights/" + this.bookHash + "/");
    },
    processGetBookAllUsersRightsResponse: function(response) {
      this.bookAllUsersRights = response.data;

      this.bookAllUsersRightsMap = new Map();
      for (let userRightKey in this.bookAllUsersRights) {
        let userRight = this.bookAllUsersRights[userRightKey];
        this.bookAllUsersRightsMap[userRight.userId] = userRight;
      }
    },
    getAllFlagsByBook: function() {
      return axios.get("/api/book-user-flag/get-all-flags-by-book/" + this.bookHash + "/");
    },
    processGetAllFlagsByBookResponse: function(response) {
      this.flags = response.data;
    },
    getSlots: function() {
      return axios.get("/api/book-slot/get-book-slots/" + this.bookHash + "/" + this.currentFirstDateOfMonth.format("YYYY") + "/" + this.currentFirstDateOfMonth.format("MM") + "/");
    },
    processGetSlotsResponse: function(response) {
      this.slots = response.data;

      this.slotsMap = new Map();
      let todayDate = moment();
      for (let slotKey in this.slots) {
        let slot = this.slots[slotKey];

        this.$set(slot, "isOpen", slot.status == 1);
        this.$set(slot, "isOpenNewStatus", slot.status == 1);

        this.slotsMap[slot.startDate + ' ' + slot.endDate] = slot;
        slot.bookings = [];
        slot.isBookedByUser = false;
        slot.isOld = moment(slot.startDate, "YYYY-MM-DD HH:mm:ss").isBefore(todayDate);

        let slotStartDate = moment(slot.startDate, "YYYY-MM-DD HH:mm:ss");
        let slotEndDate = moment(slot.endDate, "YYYY-MM-DD HH:mm:ss");
        slot.prettyDate = this.weekDayNames[slotStartDate.day()] + ' ' + slotStartDate.date() + ' ' + this.monthNames[slotStartDate.month()];
        slot.prettyTime = slotStartDate.format('HH:mm') + ' - ' + slotEndDate.format('HH:mm');
        slot.startDateUrlFormat = moment(slot.startDate, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD-HH-mm-ss");
        slot.endDateUrlFormat = moment(slot.endDate, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD-HH-mm-ss");
      }
    },
    loadNextMonth: function(goToTop) {
      this.$parent.refresh = true;
      this.slotSliceIndex = 0;
      this.currentFirstDateOfMonth.add(1, 'M').startOf('month');
      let p1 = this.getSlots();
      let p2 = this.getBookings();
      Promise.all([p1, p2]).then((responses) => {
        this.processGetSlotsResponse(responses[0]);
        this.processGetBookingsResponse(responses[1]);

        this.$parent.refresh = false;
        if (goToTop) {
          let container = this.$el.querySelector("#topPage");
          container.scrollIntoView();
        }
      });
    },
    loadPreviousMonth: function(goToTop) {
      this.$parent.refresh = true;
      this.slotSliceIndex = 0;
      this.currentFirstDateOfMonth.subtract(1, 'M').startOf('month');
      let p1 = this.getSlots();
      let p2 = this.getBookings();
      Promise.all([p1, p2]).then((responses) => {
        this.processGetSlotsResponse(responses[0]);
        this.processGetBookingsResponse(responses[1]);

        this.$parent.refresh = false;
        if (goToTop) {
          let container = this.$el.querySelector("#topPage");
          container.scrollIntoView();
        }
      });
    },
    loadNextPage: function() {
      this.slotSliceIndex = this.slotSliceIndex + this.numberOfSlotsPerPage;
      var container = this.$el.querySelector("#topPage");
      container.scrollIntoView();
    },
    loadPreviousPage: function() {
      this.slotSliceIndex = this.slotSliceIndex - this.numberOfSlotsPerPage;
    },
    updateSlotStatus: function(slot, confirm) {
      if (!confirm) {
        let message = 'Voulez-vous fermer le créneau du ' + slot.prettyDate + ' ' + ' ? ATTENTION Toutes les réservations du créneau seront supprimées !';

        if (slot.isOpenNewStatus == 1) {
          message = 'Voulez-vous ouvrir le créneau du ' + slot.prettyDate + ' ' + ' ?';
        }

        this.$bvModal.msgBoxConfirm(message, { okVariant: 'success', okTitle: 'Oui', cancelTitle: 'Non', hideHeaderClose: true, centered: true, noFade: true }).then((value) => {
            if (value) {
              this.updateSlotStatus(slot, true);
            } else {
              slot.isOpenNewStatus = slot.isOpen;
            }
          });
        return;
      }

      this.$parent.refresh = true;
      let json = {
        bookId: this.book.id,
        slotStartDate: slot.startDate,
        slotEndDate: slot.endDate,
        status: slot.isOpenNewStatus ? 1 : 2,
       };

      axios.post("/api/book-slot/update-slot-status/", json)
      .then(response => {
        if(response.status == 200) {
          let p1 = this.getSlots();
          let p2 = this.getBookings();
          Promise.all([p1, p2]).then((responses) => {
            this.processGetSlotsResponse(responses[0]);
            this.processGetBookingsResponse(responses[1]);
            this.$parent.refresh = false;
          });
        }
      }).catch(error => {
        slot.isOpenNewStatus = slot.isOpen;
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
  },
  beforeMount() {
    this.$parent.init();
    this.bookHash = this.$route.query.hash;

    let p1 = this.getBook();
    let p2 = this.getSlots();
    let p3 = this.getBookings();
    let p4 = this.getBookAllUsersRights();
    let p5 = this.getAllFlagsByBook();
    Promise.all([p1, p2, p3, p4, p5]).then((responses) => {
      this.processGetBookResponse(responses[0]);
      this.processGetSlotsResponse(responses[1]);
      this.processGetBookingsResponse(responses[2]);
      this.processGetBookAllUsersRightsResponse(responses[3]);
      this.processGetAllFlagsByBookResponse(responses[4]);
      document.title = this.$parent.websiteName + " - " + this.book.name;
      this.$parent.loaded = true;
    }).catch(()=>{
      this.$router.push('/error.html');
    });
  }
}
</script>
