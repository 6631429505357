<template>
<div class="container-fluid flex-fill" v-if="$parent.loaded">

  <b-breadcrumb>
    <b-breadcrumb-item to="/"><b-img class="mb-1" width="24" height="24" src="/static/images/logo.png" alt="logo"></b-img></b-breadcrumb-item>
    <b-breadcrumb-item :to="'/club-dashboard.html?clubId=' + clubNews.clubId" ><office-building-icon/></b-breadcrumb-item>
    <b-breadcrumb-item active class="font-weight-bold"><message-outline-icon/> Commentaires Actualité</b-breadcrumb-item>
  </b-breadcrumb>

  <div class="alert-secondary message mb-4">
    <div v-html="clubNews.text" class="inner-html text-break"></div>
    <div class="small text-muted text-right mr-2">{{ clubNews.creationDate }}</div>
  </div>

  <div class="alert alert-secondary mb-0 text-center h5 mb-4" role="alert" v-if="comments.length == 0">
    Pas de commentaires. Soyez le premier à commenter.
  </div>

  <b-pagination v-if="clubNews.numberOfComments > numberOfRowsPerPage" v-model="currentPage" :total-rows="clubNews.numberOfComments" :per-page="numberOfRowsPerPage" first-number align="center" @change="loadComments"></b-pagination>

  <div class="message mb-4" :class="commentIndex % 2 == 0 ? 'message-even' : 'message-odd'" v-for="(comment,commentIndex) in comments" :key="commentIndex">
    <span class="font-weight-bold">{{ comment.user.firstname }} {{ comment.user.lastname }}</span>
    <span v-if="comment.user.hash != $parent.userSession.user.hash"><router-link class="text-dark ml-2" title="message privé" :to="'/private-message.html?userHash=' + comment.user.hash"><message-outline-icon/></router-link></span>
    <hr class="mt-1 mb-1">
    <div v-html="comment.text" class="inner-html text-break"></div>
    <div class="small text-muted text-right mr-2">{{ comment.creationDate }}</div>
  </div>

  <b-pagination v-if="clubNews.numberOfComments > numberOfRowsPerPage" v-model="currentPage" :total-rows="clubNews.numberOfComments" :per-page="numberOfRowsPerPage" first-number align="center" @change="loadComments"></b-pagination>

  <div>
    <div style="display: flex" class="mt-0">
      <div style="display: flex; width: 100%">
        <b-form-textarea style="background-color: #DDDDDD;" id="new-message" v-model="newComment" rows="3" max-rows="3"></b-form-textarea>
      </div>
      <div style="display: flex" class="ml-2">
        <b-button variant="success" size="lg" v-on:click="addComment()" :disabled="newComment.length == 0"><send-icon/></b-button>
      </div>
    </div>
  </div>

</div>
</template>

<script>
import axios from 'axios'

export default {
  data: function () {
    return {
      clubNews: undefined,
      clubNewsId: undefined,
      comments: [],
      newComment: '',
      currentPage: 1,
      numberOfRowsPerPage: 20,
      lastCommentId: 0,
    }
  },
  methods: {
    addComment: function() {
      if (this.newComment.length == 0) {
        return;
      }
      this.$parent.refresh = true;
      let newCommentHtml = '<p>' + this.newComment.replaceAll('\n', '</p><p>') + '</p>';

      var json = {
        clubNewsId: this.clubNewsId,
        text: newCommentHtml,
        userId: this.$parent.userSession.user.id,
       };

      axios.post("/api/club-news-comment/add-club-news-comment/", json)
      .then(() => {
        let p1 = this.getClubNews();
        let p2 = this.getClubNewsComments();
        Promise.all([p1, p2]).then((responses) => {
          this.processGetClubNewsResponse(responses[0]);
          this.processGetClubNewsCommentsResponse(responses[1]);
          this.newComment = '';
          this.$parent.refresh = false;
        });
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
    loadComments: function(pageNum) {
      this.$parent.refresh = true;
      this.lastCommentId = (pageNum - 1) * this.numberOfRowsPerPage;
      this.getClubNewsComments().then(response => {
        this.processGetClubNewsCommentsResponse(response);
        this.$parent.refresh = false;
      });
    },
    getClubNews: function() {
      return axios.get("/api/club-news/get-single-club-news/" + this.clubNewsId + "/");
    },
    processGetClubNewsResponse: function(response) {
      this.clubNews = response.data;
    },
    getClubNewsComments: function() {
      return axios.get("/api/club-news-comment/get-club-news-comments/" + this.clubNewsId + "/" + this.lastCommentId + "/");
    },
    processGetClubNewsCommentsResponse: function(response) {
      this.comments = response.data;
    },
  },
  beforeMount() {
    this.$parent.init();
    this.clubNewsId = this.$route.query.clubNewsId;

    let p1 = this.getClubNews();
    let p2 = this.getClubNewsComments();
    Promise.all([p1, p2]).then((responses) => {
      this.processGetClubNewsResponse(responses[0]);
      this.processGetClubNewsCommentsResponse(responses[1]);
      document.title = this.$parent.websiteName + " - Commentaires Actualité " + this.clubNewsId;
      this.$parent.loaded = true;
    }).catch(()=>{
      this.$router.push('/error.html');
    });

  }
}
</script>
