<template>
<div class="container-fluid flex-fill help" v-if="$parent.loaded">

  <b-breadcrumb>
    <b-breadcrumb-item to="/"><b-img class="mb-1" width="32" height="32" src="/static/images/logo.png" alt="logo"></b-img></b-breadcrumb-item>
    <b-breadcrumb-item active class="font-weight-bold">FAQ</b-breadcrumb-item>
  </b-breadcrumb>

  <p>Sur cette page, vous retrouverez les questions les plus courantes. Si vous avez d'autres questions ou des remarques, n'hésitez par à utiliser le formulaire de <router-link to="/contact.html">contact</router-link>.</p>

  <h2>A quoi sert {{ $parent.websiteName }} ?</h2>
  <p><em>{{ $parent.websiteName }}</em> est un site qui permet de faciliter la gestion de votre association. Les fonctionnalités du site sont en évolution permanente, en fonction des utilisations et des souhaits.</p>

  <h2>Quelle est la vision du site ?</h2>
  <p>La vision du site <em>{{ $parent.websiteName }}</em> est d'avoir un site avec les fonctionnalités qui peuvent vous aider au mieux dans les tâches les plus courantes ou les plus fastidieuses dans la gestion d'une association. Ce site s'inscrit dans une <u>méthodologie d'amélioration continue</u>. Vous avez trouvé un bug ? Super, on va le corriger. Vous souhaitez une nouvelle fonctionnalité ? On va regarder si on peut l'implémenter. Il y a un truc qui va pas ? Expliquez-nous le souci, on va regarder ça.</p>
  <p>Nous avons déjà quelques idées pour de futures fonctionnalités comme la gestion de prêt de matériel, la communication autour de vos adhérents,... N'hésitez pas à nous remonter toutes vos remarques via le formulaire de contact.</p>

  <h2>Est-ce gratuit ?</h2>
  <p><em>{{ $parent.websiteName }}</em> est entièrement gratuit et le restera. Il n'y a pas de version gratuite avec des fonctionnalités dégradées ou bridées, de version payante avec plus de fonctionnalités, de frais caché, d'une vision <i>attrape nigauds</i> comme faire une version gratuite puis la rendre payante une fois qu'il y a suffisamment de monde qui l'utilise. Tout est transparent et accessible pour tout le monde tout le temps.</p>
  <p>On entend souvent <i>"Si c'est gratuit, c'est toi le produit"</i>. Ce n'est pas le cas sur <em>{{ $parent.websiteName }}</em>. Les données ne sont pas revendues à des tiers, ni utilisés par des réseau sociaux via des fonctionnalités détournées.</p>
  <p>Mais dans ce cas quel est l'intérêt de <em>{{ $parent.websiteName }}</em> ? Cela coûte du temps à le maintenir et peut être de l'argent ? Oui le développement de <em>{{ $parent.websiteName }}</em> nécessite du temps, beaucoup de temps... Pour donner un ordre d'idée, la première version de la fonctionnalité <i>Réservation</i> a nécessité une centaine d'heure de travail. L'hébergement du site à l'année coute pour l'instant environ 150€. Vous souhaitez participer à son financement et donner une rétribution aux créateurs pour leur temps passé à faire le site, vous pouvez faire un <router-link to="/donate.html">don</router-link>, nous vous en serons très reconnaissant. Grâce à vous nous pourrons continuer à maintenir le site manière plus fréquente. Les associations ou les utilisateurs qui nous soutiennent sont sur notre page de <router-link to="/thanks.html">remerciements</router-link>.</p>

  <h2>Comment est fait {{ $parent.websiteName }} ?</h2>
  <p>{{ $parent.websiteName }} est composé d'un backend et d'un client web. Le backend est entièrement écrit en Java, il repose sur Vertx pour la gestion des requêtes API. Tout le reste du backend est écrit à la main et ne repose sur aucun framework. Le but est d'avoir un backend le plus léger possible et le plus performant. Le client web est écrit avec VueJS. Les données sont stockées dans une base PostgreSQL. Il y a un cache Varnish qui cache la plupart des requêtes GET. Aussi, le backend à possède un cache applicatif, pour délester la base en requêtes.</p>
  <p>De même que la vision fonctionnelle, <em>{{ $parent.websiteName }}</em> s'inscrit aussi dans une vision technique. Chaque fonctionnalité est étudiée et doit s'inscrire une simplicité d'implémentation et dans une vision de performance. Bien que tout n'est pas parfait, il est parfois nécessaire de revoir l'implémentation, de refactorer certaines parties, d'abandonner une implémentation pour une autre, ou de rajouter une batterie de test...</p>
  <p>Si vous avez des remarques techniques, n'hésitez pas.</p>

</div>
</template>

<script>
export default {
  data: function () {
    return {

    }
  },
  methods: {
  },
  beforeMount() {
    this.$parent.init();
    document.title = this.$parent.websiteName + " - FAQ";

    this.$parent.loaded = true;
  }
}
</script>
