<template>
<div class="container-fluid flex-fill" v-if="$parent.loaded">

  <b-breadcrumb>
    <b-breadcrumb-item to="/"><b-img class="mb-1" width="24" height="24" src="/static/images/logo.png" alt="logo"></b-img></b-breadcrumb-item>
    <b-breadcrumb-item active class="font-weight-bold"><office-building-icon/> {{ this.club.name }}</b-breadcrumb-item>
  </b-breadcrumb>

  <b-navbar toggleable="sm" type="dark" variant="dark" v-if="club.isAdmin" >
    <b-navbar-toggle target="nav-text-collapse"></b-navbar-toggle>

   <b-collapse id="nav-text-collapse" is-nav>
      <b-navbar-nav>
        <b-nav>

          <b-nav-item-dropdown>
            <template v-slot:button-content>
              <newspaper-icon/> Actualités
            </template>
            <b-dropdown-item :to="'/club-admin-news.html?clubId=' + clubId"><newspaper-icon/> Gestion des actualités</b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown>
            <template v-slot:button-content>
              <account-icon/> Adhérents
            </template>
            <b-dropdown-item :to="'/club-admin-users.html?clubId=' + clubId"><account-multiple-icon/> Liste des adhérents</b-dropdown-item>
            <b-dropdown-item :to="'/club-admin-user-tags.html?clubId=' + clubId"><tag-icon/> Liste des tags</b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown>
            <template v-slot:button-content>
              <forum-icon/> Forums
            </template>
            <b-dropdown-item :to="'/club-admin-forum-category.html?clubId=' + clubId"><shape-icon/> Gestion des catégories</b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown>
            <template v-slot:button-content>
              <office-building-icon/> Infos Asso
            </template>
            <b-dropdown-item :to="'/club-admin-edit-information.html?clubId=' + clubId"><pencil-icon/> Editer informations</b-dropdown-item>
            <b-dropdown-item :to="'/club-admin-pages.html?clubId=' + clubId"><text-box-icon/> Liste des pages</b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown>
            <template v-slot:button-content>
              <package-variant-closed-icon/> Materiels
            </template>
            <b-dropdown-item :to="'/club-admin-equipment.html?clubId=' + clubId"><package-variant-closed-icon/> Gestion des matériels</b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown>
            <template v-slot:button-content>
              <book-icon/> Registres
            </template>
            <b-dropdown-item id="add-book-link" :to="'/add-book.html?clubid=' + club.id"><plus-circle-outline-icon/> Créer un nouveau registre</b-dropdown-item>
            <b-dropdown-item :to="'/club-admin-books.html?clubId=' + clubId"><book-icon/> Liste des registres</b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown>
            <template v-slot:button-content>
              <message-icon/> Salons
            </template>
            <b-dropdown-item :to="'/club-admin-group-talk.html?clubId=' + clubId"><message-icon/> Gestion des salons</b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown>
            <template v-slot:button-content>
              <poll-icon/> Sondages
            </template>
            <b-dropdown-item :to="'/club-admin-poll.html?clubId=' + clubId"><poll-icon/> Gestion des sondages</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-nav>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>

  <b-card no-body>
    <b-tabs v-model="$parent.clubDashboardSeletedTab" card>

      <b-tab>
        <template v-slot:title>
          <newspaper-icon/><span class="d-none d-md-inline"> Actualités</span>
        </template>
        <div class="alert alert-secondary mb-0 text-center h5" role="alert" v-if="newsList.length == 0">
          Aucune actualité
        </div>
        <b-card-group columns v-if="newsList.length >= 0">
          <b-card bg-variant="light" v-for="(news, newsIndex) in newsList" :key="newsIndex">
            <b-card-body>
              <b-card-text>
                <div class="inner-html" v-html="news.text"></div>
              </b-card-text>
              <b-card-text class="text-right small text-muted">
                <span class="mr-2"><router-link class="text-dark" :to="'/club-news-comments.html?clubNewsId=' + news.id"><message-outline-icon/> {{ news.numberOfComments }}</router-link></span>
                <span class="mr-2" title="actualité publique" v-if="news.isPublic"><earth-icon/></span>
                <span>{{ news.creationDate }}</span>
              </b-card-text>
            </b-card-body>
          </b-card>
        </b-card-group>
      </b-tab>

      <b-tab v-if="users && users.length > 0">
        <template v-slot:title>
          <account-multiple-icon/><span class="d-none d-md-inline"> Adhérents</span>
        </template>

        <b-row class="mb-4">
          <b-col cols="12" md="6">
            <b-input-group>
              <b-form-input type="search" v-model="searchUsersText" placeholder="Chercher des adhérents"></b-form-input>
              <template v-slot:append>
                <b-button v-on:click="refresh()"><refresh-icon/></b-button>
              </template>
            </b-input-group>
          </b-col>
        </b-row>

        <b-pagination class="m-0" v-model="currentUserPage" :total-rows="users.length" :per-page="numberOfUsersPerPage" first-number align="center" v-if="numberOfUsersPerPage < users.length"></b-pagination>

        <div class="small text-muted text-center mt-2 mb-2"><span class="mr-2"><earth-off-icon/> utilisateur hors ligne</span> <span><crown-icon/> administrateur</span></div>

        <b-row align-v="center" :class="{'even-line-background' : userIndex % 2 == 1}" class="p-2" v-for="(user, userIndex) in searchUsers.slice((currentUserPage - 1) * numberOfUsersPerPage, (currentUserPage - 1) * numberOfUsersPerPage + numberOfUsersPerPage)" :key="userIndex">
          <b-col cols="12" class="p-2">
            <span class="text-break">{{ user.firstname }} {{ user.lastname }}</span>
            <span class="ml-2"><earth-off-icon class="text-secondary" v-if="user.isFake"/><crown-icon class="text-secondary" v-if="user.isAdmin"/></span>
            <span class="ml-2" v-if="!user.isFake">
              <router-link v-if="activeUsersMap[user.id] == undefined" class="text-dark" title="message privé" :to="'/private-message.html?userHash=' + user.hash"><message-outline-icon/></router-link>
              <router-link v-if="activeUsersMap[user.id] != undefined" class="text-success" title="message privé" :to="'/private-message.html?userHash=' + user.hash"><message-icon/></router-link>
            </span>
          </b-col>
        </b-row>

        <b-pagination class="mt-4 mb-0" v-model="currentUserPage" :total-rows="users.length" :per-page="numberOfUsersPerPage" first-number align="center" v-if="numberOfUsersPerPage < users.length"></b-pagination>

      </b-tab>

      <b-tab v-if="forumCategories && forumCategories.length > 0">
        <template v-slot:title>
          <forum-icon/><span class="d-none d-md-inline"> Forums</span>
        </template>

        <b-row align-v="center" :class="{'even-line-background' : forumCategoryIndex % 2 == 1}" class="p-2" v-for="(forumCategory, forumCategoryIndex) in forumCategories" :key="forumCategoryIndex">
          <b-col cols="12" class="p-2">
            <router-link :to="'/club-forum-category.html?forumCategoryId=' + forumCategory.id + '&clubId=' + forumCategory.clubId" :id="'forum-category-' + forumCategoryIndex"><span class="text-break font-weight-bold text-uppercase">{{ forumCategory.name }}</span></router-link>
            <span class="ml-4 small text-muted">{{ forumCategory.numberOfThreads }} sujets</span>
          </b-col>
        </b-row>
      </b-tab>

      <b-tab>
        <template v-slot:title>
          <office-building-icon/><span class="d-none d-md-inline"> Infos Asso</span>
        </template>
        <p>{{ club.address }}<br/>{{ club.zipCode }} {{ club.city }}</p>
        <p v-if="club.phoneNumber">
          <phone-classic-icon/> {{ club.phoneNumber }}
        </p>

        <ul class="pl-3 mb-4">
          <li>
            <account-multiple-icon/> {{ club.numberOfVerifiedUsers }} adhérents
          </li>
          <li>
            <book-icon/> {{ club.numberOfBooks }} registres
          </li>
          <li>
            <newspaper-icon/> {{ club.numberOfNews }} Actualités
          </li>
          <li>
            <message-icon/> {{ club.numberOfGroupTalks }} salons
          </li>
          <li>
            <router-link target="_blank" :to="'/club-public-page.html?clubId=' + club.id"><earth-icon/> voir le site de l'asso</router-link>
            <span class="text-muted ml-2 small"><information-outline-icon/> {{ $parent.websiteName }} génére un site visible par tous les internautes, où les informations publiques sont publiées.</span>
          </li>
        </ul>

        <b-row v-if="publicPages && publicPages.length > 0" align-v="center" class="text-uppercase font-weight-bold text-white bg-secondary p-2">
          <b-col cols="12" class="p-2">
            Pages publiques
          </b-col>
        </b-row>

        <div>
        <b-row align-v="center" :class="{'even-line-background' : pageIndex % 2 == 1}" class="p-2" v-for="(page, pageIndex) in publicPages" :key="pageIndex">
          <b-col cols="12" class="p-2">
            <router-link :to="'/club-page.html?pageId=' + page.id"><span class="text-break font-weight-bold text-uppercase">{{ page.title }}</span></router-link>
          </b-col>
        </b-row>
        </div>

        <b-row v-if="privatePages && privatePages.length > 0" align-v="center" class="text-uppercase font-weight-bold text-white bg-secondary p-2">
          <b-col cols="12" class="p-2">
            Pages privées
          </b-col>
        </b-row>

        <div>
        <b-row align-v="center" :class="{'even-line-background' : pageIndex % 2 == 1}" class="p-2" v-for="(page, pageIndex) in privatePages" :key="pageIndex">
          <b-col cols="12" class="p-2">
            <router-link :to="'/club-page.html?pageId=' + page.id"><span class="text-break font-weight-bold text-uppercase">{{ page.title }}</span></router-link>
          </b-col>
        </b-row>
        </div>
      </b-tab>

      <b-tab v-if="myBooks && myBooks.length > 0">
        <template v-slot:title>
          <book-icon/><span class="d-none d-md-inline"> Registres</span>
        </template>

        <b-row align-v="center" :class="{'even-line-background' : bookIndex % 2 == 1}" class="p-2" v-for="(book, bookIndex) in myBooks" :key="bookIndex">
          <b-col cols="12" class="font-weight-bold text-uppercase p-2">
            <router-link class="text-dark" :to="'/book.html?hash=' + book.hash">{{ book.name }}</router-link>
          </b-col>
        </b-row>

        <div class="mt-2">
          <router-link :to="'/books.html?clubId=' + club.id"><book-multiple-icon/> Voir tous les créneaux de tous les registres</router-link>
        </div>
      </b-tab>

      <b-tab v-if="groupTalks && groupTalks.length > 0">
        <template v-slot:title>
          <span :class="{'text-warning' : unreadGroupTalk}"><message-icon/></span><span class="d-none d-md-inline"> Salons</span>
        </template>

        <b-card-group columns v-if="groupTalks.length > 0">
          <b-card bg-variant="light" v-for="(groupTalk, groupTalkIndex) in groupTalks" :key="groupTalkIndex">
            <template v-slot:header>
              <router-link :to="'/club-group-talk.html?groupTalkId=' + groupTalk.id" :id="'group-talk-' + groupTalkIndex"><div class="text-break">{{ groupTalk.name }}</div></router-link>
            </template>
            <b-card-text>
              <ul class="pl-3">
                <li>
                  <span><b>Nombre de message</b>: {{ groupTalk.numberOfMessages }}</span>
                  <span class="ml-2 text-warning" v-if="(groupTalk.lastMessageId && $parent.lastGroupTalkMessageIdMap.get(''+groupTalk.id) && groupTalk.lastMessageId > $parent.lastGroupTalkMessageIdMap.get(''+groupTalk.id)) || (groupTalk.lastMessageId && !$parent.lastGroupTalkMessageIdMap.get(''+groupTalk.id))"> <message-icon title="nouveau message"/></span>
                </li>
                <li v-if="groupTalk.lastMessageDate">
                  <b>Date du dernier message</b>: {{ groupTalk.lastMessageDate }}
                </li>
              </ul>
            </b-card-text>
          </b-card>
        </b-card-group>
      </b-tab>

      <b-tab>
        <template v-slot:title>
          <poll-icon/><span class="d-none d-md-inline"> Sondages</span>
        </template>

        <div class="alert alert-secondary mb-4" role="alert">
          Vous pouvez <router-link :to="'/club-add-poll.html?clubId=' + club.id">créér votre sondage</router-link>. Chaque sondage avant publication auprès des adhérents, est soumis à validation auprès de l'administrateur de l'asso.
        </div>

        <b-card-group columns v-if="polls.length > 0">
          <b-card bg-variant="light" v-for="(poll, pollIndex) in polls" :key="pollIndex">
            <template v-slot:header>
              {{ poll.name }}
            </template>
            <b-card-text>
              <div class="text-muted text-break" v-html="poll.description"></div>

              <b-row align-v="center" class="mt-2" v-for="(option, optionIndex) in poll.options" :key="optionIndex">
                <b-col cols="9" sm="10" class="mb-3">
                    <div class="text-break">{{option.name}}</div>
                    <b-progress max="100" variant="secondary" v-if="poll.numberOfVotes != 0">
                      <b-progress-bar :value="(100 * option.numberOfVotes / poll.numberOfVotes).toFixed(0)">
                          <span class="text-white"><strong>{{ (100 * option.numberOfVotes / poll.numberOfVotes).toFixed(0) }}%</strong></span>
                      </b-progress-bar>
                    </b-progress>
                    <b-progress max="100" :value="0" variant="secondary" v-if="poll.numberOfVotes == 0">
                    </b-progress>
                </b-col>

                <b-col cols="3" sm="2" class="text-right">
                <b-button variant="outline-dark" size="sm" v-on:click="votePoll(poll.id, option.id)">vote</b-button>
                </b-col>
              </b-row>

              <div class="text-right small text-muted mt-2">
                <span class="mr-2"><router-link class="text-dark" :to="'/club-poll-comments.html?clubPollId=' + poll.id"><message-outline-icon/> {{ poll.numberOfComments }}</router-link></span>
                <span>Nombre de votes: {{ poll.numberOfVotes }}</span>
              </div>
            </b-card-text>
          </b-card>
        </b-card-group>

      </b-tab>

    </b-tabs>
  </b-card>

</div>
</template>

<script>
import axios from 'axios'

export default {
  data: function () {
    return {
      books: [],
      club: undefined,
      clubId: undefined,
      currentUserPage: 1,
      forumCategories: [],
      groupTalks: [],
      newsList: [],
      publicPages: [],
      privatePages: [],
      polls: [],
      searchUsersText: '',
      unreadGroupTalk: false,
      numberOfUsersPerPage: 50,
      users: [],
      activeUsers: [],
      activeUsersMap: new Map(),
    }
  },
  computed: {
    myBooks: function () {
      return this.books.filter(row => {
        return this.$parent.bookRightsMap[row.id] && this.$parent.bookRightsMap[row.id].right >= 0;
      });
    },
    searchUsers: function () {
      let searchTextLC = this.searchUsersText.toLowerCase();
      var searchUsers = this.users.filter(row => {
        let filterOnName = (this.searchUsersText != undefined && this.searchUsersText.length >= 2);

        let filterOnNameIsOK = true;

        if (filterOnName) {
          filterOnNameIsOK = (row.lastname.toLowerCase().includes(searchTextLC) || row.firstname.toLowerCase().includes(searchTextLC));
        }

        return filterOnNameIsOK;
      });
      return searchUsers;
    },
  },
  methods: {
    deleteGroupTalk: function(groupTalk, confirm) {
      if (!confirm) {

        this.$bvModal.msgBoxConfirm('Voulez-vous supprimer le groupe de discussion ' + groupTalk.name + ' ?' , { okVariant: 'success', okTitle: 'Oui', cancelTitle: 'Non', hideHeaderClose: true, centered: true, noFade: true }).then((value) => {
            if (value) {
              this.deleteGroupTalk(groupTalk, true);
            }
          });
        return;
      }
      this.$parent.refresh = true;

      var json = {
        groupTalkId: groupTalk.id,
       };

      axios.post("/api/group-talk/delete-group-talk/", json)
      .then(() => {
        this.getGroupTalk().then(response => {
          this.processGetGroupTalkResponse(response);
          this.$parent.refresh = false;
        });
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
    getActiveUsers: function() {
      return axios.get("/api/user-session/get-active-users/");
    },
    processGetActiveUsersResponse: function(response) {
      this.activeUsers = response.data;
      this.activeUsersMap = new Map();
      for (let activeUser of this.activeUsers) {
        this.activeUsersMap[activeUser.userId] = activeUser;
      }
    },
    getClubBooks: function() {
      return axios.get("/api/book/get-club-books/" + this.clubId + "/");
    },
    processGetClubBooksResponse: function(response) {
      this.books = response.data;
    },
    getClubForumCategories: function() {
      return axios.get("/api/club-forum-category/get-club-forum-categories/" + this.clubId + "/");
    },
    processGetClubForumCategoriesResponse: function(response) {
      this.forumCategories = response.data;
    },
    getGroupTalk: function() {
      return axios.get("/api/group-talk/get-group-talks/" + this.clubId + "/");
    },
    processGetGroupTalkResponse: function(response) {
      this.groupTalks = response.data;
    },
    getNews: function() {
      return axios.get("/api/club-news/get-club-news/" + this.clubId + "/");
    },
    processGetNewsResponse: function(response) {
      this.newsList = response.data;
    },
    getPages: function() {
      return axios.get("/api/club-page/get-club-pages/" + this.clubId + "/");
    },
    processGetPagesResponse: function(response) {
      let pages = response.data;
      this.publicPages = [];
      this.privatePages = [];
      for (let page of pages) {
        if (page.isPublic) {
          this.publicPages.push(page);
        } else {
          this.privatePages.push(page);
        }
      }
    },
    getPolls: function() {
      return axios.get("/api/club-poll/get-club-polls-with-options/" + this.clubId + "/");
    },
    processGetPollsResponse: function(response) {
      this.polls = response.data;
    },
    getUsers: function() {
      return axios.get("/api/club-user/get-users/" + this.clubId + "/");
    },
    processGetUsersResponse: function(response) {
      this.users = response.data;
    },
    votePoll: function(pollId, pollOptionId) {
      this.$parent.refresh = true;

      var json = {
        pollId: pollId,
        pollOptionId: pollOptionId,
        userId: this.$parent.userSession.user.id,
       };

      axios.post("/api/club-poll/vote-club-poll/", json)
      .then(() => {
        this.$bvModal.msgBoxOk("Votre vote a bien été pris en compte. Le sondage se mettra à jour dans quelques instants. Vous pouvez changer votre vote à tout moment.", { centered: true, noFade: true });
        this.$parent.refresh = false;
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
  },
  beforeMount() {
    this.$parent.init();
    this.clubId = this.$route.query.clubId;

    if (!this.$parent.myUserClubMap[this.clubId]) {
      this.$router.push('/error.html');
    }

    this.club = this.$parent.myUserClubMap[this.clubId].club;
    document.title = this.$parent.websiteName + " - Tableau de bord " + this.club.name;

    let p1 = this.getNews();
    let p2 = this.getClubBooks();
    let p3 = this.getGroupTalk();
    let p4 = this.$parent.getUserSettings(this.$parent.userSession.user.hash);
    let p5 = this.getClubForumCategories();
    let p6 = this.getUsers();
    let p7 = this.getPolls();
    let p8 = this.getActiveUsers();
    let p9 = this.getPages();
    Promise.all([p1, p2, p3, p4, p5, p6, p7, p8, p9]).then((responses) => {
      this.processGetNewsResponse(responses[0]);
      this.processGetClubBooksResponse(responses[1]);
      this.processGetGroupTalkResponse(responses[2]);
      this.$parent.processGetUserSettingsResponse(responses[3]);
      this.processGetClubForumCategoriesResponse(responses[4]);
      this.processGetUsersResponse(responses[5]);
      this.processGetPollsResponse(responses[6]);
      this.processGetActiveUsersResponse(responses[7]);
      this.processGetPagesResponse(responses[8]);

      for (let groupTalk of this.groupTalks) {
        if ((groupTalk.lastMessageId && this.$parent.lastGroupTalkMessageIdMap.get(''+groupTalk.id) && groupTalk.lastMessageId > this.$parent.lastGroupTalkMessageIdMap.get('' + groupTalk.id)) || (groupTalk.lastMessageId && !this.$parent.lastGroupTalkMessageIdMap.get(''+groupTalk.id))) {
          this.unreadGroupTalk = true;
          break;
        }
      }

      this.$parent.loaded = true;
    }).catch((error)=>{
      console.log(error);
      // this.$router.push('/error.html');
    });
  }
}
</script>
