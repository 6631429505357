<template>
<div class="container-fluid flex-fill" v-if="$parent.loaded">

  <b-breadcrumb>
    <b-breadcrumb-item to="/"><b-img class="mb-1" width="32" height="32" src="/static/images/logo.png" alt="logo"></b-img></b-breadcrumb-item>
    <b-breadcrumb-item :to="'/club-dashboard.html?clubId=' + clubId" ><office-building-icon/></b-breadcrumb-item>
    <b-breadcrumb-item active class="font-weight-bold">Les tags adhérents</b-breadcrumb-item>
  </b-breadcrumb>

  <b-card no-body>
    <b-tabs card>
      <b-tab>
        <template v-slot:title>
          <newspaper-icon/> Les tags
        </template>

        <div class="alert alert-secondary mb-0 text-center h5" role="alert" v-if="tags.length == 0">
          Aucun tag trouvé.
        </div>

        <div v-if="tags.length > numberOfRowsPerPage">
          <b-pagination v-model="currentPage" :total-rows="tags.length" :per-page="numberOfRowsPerPage" first-number align="center"></b-pagination>
        </div>

        <b-row align-v="center" :class="{'even-line-background' : tagIndex % 2 == 1}" class="p-2" v-for="(tag, tagIndex) in tags.slice((currentPage - 1) * numberOfRowsPerPage, (currentPage - 1) * numberOfRowsPerPage + numberOfRowsPerPage)" :key="tagIndex">
          <b-col cols="12">

            <b-input-group class="mt-4">
              <template #prepend>
                  <b-input-group-text class="font-weight-bold">Nom</b-input-group-text>
              </template>
              <b-form-input v-model="tag.name" type="text"></b-form-input>
            </b-input-group>
            <b-form-text>20 caractères maximum</b-form-text>

            <b-form-group class="mt-2 mb-0">
              <quilleditor ref="quilleditor" :id="'newsText-' + tagIndex" v-model="tag.description"></quilleditor>
            </b-form-group>

            <div class="text-center mt-4">
              <b-button variant="success" class="mr-2" v-on:click="updateTag(tag);"><content-save-icon/> Mettre à jour</b-button>
              <b-button variant="danger" v-on:click="deleteTag(tag.id, false);"><delete-icon/> Supprimer</b-button>
            </div>
          </b-col>
        </b-row>
      </b-tab>

      <b-tab>
        <template v-slot:title>
          <plus-circle-outline-icon/> Ajout d'un tag
        </template>

        <b-input-group >
          <template #prepend>
              <b-input-group-text class="font-weight-bold">Nom</b-input-group-text>
          </template>
          <b-form-input v-model="newTag.name" type="text"></b-form-input>
        </b-input-group>
        <b-form-text>20 caractères maximum</b-form-text>

        <b-form-group class="mt-4 mb-0" label-class="font-weight-bold">
          <quilleditor ref="quilleditor" id="newsText" v-model="newTag.description"></quilleditor>
        </b-form-group>

        <b-row class="text-center mt-4">
          <b-col sm="12">
            <b-button variant="success" v-on:click="addTag()" :disabled="newTag.name && newTag.name > 0">Ajouter le tag</b-button>
          </b-col>
        </b-row>

      </b-tab>
    </b-tabs>
  </b-card>
</div>
</template>

<script>
import axios from 'axios'
import quilleditor from '@/components/QuillEditor.vue'

export default {
  components: {
    quilleditor
  },
  data: function () {
    return {
      tags: [],
      club: undefined,
      clubId: undefined,
      newTag: {name: '', description: ''},
      numberOfRowsPerPage: 20,
      currentPage: 1,
    }
  },
  methods: {
    addTag: function() {
      var json = {
        clubId: this.club.id,
        name: this.newTag.name,
        description: this.newTag.description,
       };

      axios.post("/api/club-user-tag/add-club-user-tag/", json)
      .then(() => {
        this.getTags().then(response => {
          this.processGetTagsResponse(response);
          this.$bvModal.msgBoxOk("Le tag a été ajouté.", { centered: true, noFade: true });
          this.newTag = {name: '', description: ''};
          this.$parent.refresh = false;
        });
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
    deleteTag: function(tagId, confirm) {
      if (!confirm) {

        this.$bvModal.msgBoxConfirm('Voulez-vous supprimer le tag ?' , { okVariant: 'success', okTitle: 'Oui', cancelTitle: 'Non', hideHeaderClose: true, centered: true, noFade: true }).then((value) => {
            if (value) {
              this.deleteTag(tagId, true);
            }
          });
        return;
      }
      this.$parent.refresh = true;

      var json = {
        clubId: this.club.id,
        tagId: tagId,
       };

      axios.post("/api/club-user-tag/delete-club-user-tag/", json)
      .then(() => {
        this.getTags().then(response => {
          this.processGetTagsResponse(response);
          this.$bvModal.msgBoxOk("Le tag a été supprimé.", { centered: true, noFade: true });
          this.$parent.refresh = false;
        });
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
    getTags: function() {
      return axios.get("/api/club-user-tag/get-club-user-tags/" + this.clubId + "/");
    },
    processGetTagsResponse: function(response) {
      this.tags = response.data;
    },
    updateTag: function(tag) {
      var json = {
        tagId: tag.id,
        clubId: this.club.id,
        name: tag.name,
        description: tag.description
       };

      axios.post("/api/club-user-tag/update-club-user-tag/", json)
      .then(() => {
        this.getTags().then(response => {
          this.processGetTagsResponse(response);
          this.$bvModal.msgBoxOk("Le tag a été mis à jour.", { centered: true, noFade: true });
          this.$parent.refresh = false;
        });
      }).catch(error => {
        this.$parent.displayErrorMessage(error);
        this.$parent.refresh = false;
      });
    },
  },
  beforeMount() {
    this.$parent.init();
    this.clubId = this.$route.query.clubId;
    this.club = this.$parent.myUserClubMap[this.clubId].club;

    let p1 = this.getTags();
    Promise.all([p1]).then((responses) => {
      this.processGetTagsResponse(responses[0]);
      document.title = this.$parent.websiteName + " - Gestion des tags de l'asso " + this.club.name;
      this.$parent.loaded = true;
    }).catch((error)=>{
      console.log(error);
      // this.$router.push('/error.html');
    });
  }
}
</script>
