<template>
  <div></div>
</template>

<script>
import axios from 'axios'

export default {
  methods: {
    logout () {
      let json = {
          sessionHash: this.$parent.userSession.hash,
       };
      axios.post("/api/user/logout/", json)
      .then(() => {
        this.$cookies.remove('session_hash');
        this.$cookies.remove('user_hash');
        this.$parent.userSession = undefined;
        localStorage.sessionHash = undefined;
        this.$router.push('/');
      }).catch(() => {
        this.$cookies.remove('session_hash');
        this.$cookies.remove('user_hash');
        this.$parent.userSession = undefined;
        localStorage.sessionHash = undefined;
        this.$router.push('/');
      });
    }
  },
  beforeMount() {
    this.logout();
  }
}
</script>
