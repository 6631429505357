<template>
<div class="container-fluid flex-fill" v-if="$parent.loaded">

  <b-breadcrumb>
    <b-breadcrumb-item to="/"><b-img class="mb-1" width="32" height="32" src="/static/images/logo.png" alt="logo"></b-img></b-breadcrumb-item>
    <b-breadcrumb-item active class="font-weight-bold">Changelog</b-breadcrumb-item>
  </b-breadcrumb>

  <h5>14.0.0</h5>
  <ul>
    <li>Fonctionnalité
      <ul>
        <li>Commentaires sur les sondages</li>
      </ul>
    </li>
    <li>Amélioration
      <ul>
        <li>Epingler des sujets dans les forums des asso et du site</li>
      </ul>
    </li>
  </ul>

  <h5>13.0.0</h5>
  <ul>
    <li>Fonctionnalité
      <ul>
        <li>Pages publiques/privées sur une asso</li>
      </ul>
    </li>
    <li>Améliorations
      <ul>
        <li>icone message privé indiquant que l'adhérent est en ligne, dans l'onglet adhérent</li>
        <li>Les admins d'une asso peuvent éditer les messages des forums</li>
        <li>Possibilité de désactiver les notifications email des messages privés depuis la page profil</li>
      </ul>
    </li>
    <li>Correction
      <ul>
        <li>Suppression du doublon onglet 'droit asso' dans l'admin adhérent</li>
      </ul>
    </li>
  </ul>

  <h5>12.0.0</h5>
  <ul>
    <li>Fonctionnalité
      <ul>
        <li>Tags adhérents</li>
      </ul>
    </li>
    <li>Améliorations
      <ul>
        <li>Nombre de messages dans discussion créneau</li>
        <li>Suppression des sujets forum par un admin</li>
        <li>Alerting email sur les forums</li>
      </ul>
    </li>
  </ul>

  <h5>11.1.0</h5>
  <ul>
    <li>Améliorations
      <ul>
        <li>Mise en cache de la requête get-club-users</li>
        <li>Monitoring des requetes mises en cache</li>
        <li>Monitoring des codes réponses</li>
      </ul>
    </li>
    <li>Correction
      <ul>
        <li>Sur la page tous les registres, seuls les registres auxquels l'adhérent est inscrit sont démandés</li>
      </ul>
    </li>
  </ul>

  <h5>11.0.0</h5>
  <ul>
    <li>Fonctionnalité
      <ul>
        <li>Sondages asso</li>
      </ul>
    </li>
    <li>Améliorations
      <ul>
        <li>Champ recherche dans la liste des adhérent de l'asso</li>
        <li>Lien message privé dans les forums et salons de discussion de l'asso</li>
      </ul>
    </li>
    <li>Correction
      <ul>
        <li>Mise à jour registre erreur 500</li>
      </ul>
    </li>
  </ul>

  <h5>10.0.0</h5>
  <ul>
    <li>Fonctionnalité
      <ul>
        <li>Message privé</li>
      </ul>
    </li>
  </ul>

  <h5>9.1.0</h5>
  <ul>
    <li>Amélioration
      <ul>
        <li>le super admin peut éditer les messages des forums du site</li>
      </ul>
    </li>
  </ul>

  <h5>9.0.0</h5>
  <ul>
    <li>Fonctionnalité
      <ul>
        <li>Salon de discussion sur les créneaux des registres</li>
      </ul>
    </li>
  </ul>

  <h5>8.0.1</h5>
  <ul>
    <li>Corrections
      <ul>
        <li>Lors d'une mise à jour de registre, les créneaux fermés restent fermés</li>
        <li>Suppression du cache Varnish</li>
        <li>Suppression de code mort</li>
        <li>Augmentation de la taille maximale d'un message sur les forums</li>
      </ul>
    </li>
  </ul>

  <h5>8.0.0</h5>
  <ul>
    <li>Fonctionnalité
      <ul>
        <li>Liste des adhérents consultable par les adhérents de l'asso</li>
      </ul>
    </li>
    <li>Améliorations
      <ul>
        <li>Affichage des droits et des flags sur la page qui liste tous les créneaux des registres</li>
        <li>Colorisation de l'icone nouveau message pour les discussions</li>
        <li>Améliorations visuelles sur la liste des adhérents pour les admins d'asso</li>
      </ul>
    </li>
  </ul>

  <h5>7.0.0</h5>
  <ul>
    <li>Fonctionnalité
      <ul>
        <li>Forums site monasso</li>
      </ul>
    </li>
    <li>Améliorations
      <ul>
        <li>Page qui liste tous les créneaux des registres</li>
      </ul>
    </li>
  </ul>

  <h5>6.0.0</h5>
  <ul>
    <li>Fonctionnalité
      <ul>
        <li>Forums d'une asso</li>
      </ul>
    </li>
  </ul>

  <h5>5.0.0</h5>
  <ul>
    <li>Fonctionnalités
      <ul>
        <li>Page publique d'une asso: information, horaire, actualités publiques</li>
        <li>Commentaires sur les actualités du site</li>
      </ul>
    </li>
    <li>Correction
      <ul>
        <li>Les nouvelles deviennent les actualités</li>
      </ul>
    </li>
  </ul>

  <h5>4.0.0</h5>
  <ul>
    <li>Fonctionnalité
      <ul>
        <li>Commentaires sur les news des assos</li>
      </ul>
    </li>
    <li>Correction
      <ul>
        <li>Format 24h des dates dans les mails</li>
      </ul>
    </li>
  </ul>

  <h5>3.0.0</h5>
  <ul>
    <li>Fonctionnalités
      <ul>
        <li>Oubli de mot de passe</li>
        <li>Gestion du matériel</li>
      </ul>
    </li>
    <li>Amélioration
      <ul>
        <li>Amélioration du visuel sur les petits écrans</li>
      </ul>
    </li>
    <li>Correction
      <ul>
        <li>le nombre de nouvelles du club est recalculé quand les anciennes nouvelles sont supprimées</li>
      </ul>
    </li>
  </ul>

  <h5>2.0.0</h5>
  <ul>
    <li>Fonctionnalité
      <ul>
        <li>Possibilité pour les modérateurs et administrateurs de registre de réserver pour un autre utilisateur</li>
      </ul>
    </li>
    <li>Amélioration
      <ul>
        <li>Ajout d'une notification visuelle sur les salons qui ont des messages non lus</li>
      </ul>
    </li>
  </ul>

  <h5>1.0.0</h5>
  <ul>
    <li>Fonctionnalités
      <ul>
        <li>Utilisateur hors ligne</li>
        <li>Salons de discussion</li>
        <li>Réservations</li>
        <li>Nouvelles de mon asso</li>
      </ul>
    </li>
  </ul>
</div>
</template>

<script>
export default {
  data: function () {
    return {

    }
  },
  methods: {
  },
  beforeMount() {
    this.$parent.init();
    document.title = this.$parent.websiteName + " - Liste des versions";

    this.$parent.loaded = true;
  }
}
</script>
